/* Reset browsers */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}
#ads-gate iframe,
#ads-gate img {
    z-index: 0 !important;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

/* remember to define focus styles! */
:focus {
    outline: 0;
}

/* remember to highlight inserts somehow! */
ins {
    text-decoration: none;
}
del {
    text-decoration: line-through;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
    border-collapse: collapse;
    border-spacing: 0;
}

body {
    min-width: 960px;
}

.notOverflowHidden {
    overflow: visible !important;
}

/* =Containers
--------------------------------------------------------------------------------*/
.container {
    margin: 0px auto;
    width: 960px;
    position: relative;
    clear: both;
}
/* Grid
	- grid_x - column width 80*x
	- outer_x - column width 60*x, margin: 0 10px
	- inner_x - column width 60*x, padding: 10px
--------------------------------------------------------------------------------*/
.grid_1,
.grid_2,
.grid_3,
.grid_4,
.grid_5,
.grid_6,
.grid_7,
.grid_8,
.grid_9,
.grid_10,
.grid_11,
.grid_12 {
    display: block;
    float: left;
    margin: 0px;
}
.container .grid_1 {
    width: 80px;
}
.container .grid_2 {
    width: 160px;
}
.container .grid_3 {
    width: 240px;
}
.container .grid_4 {
    width: 320px;
}
.container .grid_5 {
    width: 400px;
}
.container .grid_6 {
    width: 480px;
}
.container .grid_7 {
    width: 560px;
}
.container .grid_8 {
    width: 640px;
}
.container .grid_9 {
    width: 720px;
}
.container .grid_10 {
    width: 800px;
}
.container .grid_11 {
    width: 880px;
}
.container .grid_12 {
    width: 960px;
}

.inner_1,
.inner_2,
.inner_3,
.inner_4,
.inner_5,
.inner_6,
.inner_7,
.inner_8,
.inner_9,
.inner_10,
.inner_11,
.inner_12 {
    display: block;
    float: left;
    padding: 10px 10px 0;
    margin: 0 0 10px;
}
.outer_1,
.outer_2,
.outer_3,
.outer_4,
.outer_5,
.outer_6,
.outer_7,
.outer_8,
.outer_9,
.outer_10,
.outer_11,
.outer_12 {
    display: block;
    float: left;
    margin: 0 10px;
}

.container .outer_1,
.container .inner_1 {
    width: 60px;
}
.container .outer_2,
.container .inner_2 {
    width: 140px;
}
.container .outer_3,
.container .inner_3 {
    width: 220px;
}
.container .outer_4,
.container .inner_4 {
    width: 300px;
}
.container .outer_5,
.container .inner_5 {
    width: 380px;
}
.container .outer_6,
.container .inner_6 {
    width: 460px;
}
.container .outer_7,
.container .inner_7 {
    width: 540px;
}
.container .outer_8,
.container .inner_8 {
    width: 620px;
}
.container .outer_9,
.container .inner_9 {
    width: 700px;
}
.container .outer_10,
.container .inner_10 {
    width: 780px;
}
.container .outer_11,
.container .inner_11 {
    width: 860px;
}
.container .outer_12,
.container .inner_12 {
    width: 940px;
}

/* Universal
--------------------------------------------------------------------------------*/
* {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

/* `Clear Floated Elements
----------------------------------------------------------------------------------------------------*/
.clear {
    clear: both;
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
}

.clearfix:before,
.clearfix:after,
.cb:before,
.cb:after {
    content: "\0020";
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
}
.clearfix:after,
.cb:after {
    clear: both;
}

/* Blesk styles + helpers
----------------------------------------------------------------------------------------------------*/

body {
    background: #e9eff4;
    font-family: Arial, sans-serif;
}

#body-wrapper {
    background: #e9eff4 !important;
    width: 960px;
    margin: 0 auto;
    padding: 0 60px 10px;
}

a {
    text-decoration: none;
    color: #ff0000;
}

a:hover {
    text-decoration: underline;
}

.floatNone {
    float: none !important;
}

.floatLeft {
    float: left;
}

.floatRight {
    float: right;
}

#content {
    padding-top: 20px;
}

#site {
    margin-top: 30px;
}

.alignCenter {
    text-align: center;
}

.displayNone {
    display: none;
}
.displayIB {
    display: inline-block;
}
.displayIB a {
    display: inline-block;
}

.mt0px {
    margin-top: 0px !important;
}

.mt10px {
    margin-top: 10px !important;
}

.mt20px {
    margin-top: 20px !important;
}

.mt50px {
    margin-top: 50px;
}

.mr0px {
    margin-right: 0px;
}

.mr10px {
    margin-right: 10px;
}

.mr20px {
    margin-right: 10px;
}

.mb0px {
    margin-bottom: 0px !important;
}

.mb10px {
    margin-bottom: 10px;
}

.mb20px {
    margin-bottom: 20px !important;
}

.mb30px {
    margin-bottom: 30px !important;
}

.mb50px {
    margin-bottom: 50px;
}

.ml0px {
    margin-left: 0px;
}

.ml10px {
    margin-left: 10px;
}

.ml20px {
    margin-left: 10px;
}

.noBottom {
    bottom: 0 !important;
}
.wrapper {
    position: relative;
}

.buttonRed {
    -moz-box-shadow: inset 0px 1px 2px 0px #fe6072;
    -webkit-box-shadow: inset 0px 1px 2px 0px #fe6072;
    box-shadow: inset 0px 1px 2px 0px #fe6072;
    background: -moz-linear-gradient(center top, #ff001e 5%, #dc0033 100%);

    background-color: #ff001e;
    border: 1px solid #dc0033;
    display: inline-block;
    color: #ffffff;
    font-family: Trebuchet MS;
    font-family: "tablet_gothic_condensed";
    font-size: 16px;
    line-height: 28px;
    font-weight: bold;
    display: block;
    cursor: pointer;
    text-transform: uppercase;
    padding: 0px 6px;
    text-decoration: none;
    margin: 0;
}

.buttonRed:hover {
    background-color: #dc0033;
}

.buttonRed:active {
    position: relative;
    top: 1px;
}

.wid50 {
    width: 50px !important;
}
.wid100 {
    width: 100px !important;
}
.wid150 {
    width: 150px !important;
}
.wid200 {
    width: 200px !important;
}
.wid300 {
    width: 300px !important;
}

.lineH1 {
    line-height: 1px;
}
.imageWrapper {
    float: left;
    margin: 0 10px;
    max-width: 940px;
    overflow: hidden;
}

.imageWrapper img {
    max-width: 940px;
}
.imageWrapper a {
    display: block;
}

.posRel {
    position: relative !important;
}

/* Blesk fonts
----------------------------------------------------------------------------------------------------*/

@font-face {
    font-family: "tablet_gothic_compressed";
    src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet-gothic-compressed-bold-18-2.eot");
    src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet-gothic-compressed-bold-18-2.eot?#iefix") format("embedded-opentype");
    src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet-gothic-compressed-bold-18-2.woff") format("woff");
    src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet-gothic-compressed-bold-18-2.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "tablet_gothic_condensed";
    src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot");
    src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot?#iefix") format("embedded-opentype");
    src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.woff") format("woff");
    src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.ttf?v=3") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "tablet_gothic_condensed_extra";
    src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-extrabold-webfont.eot");
    src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-extrabold-webfont.eot?#iefix") format("embedded-opentype");
    src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-extrabold-webfont.woff") format("woff");
    src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-extrabold-webfont.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/* header and menu
-----------------------------------------------------------------------------*/
#site .headerMenu {
    overflow: visible;
}

.topPanel {
    color: #707b87;
    font-size: 12px;
    float: left;
    border-bottom: 1px solid #cad5df;
    /*padding-bottom: 6px;*/
    padding-bottom: 0px;
    padding-right: 10px;
    width: 660px;
}

.topPanelArticle {
    color: #707b87;
    font-size: 12px;
    float: left;
    width: 940px;
    padding-right: 10px;
    padding-bottom: 5px;
    vertical-align: baseline;
}

.topPanel a,
.topPanelArticle a {
    color: #707b87;
    text-decoration: none;
}

.topPanel a:hover,
.topPanelArticle a:hover {
    text-decoration: underline;
}

.topPanel .profileActions,
.topPanelArticle .profileActions,
.topPanel .calendar,
.topPanel .nameDay,
.topPanel .linkMagazines,
.topPanelArticle .linkMagazines {
    display: inline-block;
    line-height: 22px;
}

.topPanelArticle .linkMagazines {
    float: right;
    min-width: 425px;
    text-align: right;
    line-height: 22px;
}

.topPanel .profileActions a {
    padding: 0px;
    border-right: 1px solid #cad5df;
    display: inline-block;
}

.topPanel .profileActions a.user {
    border: none;
    line-height: 22px;
    display: inline-block;
}

.topPanelArticle .profileActions a {
    padding: 0;
    display: inline-block;
    width: 89px;
}

.topPanelArticle .profileActions a.registration {
    padding: 0 !important;
    width: 114px;
}

.topPanel .linkMagazines a,
.topPanelArticle .linkMagazines a {
    margin: 4px 0px 4px 4px;
    padding: 0 5px;
    font-weight: bold;
    border-left: 0px solid #cad5df;
}
.topPanel .linkMagazines .fb_iframe_widget,
.topPanelArticle .linkMagazines .fb_iframe_widget {
    vertical-align: middle;
    margin-left: 12px;
    display: inline;
}

.topPanel .profileActions a.registration,
.topPanelArticle .profileActions a.registration {
    padding-left: 0px;
    display: inline-block;
    width: 114px;
    line-height: 22px;
}

.topPanel .profileActions a.login,
.topPanel .profileActions a.logout,
.topPanelArticle .profileActions a.login,
.topPanelArticle .profileActions a.logout {
    padding-left: 25px;
    width: 89px;
    display: inline-block;
    line-height: 22px;
    border: none;
}

.topPanel .calendar {
    font-weight: bold;
    /*text-transform: capitalize;*/
    margin: 0px 10px 0 10px;
}

.topPanel .nameDay a {
    font-weight: bold;
}

.headerMenu .calendarContHP {
    position: relative;
    float: left;
}

.headerMenu .calendar {
    font-size: 12px;
    color: #343f4b;
    font-weight: bold;
    line-height: 15px;
    padding: 6px 10px 0px 10px;
    margin: 0px 0;
}

.headerMenu .nameDay {
    font-size: 11px;
    line-height: 15px;
    max-width: 360px;
    color: #5c6773;
    padding: 6px 10px 5px 10px;
} /* #### */

.topPanel .profileActions,
.topPanelArticle .profileActions {
    display: inline-block;
    line-height: 30px;
    width: 230px;
}

/* Paticka
-----------------------------------------------------------------------------*/
.footer {
    margin: 50px 0;
    padding-bottom: 5px;
}

.footer .logoAodkazy {
    bottom: 70px;
}

#bottomMenu {
    display: none;
}

.mobVer {
    display: block;
    font-family: "tablet_gothic_condensed";
    font-size: 16px;
    margin-bottom: 50px;
}

.mobVer a {
    padding: 10px;
    background: #ff0000;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    margin: 0 10px;
    display: inline-block;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
}

.mobVer a:hover {
    text-decoration: none;
    background: #eb0000;
    transition-duration: 0.5s;
}

.footer .mobVer {
    display: block;
}

.footer .footerText {
    font-size: 11px;
    text-align: center;
    line-height: 14px;
    display: block;
}

.footer .footerText .mobVer {
    display: none;
}

/* Search
-----------------------------------------------------------------------------*/
.searchBox {
    float: right;
    /*width: 258px;*/
    position: relative;
    right: 0px;
}

.searchBox form:after {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 0px;
    right: 10px;
    left: auto;
    width: 90%;
    top: 7px;
    max-width: 260px;
    background: #888;
    box-shadow: 0 15px 10px #888;
    transform: rotate(3deg);
    height: 8px;
}

.article .searchBox {
    /*width: 300px;*/
}

.searchBox .searchField {
    width: 162px;
    height: 26px;
    margin: 0px;
    display: block;
    float: left;
    border: 1px solid #cad5df;
    text-indent: 10px;
    border-radius: 0px;
}

.article .searchBox .searchField {
    /*width: 202px;*/
}

.searchBox .searchButton {
    background: #ff0000;
    border: none;
    color: #ffffff;
    height: 30px;
    margin: 0px;
    text-transform: uppercase;
    display: block;
    float: left;
    font-family: "Tablet Gothic Condensed", Arial, sans-serif;
    font-size: 16px;
    line-height: 30px;
    cursor: pointer;
    width: 80px;
}

.articleContainer.middleArticle.searchResult .wrapper {
    margin: 10px 0 0 0;
    left: 310px;
    padding-left: 0;
    width: 300px;
}
.articleContainer.middleArticle.searchResult img {
    top: 10px;
    left: 10px;
}
.articleContainer.middleArticle.searchResult {
    height: 180px;
    margin: 0 10px 20px 10px;
    width: 610px;
}

.searchForm form {
    margin: 20px 0 0 0;
}

.searchForm h2 {
    font-size: 40px;
    font-family: "tablet_gothic_condensed";
    line-height: 40px;
}

.searchForm .colorBtn {
    position: relative;
    top: 2px;
}

.searchBox form .colorBtn {
    width: 60px;
    border-radius: 0;
}

.wikiResult .articleContainer.middleArticle h3.title {
    margin-top: 10px;
    margin-left: -10px;
    height: 80px;
}
.wikiResult .articleContainer.middleArticle img {
    top: 10px;
}
.wikiResult .articleContainer.middleArticle .wrapper {
    height: 170px;
}
.wikiResult .articleContainer.middleArticle {
    height: 170px;
}

/* Forms items
-----------------------------------------------------------------------------*/
.colorBtn,
.lightBtn,
.darkBtn {
    display: inline-block;
    text-transform: uppercase;
    font-family: "tablet_gothic_condensed";
    font-weight: bold;
    font-size: 16px;
    margin: 0;
    padding: 0 17px;
    position: relative;
    cursor: pointer;
    border: none;
}

.colorBtn {
    line-height: 30px;
    height: 30px;
    background: #ff0000;
    color: #ffffff;
}
.colorBtn:hover {
    background: #eb0000;
}

.lightBtn {
    line-height: 28px;
    height: 28px;
    border: 1px solid #cad5df;
    background: #f2f5f8;
    color: #707b87;
}
.lightBtn:hover {
    background: #e9eff4;
}

.darkBtn {
    line-height: 30px;
    height: 30px;
    background: #5c6773;
    color: #ffffff;
}

.darkBtn:hover {
    background: #48535f;
}

.colorBtn.disableBtn,
.lightBtn.disableBtn,
.darkBtn.disableBtn {
    background: #cad5df;
    color: #e9eff4;
}

.normalInp {
    display: inline-block;
    /*
	font-family: 'tablet_gothic_condensed';
	font-weight: bold;
*/
    font-family: Arial;
    font-weight: normal;
    font-size: 16px;
    margin: 0;
    padding: 0 17px;
    position: relative;
    cursor: pointer;
    line-height: 26px;
    height: 26px;
    border: 1px solid #cad5df;
    background: #ffffff;
    color: #98a3af;
}

.normalInp.disableInp {
    background: #e9eff4;
}

.login form div.field {
    float: left;
    margin-right: 10px;
}

.login form label {
    font: bold 12px/30px Arial;
    color: #333f48;
}
.login form div.field a {
    text-decoration: none;
    font: bold 10px/30px Arial;
    color: #dc0032;
}
.login form div.field a:hover,
.login form div.field a:focus {
    text-decoration: underline;
    color: #dc0032;
}
.login p {
    float: left;
    line-height: 30px;
    margin: 0;
}
.login .frmName {
    width: 160px;
}
.login .frmPass {
    width: 160px;
}
.login .frmSubmit {
    width: 120px;
    margin-right: 0px;
}
.login input.text,
.login input.password {
    height: 22px;
    border: 1px #d8e1e5 solid;
    font: 16px/22px Arial;
    letter-spacing: 1px;
    color: #333f48;
    -webkit-box-shadow: inset 0 8px 10px -3px #f4f7f8;
    -moz-box-shadow: inset 0 8px 10px -3px #f4f7f8;
    box-shadow: inset 0 8px 10px -3px #f4f7f8;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    width: 148px;
    padding: 3px 5px;
}

.login textarea.textarea {
    border: 1px #d8e1e5 solid;
    font: 16px/22px Arial;
    letter-spacing: 1px;
    color: #333f48;
    -webkit-box-shadow: inset 0 8px 10px -3px #f4f7f8;
    -moz-box-shadow: inset 0 8px 10px -3px #f4f7f8;
    box-shadow: inset 0 8px 10px -3px #f4f7f8;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    width: 200px;
    padding: 3px 5px;
}

.login textarea.textarea:hover,
.login input.text:hover,
.login input.password:hover {
    color: #333f48;
    border-color: #b9c9d1;
}

.login textarea.textarea:focus,
.login input.text:focus,
.login input.password:focus {
    outline: none;
    color: #333f48;
    border-color: #b9c9d1;
}
.login input.submit {
    margin-top: 30px;
    width: 118px;
}

.links {
    border-top: 1px #d8e1e5 solid;
}
.links div {
    float: right;
    width: 120px;
}
.links a {
    color: #dc0032;
    font: 12px/30px Arial;
}

.loginBoxTop {
    background: #fff;
    padding: 20px;
    box-shadow: 5px 15px 15px rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: 3;
    width: 440px;
    height: 140px;
}

.loginBoxTop .closeLogin {
    width: 16px;
    height: 16px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -900px -500px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.loginBoxTop .tdSubmit {
    clear: both;
    margin: 20px auto 15px;
    text-align: center;
}

.loginBoxTop .requestPassword {
    float: right;
}

.loginBoxTop .requestPassword a {
    color: #16212d;
    border: none;
}

.loginBoxTop label {
    color: #48535f;
    font-size: 15px;
    display: block;
}

.loginBoxTop input[type="text"],
.loginBoxTop input[type="password"] {
    width: 202px;
    height: 26px;
    border-color: #cad5df;
    margin: 0px;
    display: block;
    float: left;
    border-style: solid;
    border-width: 1px;
    text-indent: 10px;
}

.loginBoxTop .tdUsername,
.loginBoxTop .tdPassword {
    float: left;
    width: 220px;
}

a#loginLink.active {
    background: #fff;
}

/* Shadows
-----------------------------------------------------------------------------*/

.shadowDown {
    position: absolute;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat 30px -1000px;
    height: 10px;
    width: 100%;
    z-index: 2;
    top: 0px;
    left: 0px;
    pointer-events: none;
}

.shadowDown.relative {
    position: relative;
}

.shadowUp {
    position: absolute;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat 30px -950px;
    height: 10px;
    width: 100%;
    z-index: 2;
    top: 0px;
    left: 0px;
    pointer-events: none;
}

.shadowUp.relative {
    position: relative;
}

/* Logo
-----------------------------------------------------------------------------*/
.logoBlesk a {
    position: relative;
    display: block;
    background: #f00 url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat 0px -664px;
    width: 230px;
    height: 108px;
    text-indent: -9999px;
    float: left;
    z-index: 2;
}

.logoBlesk a.prepinacLogo {
    display: block;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/prepinac-logo-blesk.png?v=68") left top no-repeat;
    width: 230px;
    height: 70px;
    text-indent: -9999px;
    float: left;
}

/* Menu
-----------------------------------------------------------------------------*/
#menu {
    margin-top: 0px;
    /*position: relative;*/
}

#menu a:hover {
    text-decoration: none;
}

#menu ul.topLevelMenu li {
    width: 240px;
    display: inline-block;
    position: relative;
}

#menu ul.topLevelMenu li.hover {
    background: #fff;
}

#menu ul.topLevelMenu li.celebrity {
    width: 240px;
    height: 80px;
}

#menu ul.topLevelMenu li.zpravy {
    width: 190px;
    height: 80px;
}

#menu ul.topLevelMenu li.sport {
    width: 170px;
    height: 80px;
}

#menu ul.topLevelMenu li.program {
    width: 55px;
    height: 70px;
    padding: 8px 30px 2px 25px;
}

#menu ul.topLevelMenu li.program:hover {
    background: #fff;
}

#menu ul.topLevelMenu li.program .subCategories a {
    padding-bottom: 0px;
}

#menu ul.topLevelMenu li.program a.category {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -700px -300px;
    text-indent: -9999px;
    padding-top: 0px;
    line-height: 40px;
    padding-bottom: 9px;
    position: relative;
    top: 6px;
}

#menu .menuSection {
    box-shadow: 5px 15px 15px rgba(0, 0, 0, 0.2);
}

#menu .hoverMenu.hover {
    box-shadow: 5px 15px 15px rgba(0, 0, 0, 0.2);
}

#menu .hoverMenu.hover.toLeft {
    box-shadow: -5px 15px 15px rgba(0, 0, 0, 0.2);
}

#menu .hoverMenu.hover.toLeft .menuSection {
    box-shadow: -5px 15px 15px rgba(0, 0, 0, 0.2);
}

#menu ul.topLevelMenu li a.category {
    color: #16212d;
    font-weight: bold;
    font-family: "tablet_gothic_condensed", Arial, sans-serif;
    font-size: 46px;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    display: block;
    line-height: 46px;
    padding-top: 10px;
    transition: all 0.1s ease-out;
}

#menu ul.topLevelMenu ul.subCategories .bulletDivider {
    font-size: 12px;
    padding: 0px 0px;
}

#menu ul.topLevelMenu ul.subCategories {
    display: block;
    text-align: center;
}

#menu ul.topLevelMenu ul.subCategories li {
    width: auto;
    padding: 0px 5px;
    color: #707b87;
    float: none;
}

#menu ul.topLevelMenu ul.subCategories li a {
    line-height: 12px;
    padding-top: 0px;
    text-indent: 0px;
    background: transparent;
    font-weight: bold;
    font-family: "tablet_gothic_condensed", Arial, sans-serif;
    text-transform: uppercase;
}

#menu ul.topLevelMenu ul.subCategories li a.red {
    color: #ff0000;
}

#menu ul.topLevelMenu ul.subCategories li a:hover {
    text-decoration: underline;
}

#menu ul.topLevelMenu a.subCategory {
    font-size: 12px;
    color: #707b87;
}

#menu ul.secondLevelMenu {
    background: #ff0000;
    color: #fff;
    height: 30px;
    position: relative;
    margin-top: 0px;
    line-height: 30px;
    padding: 0px 0px;
    background-image: linear-gradient(to left, rgb(255, 0, 0) 0%, rgb(255, 0, 0) 10%, rgb(171, 0, 0) 35%, rgb(255, 0, 0) 60%, rgb(255, 0, 0) 100%);
}

#menu ul.secondLevelMenu ul {
    float: left;
}

#menu ul.secondLevelMenu ul.block {
    margin-right: 14px;
    padding-top: 8px;
}

#menu ul.secondLevelMenu ul.leftDark a {
    color: #540101;
}

#menu ul.secondLevelMenu ul.middle {
    padding-left: 0px;
}

#menu ul.secondLevelMenu ul.bleskMobil {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -700px -350px;
    width: 61px;
    height: 40px;
    top: -4px;
    text-indent: -9999px;
    right: 78px;
    padding: 0px;
    border-right: 0px;
    float: left;
    position: absolute;
}
#menu ul.secondLevelMenu ul.synotLiga {
    background: transparent url("https://img2.cncenter.cz/images/isport-synotliga/logo-synot-liga-na-HP-blesk.png?v=3") no-repeat;
    background-size: 100% auto;
    width: 54px;
    height: 44px;
    position: absolute;
    text-indent: -9999px;
    top: -8px;
    /*right: 200px;*/
    right: 148px;
}
#menu ul.secondLevelMenu ul.synotLiga li {
    position: relative;
    display: block;
    padding: 0px;
    width: 54px;
    height: 44px;
    border-right: none;
}
#menu ul.secondLevelMenu ul.synotLiga li a {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
}

#menu ul.secondLevelMenu .food-test-hp {
    position: absolute;
    top: -10px;
    right: 180px;
}

#menu ul.secondLevelMenu ul.srdceProVas {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/srdceProVas/logo-srdceProVas.png?v=1") no-repeat;
    background-size: 100% auto;
    width: 52px;
    height: 46px;
    position: absolute;
    text-indent: -9999px;
    top: -8px;
    /*right: 200px;*/
    right: 148px;
}
#menu ul.secondLevelMenu ul.srdceProVas li {
    position: relative;
    display: block;
    padding: 0px;
    width: 54px;
    height: 44px;
    border-right: none;
}
#menu ul.secondLevelMenu ul.srdceProVas li a {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
}

#menu ul.secondLevelMenu ul.v li a {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
}

#menu ul.secondLevelMenu ul.bleskMobil li {
    position: relative;
    display: block;
    padding: 0px;
    width: 61px;
    height: 40px;
    border-right: none;
}

#menu ul.secondLevelMenu ul.bleskMobil li a {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
}

#menu ul.secondLevelMenu ul.iKiosk {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -700px -250px;
    width: 40px;
    height: 40px;
    position: absolute;
    text-indent: -9999px;
    top: -4px;
    /*right: 80px;*/
    right: 10px;
}

#menu ul.secondLevelMenu ul.iKiosk li {
    position: relative;
    display: block;
    padding: 0px;
    width: 40px;
    height: 40px;
    border-right: none;
}

#menu ul.secondLevelMenu ul.iKiosk li a {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
}

#menu ul.secondLevelMenu ul.dobryVyrobek {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -700px -200px;
    width: 61px;
    height: 42px;
    position: absolute;
    text-indent: -9999px;
    top: -4px;
    right: 10px;
}

#menu ul.secondLevelMenu ul.dobraMinutovaPujcka {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/dobry-vyrobek/dobra-minutova-pujcka.png") no-repeat;
    width: 59px;
    height: 40px;
    position: absolute;
    text-indent: -9999px;
    top: -4px;
    right: 10px;
}
#menu ul.secondLevelMenu ul.dobraMinutovaPujcka li {
    position: relative;
    display: block;
    padding: 0px;
    width: 59px;
    height: 40px;
    border-right: none;
}
#menu ul.secondLevelMenu ul.dobraMinutovaPujcka li a {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
}

#menu ul.secondLevelMenu ul.dobryVyrobek.uspora {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/dobry-vyrobek/logo-uspora.png") no-repeat;
}

#menu ul.secondLevelMenu ul.dobryVyrobek.auto {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/dobry-vyrobek/dobre-auto.png") no-repeat;
}

#menu ul.secondLevelMenu ul.dobryVyrobek.jistota {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/dobry-vyrobek/dobra-jistota.png") no-repeat;
}

#menu ul.secondLevelMenu ul.dobryVyrobek.ucet {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/dobry-vyrobek/dobry-ucet.png?v=1") no-repeat;
}

#menu ul.secondLevelMenu ul.dobryVyrobek.pujcka {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/dobry-vyrobek/dobra-pujcka.png?v=1") no-repeat;
}

#menu ul.secondLevelMenu ul.dobryVyrobek li {
    position: relative;
    display: block;
    padding: 0px;
    width: 59px;
    height: 40px;
    border-right: none;
}

#menu ul.secondLevelMenu ul.dobryVyrobek li a {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
}

#menu ul.secondLevelMenu ul.right {
    float: right;
    background: #c80000;
    padding-left: 60px;
}

#menu ul.secondLevelMenu li {
    display: inline-block;
    padding: 0px 8px;
    border-right: 1px solid #ffffff;
    color: #ffffff;
    font-family: "tablet_gothic_condensed";
    font-size: 16px;
    line-height: 16px;
    width: auto;
}

#menu ul.secondLevelMenu li.first {
    padding-left: 20px;
}

#menu ul.secondLevelMenu li.last {
    border-right: 0px;
}

#menu ul.secondLevelMenu li:hover {
    /*background: #ffffff;*/
}

#menu ul.secondLevelMenu li a {
    color: #ffffff;
    text-transform: uppercase;
}

#menu ul.secondLevelMenu li a:hover {
    text-decoration: underline;
}

#menu ul.topLevelMenu li.celebrity a.category:after,
#menu ul.topLevelMenu li.sport a.category:after,
#menu ul.topLevelMenu li.zpravy a.category:after {
    width: 10px;
    content: "";
    height: 10px;
    float: right;
    padding-top: 15px;
    padding-right: 0px;
    position: relative;
    left: -10px;
}

#menu ul.topLevelMenu li.celebrity a.category:after {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -250px -200px;
    background-origin: content-box;
    background-clip: content-box;
}

#menu ul.topLevelMenu li.zpravy a.category:after {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -600px -200px;
    background-origin: content-box;
    background-clip: content-box;
}

#menu ul.topLevelMenu li.sport a.category:after {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -350px -200px;
    background-origin: content-box;
    background-clip: content-box;
}

#menu ul.topLevelMenu li.celebrity.hover a.category {
    /*color: #b469f5;*/
    color: #f00;
}

#menu ul.topLevelMenu li.zpravy.hover a.category {
    color: #6e78dc;
}

#menu ul.topLevelMenu li.sport.hover a.category {
    color: #91c837;
}

#menu ul.topLevelMenu li:hover ul.subCategories li a {
    color: #707b87;
}

#menu ul.topLevelMenu li:hover ul.subCategories li a.red {
    color: #ff0000;
}

#menu #menuSectionContainer {
    position: absolute;
    top: 75px;
    left: 0px;
    z-index: 20000 !important;
    width: 510px;
}

#menu .sport #menuSectionContainer {
    width: 500px;
}

#menu .celebrity #menuSectionContainer {
    width: 495px;
}

#menu .toLeft #menuSectionContainer {
    left: auto;
    right: 0px;
}

#menu .menuSection {
    border: 0px solid #8c0222;
    margin: 0px 0 0;
    background-color: #fff;
    position: relative;
    z-index: 2;
    padding: 0px;
}

#menu .menuSection .smallArticles {
    float: left;
    width: 309px;
    border-right: 1px solid #cad5df;
    margin: 10px 0px 10px 10px;
}

#menu .menuSection .smallArticles .article {
    width: 299px;
    height: 68px;
    border-bottom: 1px solid #cad5df;
    padding: 10px 0px;
    margin: 0px 20px 10px 0px;
}

#menu .zpravy .menuSection .smallArticles .article:after,
#menu .celebrity .menuSection .smallArticles .article:after,
#menu .sport .menuSection .smallArticles .article:after {
    width: 10px;
    content: "";
    height: 10px;
    float: right;
    padding-top: 25px;
    padding-right: 0px;
}

#menu .zpravy .menuSection .smallArticles .article:after {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -150px -216px;
    background-origin: content-box;
    background-clip: content-box;
}

#menu .celebrity .menuSection .smallArticles .article:after {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -250px -216px;
    background-origin: content-box;
    background-clip: content-box;
}

#menu .sport .menuSection .smallArticles .article:after {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -350px -216px;
    background-origin: content-box;
    background-clip: content-box;
}

#menu .menuSection .smallArticles .article.last {
    border-bottom: none;
}

#menu .menuSection .smallArticles .article .title {
    float: left;
    padding-left: 10px;
    color: #16212d;
    width: 150px;
    font-size: 15px;
    font-weight: bold;
}

#menu .menuSection .smallArticles .article .title:hover {
    text-decoration: underline;
}

#menu .menuSection .smallArticles img {
    float: left;
    width: 120px;
    height: 68px;
}

#menu ul.topLevelMenu .menuSection .smallArticles .articleTitle {
    font-size: 15px;
    color: #16212d;
}

#menu .menuSection .articleVertical {
    float: left;
}

#menu .celebrity .menuSection .specialBottom {
    background: #ff0000;
    clear: both;
    padding: 0px 10px;
    color: #fff;
    padding: 10px;
}

#menu ul.topLevelMenu li.celebrity .menuSection .specialBottom a {
    color: #fff;
    font-weight: bold;
}

#menu ul.topLevelMenu li.zpravy .menuSection .specialBottom {
    background: #b469f5;
    clear: both;
    padding: 0px 10px;
    color: #fff;
    padding: 10px;
}

#menu ul.topLevelMenu li.zpravy .menuSection .specialBottom a {
    color: #fff;
    font-weight: bold;
}

#menu ul.topLevelMenu li.sport .menuSection .specialBottom {
    background: #91c837;
    clear: both;
    padding: 0px 10px;
    color: #fff;
    padding: 10px;
}

#menu ul.topLevelMenu li.sport .menuSection .specialBottom a {
    color: #fff;
    font-weight: bold;
}

#menu ul.topLevelMenu li.celebrity .menuSection .specialBottom a:hover,
#menu ul.topLevelMenu li.zpravy .menuSection .specialBottom a:hover,
#menu ul.topLevelMenu li.sport .menuSection .specialBottom a:hover {
    text-decoration: underline;
}

#menu .menuSection .menuInMenu {
    float: right;
    width: 175px;
    background-color: #16212d;
    min-height: 275px;
}

#menu .celebrity .menuInMenu {
    width: 175px;
    float: left;
}
#menu .sport .menuInMenu {
    width: 175px;
}

#menu .menuSection .menuInMenu ul {
    padding: 3px 0 0 15px;
}
#menu .menuSection .menuInMenu ul li {
    float: none;
    display: block;
    width: 145px;
    height: auto !important;
}

#menu .menuSection .menuInMenu ul li a {
    float: none;
    display: block;
    width: 145px;
    font-family: "tablet_gothic_compressed";
    font-size: 16px;
    line-height: 29px;
    text-transform: uppercase;
    color: #e9eff4;
}

/* Mimoradna zprava
-----------------------------------------------------------------------------*/

.breakingNew {
    background: #dedede none;
    color: #16212d;
    position: relative;
    padding-bottom: 30px;
    padding: 0;
    width: 900px;
    overflow: hidden;
    /*	height: 130px; */
    height: 110px;
    z-index: 10;
}
.breakingNew .title-bn a.changeColor {
    color: white;
}

.breakingNew .header {
    font-family: "tablet_gothic_condensed";
    font-size: 38px;
    line-height: 40px;
    text-transform: uppercase;
    float: left;
    margin: 5px 0 0 5px;
    width: 240px;
    position: relative;
    z-index: 2;
}

.breakingNew .header .extraNew,
.breakingNew .header .extraNew2 {
    display: inline-block;
    background: #16212d;
    padding: 3px 3px 0px 3px;
    color: #ffffff;
}

.breakingNew .globe {
    width: 74px;
    height: 74px;
    position: absolute;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat right -600px;
    left: 180px;
    top: 15px;
    opacity: 0.5;
    z-index: 1;
}

.breakingNew .header .status {
    float: left;
    font-size: 12px;
    color: #16212d;
    line-height: 19px;
}

.breakingNew .linkArrow {
    display: none;
    height: 100px;
    width: 30px;
    float: right;
    line-height: 100px;
    position: relative;
    top: 15px;
    border-left: 1px solid #ffffff;
}

.breakingNew .linkArrow .linkArrowImg {
    display: none;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 40px;
    left: 14px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat left -900px;
}

.breakingNew .title {
    font-size: 79px;
    font-family: "tablet_gothic_condensed";
    text-transform: uppercase;
    text-align: center;
    /*	height: 100px;  */
    line-height: 100px;
    /*	margin:5px 0 5px 0;  */
    padding: 7px 10px 3px 5px;
}

.breakingNew .title a {
    color: #16212d;
}

.breakingNew .title a:hover {
    text-decoration: underline;
}

.breakingNew .image {
    margin: 0 10px 10px 10px;
    position: relative;
}

.breakingNew .image a {
    display: block;
}

.breakingNew .image img {
    display: none;
}

.breakingNew.black {
    background: #16212d;
}
.breakingNew.black .globe {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat right -700px;
}
.breakingNew.black .header .status {
    color: #ffffff;
}
.breakingNew.black .title {
    color: #ffffff;
}
.breakingNew.black .title a {
    color: #ffffff;
}
.breakingNew.black .header .extraNew,
.breakingNew.black .header .extraNew2 {
    background: #ff0000;
}

.breakingNew.red {
    background: #ff0000;
}
.breakingNew.red .globe {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat right -700px;
}
.breakingNew.red .header .status {
    color: #ffffff;
}
.breakingNew.red .title {
    color: #ffffff;
}
.breakingNew.red .title a {
    color: #ffffff;
}
.breakingNew.red .header .extraNew,
.breakingNew.red .header .extraNew2 {
    background: #16212d;
}

.breakingNew.yellow {
    background-color: #ffd700;
}
.breakingNew.yellow .globe {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat right -800px;
}
.breakingNew.yellow .header .status {
    color: #ff0000;
}
.breakingNew.yellow .title {
    color: #16212d;
}
.breakingNew.yellow .title a {
    color: #16212d;
}
.breakingNew.yellow .header .extraNew,
.breakingNew.yellow .header .extraNew2 {
    background: #ff0000;
}

/* Promobox HP
-----------------------------------------------------------------------------*/
.promoBox {
    position: relative;
}
.promoBox .big img {
    width: 630px;
    height: 360px;
}

.promoBox .promoboxItems {
    width: 300px;
    margin: 0px;
    padding-left: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
}

.promoBox .promoboxItems li {
    height: 90px;
    padding: 0px;
}

.promoBox .promoboxItems li a {
    display: block;
    padding: 10px 0px 11px 0px;
    border-bottom: 1px solid #cad5df;
}

.promoBox .promoboxItems li #smallPromo1 a {
    padding: 9px 0px 11px 0px;
    border-top: 1px solid #cad5df;
}

.promoBox .promoboxItems li img {
    width: 120px;
    height: 68px;
    float: left;
}
.promoBox .promoboxItems span.titleLink {
    color: #16212d;
    font-family: "tablet_gothic_condensed";
    font-size: 22px;
    padding-left: 10px;
    padding-right: 5px;
    float: left;
    width: 165px;
    letter-spacing: 0px;
    line-height: 23px;
    font-weight: bold;
}

.promoBox .promoboxItems .ui-state-active span.titleLink {
    color: #fff;
}

.promoBox .promoboxItems li.ui-state-active {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat 0px -550px;
    color: #ffffff;
    width: 320px;
    position: relative;
    left: -20px;
    padding-left: 0px;
}

.promoBox .promoboxItems li.ui-state-active a,
.promoBox .promoboxItems li.ui-state-active #smallPromo1 a {
    color: #fff;
    display: block;
    padding: 10px 0px 12px 20px;
    border: 0px;
}

/* Recent articles
-----------------------------------------------------------------------------*/
.articleContainer {
    background: #fff;
    border-top: 4px solid #ff0000;
    position: relative;
    padding: 0 10px;
}
.articleContainer.littleArticle {
    border-top: 2px solid #ff0000;
}

.articleContainer.bigArticle {
    height: 436px;
}

.articleContainer .wrapper {
    height: 200px;
    overflow: hidden;
}

.articleContainer.middleArticle .wrapper {
    height: 158px;
    overflow: visible;
    padding-left: 310px;
}

.articleContainer.smallArticle .wrapper {
    height: 227px;
    overflow: visible;
    padding-top: 158px;
}

.articleContainer.smallArticle.frantiska .wrapper {
    overflow: visible;
    padding-top: 184px;
}

.littleArticles {
    margin: 10px 0 0px;
    padding-bottom: 0px;
}

.littleArticles .delimiter {
    margin: 10px 10px 0;
    border-bottom: 1px solid #cad5df;
    clear: both;
}

.littleArticlesWithBg .littleArticles {
    margin: 0 10px;
}

.littleArticlesWithBg .littleArticle {
    border: 0px;
    background-color: transparent !important;
}

.littleArticlesWithBg .littleArticles .outer_2 {
    margin: 10px 7px 10px 8px;
}

.littleArticlesWithBg .sectionHeader a,
.littleArticles .sectionHeader a {
    font-size: 18px;
    font-family: "tablet_gothic_compressed";
    float: left;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.articleContainer.littleArticle .wrapper {
    height: 108px;
    overflow: hidden;
    padding-top: 72px;
    margin-top: 10px;
    width: 125px;
}

.articleContainer.middleArticle .wrapper .articlePhotoAuto {
    display: block;
    width: 280px;
    height: 158px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: absolute;
    left: 0px;
    top: 0px;
}

.articleContainer.middleArticle .wrapper .articlePhotoAuto img {
    height: auto;
    top: -20px;
}

.articleContainer.middleArticle .wrapper .articlePhotoAuto.autofun img {
    height: auto;
    top: -10px;
}
.articleContainer.bigArticle .articlePhoto img {
    width: 600px;
    height: 240px;
    position: absolute;
    top: 26px;
    left: 10px;
}

.articleContainer.bigArticle .articlePhoto .autofun {
    display: block;
    width: 600px;
    height: 240px;
    position: absolute;
    top: 26px;
    left: 10px;
    overflow: hidden;
}
.articleContainer.bigArticle .articlePhoto .autofun img {
    width: 600px;
    height: auto;
    position: absolute;
    top: -70px;
    left: 0;
}

.articleContainer.bigArticle .articlePhoto .iSport {
    width: 600px;
    height: 240px;
    position: absolute;
    top: 26px;
    left: 10px;
    display: block;
    overflow: hidden;
}

.articleContainer.bigArticle .articlePhoto .iSport img {
    width: 600px;
    height: auto;
    position: relative;
    top: -30px;
    left: 0px;
}

.articleContainer.middleArticle img {
    width: 280px;
    height: 158px;
    position: absolute;
    top: 0px;
    left: 0px;
}

.articleContainer.middleArticle .articlePhoto.iSport {
    width: 280px;
    height: 158px;
    position: absolute;
    top: 0px;
    left: 0px;
    display: block;
    overflow: hidden;
}

.articleContainer.middleArticle .articlePhoto.iSport img {
    width: 280px;
    height: auto;
    position: relative;
}

.articleContainer.smallArticle .articlePhoto {
    width: 280px;
    height: 158px;
    position: absolute;
    top: 0px;
    left: 0px;
    overflow: hidden;
}

.articleContainer.smallArticle.frantiska .articlePhoto {
    /*	height: auto;
	top: -40px;
	left: 0px;
	overflow: visible;*/
    height: auto;
    /*	top: -57px;
	left: -53px; */
    top: -113px;
    left: -31px;
    overflow: visible;
    z-index: 2;
}

.articleContainer.smallArticle .articlePhoto.iSport img {
    height: auto;
    position: relative;
    top: -20px;
}

.articleContainer.littleArticle .articlePhoto {
    width: 120px;
    height: 68px;
    position: absolute;
    top: 0px;
    left: 0px;
    overflow: hidden;
}

.articleContainer.littleArticle .articlePhoto img.iSport {
    width: 120px;
    height: auto;
    position: relative;
    top: -10px;
    left: 0px;
}

.articleContainer.littleArticle .articlePhoto img.autoCZ {
    width: 120px;
    height: auto;
    position: relative;
    top: -10px;
    left: 0px;
}

.articleContainer.bigArticle h3.title {
    font-family: "tablet_gothic_condensed";
    font-size: 40px;
    line-height: 42px;
    color: #16212d;
    margin-top: 0px;
    position: relative;
    padding-top: 259px;
}

.articleContainer.bigArticle h3.title a {
    color: #16212d;
    -webkit-transition: all 0.1s ease-out; /* Safari 3.2+, Chrome */
    -moz-transition: all 0.1s ease-out; /* Firefox 4-15 */
    -o-transition: all 0.1s ease-out; /* Opera 10.5–12.00 */
    transition: all 0.1s ease-out;
}

.articleContainer.bigArticle h3.title a:hover {
    text-decoration: underline;
}

.articleContainer .categoryTitle {
    font-family: "tablet_gothic_compressed";
    font-size: 16px;
    text-transform: uppercase;
    color: #ff0000;
    line-height: 26px;
    word-spacing: 3px;
}

.articleContainer.littleArticle .categoryTitle {
    font-size: 12px;
    height: 26px;

    overflow: visible;
}

.articleContainer.middleArticle {
    height: 216px;
}

.articleContainer.middleArticle h3.title,
#article .article-body .linkArticle h3 {
    font-family: "tablet_gothic_condensed";
    font-size: 22px;
    line-height: 30px;
    color: #16212d;
    float: left;
    width: 290px;
    margin: 0px;
    letter-spacing: 0px;
    position: relative;
    top: -4px;
    height: 122px;

    overflow: visible;
}

#article .article-body .linkArticle h3 {
    text-align: left;
}

#article .article-body .linkArticle h3 a {
    text-decoration: none;
    font-size: 30px;
}

#article .article-body .linkArticle h3 a:hover {
    text-decoration: underline;
}

#article .article-body .linkArticle .perex {
    text-align: left;
}

article .article-body .article-link-color {
    padding: 6px 0 4px;
}

#article .article-body .article-link-color a {
    font-size: 24px;
    color: #ff0000;
    text-decoration: none;
}

#article .article-body .article-link-color a:hover {
    text-decoration: underline;
}

.articleContainer.middleArticle h3.title a {
    color: #16212d;
    transition: all 0.1s ease-out;
    font-size: 30px;
    line-height: 29px;
}

.wikiFixTitle {
    font-size: 22px !important;
}

.articleContainer.middleArticle h3.title a:hover {
    text-decoration: underline;
}

.articleContainer.middleArticle p.perex {
    float: left;
    margin-left: 0px;
    width: 290px;
    font-family: Arial;
    font-size: 13px !important;
    color: #16212d;
    margin-bottom: 0px !important;
    line-height: 18px !important;
    bottom: -5px;
    height: 100%;
    overflow: hidden;
}

.articleContainer .articleContext {
    color: #98a3af;
    font-size: 12px;
    line-height: 15px;
    margin-top: 10px;
    position: absolute;
    bottom: 8px;
    left: 10px;
}

.articleContainer .articleContext a {
    color: #98a3af;
}

.articleContainer.inverted .articleContext a {
    color: #fff;
}

.alternative .articleContainer .articleContext * {
    color: #fff;
}

.articleContainer .articleContext .galleryIcon {
    width: 12px;
    height: 9px;
    display: inline-block;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat 0px -800px;
}

.articleContainer .articleContext .galleryTitle,
.articleContainer .articleContext .forumTitle,
.articleContainer .articleContext .videoTitle {
    padding: 0 3px;
    color: #98a3af;
}

.alternative .articleContainer .articleContext .galleryIcon {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat 0px -850px;
}

.articleContainer .articleContext .forumIcon {
    width: 13px;
    height: 11px;
    display: inline-block;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -100px -800px;
}

.alternative .articleContainer .articleContext .forumIcon {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -100px -850px;
}

.articleContainer .articleContext .sharesIcon {
    width: 12px;
    height: 10px;
    display: inline-block;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -50px -800px;
}

.alternative .articleContainer .articleContext .sharesIcon {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -50px -850px;
}

.articleContainer .articleContext .forumCount,
.articleContainer .articleContext .sharesCount,
.articleContainer .articleContext .galleryCount,
.articleContainer .articleContext .videoCount {
    color: #98a3af;
    margin-right: 10px;
}

.articleContainer.smallArticle {
    height: 436px;
}

.articleContainer.smallArticle h3 {
    margin-top: 17px;
    height: 128px;

    overflow: visible;
}

.articleContainer.smallArticle h3 a {
    color: #16212d;
    font-size: 30px;
    line-height: 32px;
    font-family: "tablet_gothic_condensed";
    letter-spacing: 0px;
    transition: all 0.2s ease-out;
}

.articleContainer.smallArticle p.perex {
    font-family: Arial;
    font-size: 15px;
    line-height: 20px;
    color: #16212d;
    margin-top: 22px;
}

.articleContainer.littleArticle h3.title {
    font-size: 15px;
    line-height: 18px;
    color: #16212d;
    margin: 0px;
}

.articleContainer.littleArticle h3.title a {
    color: #16212d;
    transition: all 0.1s ease-out;
}

.articleContainer.bigOneArticle {
    height: 440px;
    border-top: 0px;
}

.articleContainer.bigOneArticle .title {
    float: left;
    position: relative;
}

.articleContainer.bigOneArticle .title .arrow {
    display: block;
    position: absolute;
    top: 20%;
    right: -14px;
    width: 14px;
    height: 24px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -150px no-repeat;
    overflow: hidden;
}

.articleContainer.bigOneArticle h3 {
    display: block;
    float: left;
    background: #ff0000;
    padding: 0px;
    font-family: "tablet_gothic_condensed";
    color: #ffffff;
    height: 328px;
    font-size: 50px;
    width: 300px;
    line-height: 52px;
}

.articleContainer.bigOneArticle h3 a {
    color: #fff;
    padding: 10px 10px 0;
    display: block;
    width: 280px;
    height: 314px;
    overflow: hidden;
    /*overflow: visible;*/
}

.articleContainer.bigOneArticle h3 a:hover {
    text-decoration: underline;
}

.articleContainer.bigOneArticle img {
    width: 620px;
    height: 328px;
}

.articleContainer.bigOneArticle .perex {
    float: left;
    width: 610px;
    padding: 19px 0px;
    color: #16212d;
}

.articleContainer.bigOneArticle .perex a {
    color: #16212d;
    font-size: 15px;
    font-weight: bold;
    line-height: 20px;
    display: block;
}

.articleContainer.bigOneArticle .articleContext {
    left: 640px;
    bottom: 40px;
}

#article ol {
    list-style: decimal outside none !important;
    margin: 0 0 20px 40px;
}

#article ul {
    list-style: disc outside none !important;
    margin: 0 0 20px 0;
}

.minHeightG8 {
    min-height: 4300px;
}
#article .minHeightG8 {
    min-height: inherit !important;
}

/* Ikony v recentarticles
-----------------------------------------------------------------------------*/
.icon {
    display: block;
    width: 38px;
    height: 38px;
}

.icon.iconPlay {
    position: absolute;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -0px -150px;
}

.icon.iconPhoto {
    position: absolute;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -0px -100px;
}

.articleContainer.smallArticle .icon.iconPlay,
.articleContainer.smallArticle .icon.iconPhoto {
    top: 140px;
    left: 121px;
}

.articleContainer.middleArticle .icon.iconPlay,
.articleContainer.middleArticle .icon.iconPhoto {
    left: 260px;
    top: 60px;
}

.articleContainer.bigArticle .icon.iconPlay,
.articleContainer.bigArticle .icon.iconPhoto {
    left: 281px;
    top: 250px;
}

/* Sleviště
-----------------------------------------------------------------------------*/
.slevisteContainer .item {
    display: block;
    position: relative;
    padding: 20px 0 20px 0;
    border-bottom: 1px solid #e4e4e4;
}
.slevisteContainer .item.first {
    padding: 0 0 20px 0;
}
.articleContainer.slevisteContainer .wrapper {
    height: auto;
}
.slevisteContainer h3.title {
    display: block;
    width: 291px;
    height: 75px;
    overflow: hidden;
    position: relative;
    float: right;
    margin: 0 23px 0 0;
    font-size: 14px;
    line-height: 16px;
}
.articleContainer.slevisteContainer:hover a.hoverColor {
    color: #626161 !important;
}
.articleContainer.slevisteContainer h3.title a {
    color: #626161;
    text-decoration: none;
}
.articleContainer.slevisteContainer h3.title a:hover {
    color: #626161;
    text-decoration: underline;
}
.slevisteContainer .articlePhoto {
    display: block;
    width: 264px;
    height: 150px;
    overflow: hidden;
    position: relative;
    border: 1px solid #e4e4e4;
    float: left;
}
.slevisteContainer .cenaPred {
    display: block;
    width: 160px;
    position: absolute;
    right: 25px;
    top: 98px;
    z-index: 2;
    font-weight: bold;
    color: #bbbbbb;
    font-size: 18px;
    text-align: right;
    text-decoration: line-through;
}
.slevisteContainer .cenaPred span {
    padding: 0 0 0 5px;
    font-size: 12px;
}
.slevisteContainer .cenaPo {
    display: block;
    width: 160px;
    position: absolute;
    right: 25px;
    top: 120px;
    z-index: 2;
    font-weight: bold;
    color: #626161;
    font-size: 30px;
    text-align: right;
}
.slevisteContainer .cenaPo span {
    padding: 0 0 0 5px;
    font-size: 20px;
}
.slevisteContainer .articleArrow {
    display: block;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -30px no-repeat;
    width: 10px;
    height: 20px;
    position: absolute;
    right: 0px;
    top: 60px;
    z-index: 2;
}
.slevisteContainer .cenovka {
    display: block;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/sleviste-cenovka.png?v=68") 0px 0px no-repeat;
    width: 134px;
    height: 75px;
    position: absolute;
    left: 266px;
    top: 77px;
    z-index: 2;
}
.slevisteContainer .cenovka span {
    display: block;
    width: 90px;
    position: absolute;
    left: 36px;
    top: 8px;
    text-align: center;
    font-weight: bold;
    color: #ffffff;
    font-size: 36px;
}
.slevisteContainer .cenovka span {
    padding: 0 0 2px 5px;
    font-size: 24px;
}
.slevisteContainer .cenovka p {
    display: block;
    width: 100px;
    height: 25px;
    position: absolute;
    left: 50px;
    bottom: 8px;
    font-size: 24px;
    font-weight: bold;
    color: #e59e9e;
}

/* Reklama
-----------------------------------------------------------------------------*/
.fixed {
    position: fixed;
    top: 0;
}
.stickyContent {
    margin: 0 0 10px 0;
}
.homepageTabs {
    position: relative;
    float: left;
}
.tabsArea {
    position: relative;
    float: left;
}
.bottomBanner {
    position: relative;
}

#ads-dolni-banner {
    clear: both;
}
.ads-pr-perex {
    position: relative;
}
.boxPrace {
    position: absolute;
    z-index: 0;
    bottom: 1280px;
}
.boxPrace.noBottom {
    bottom: 1000px;
}

.boxSlevy {
    position: absolute;
    z-index: 0;
    bottom: 0px;
}

.boxHryRight {
    position: absolute;
    z-index: 0;
    bottom: 0px;
}
.container .boxHryRight .articleContainer.lastXMLGame {
    height: 90px;
}
.container .boxHryRight .articleContainer.noXMLGame:not(.firstWithBorder) .newGameImage {
    display: none;
}
.container .boxHryRight .articleContainer.noXMLGame.firstWithBorder .newGameImage {
    height: 40px;
    width: 83px;
    background: url("https://img2.cncenter.cz/images/abc/hry-prelepka-nove.png") no-repeat transparent !important;
    position: absolute;
    top: -33px;
}
.boxShopy {
    position: absolute;
    z-index: 0;
    bottom: 0px;
}

.boxHryRight.bot310,
.boxSlevy.bot310,
.boxShopy.bot310 {
    position: absolute;
    z-index: 0;
    bottom: 310px;
}

.boxHryRight.bot620,
.boxSlevy.bot620,
.boxShopy.bot620 {
    position: absolute;
    z-index: 0;
    bottom: 620px;
}

.container .boxHryRight {
    width: 280px;
    color: #ffffff;
    background: #16212d;
    border-top: 3px solid #f7b135;
    font-size: 15px;
    line-height: 17px;
    padding: 6px 10px 0 10px;
    margin: 0 0 0 10px;
}

.container .boxHryRight .articleContainer {
    background: transparent;
    border-top-color: #5c6773 !important;
    border-top-width: 1px;
    border-top-style: solid;
    padding: 9px 0 9px 0;
    color: #ffffff;
}

.container .boxHryRight .articleContainer.first {
    border-top: none;
}
.container .boxHryRight a,
.container .boxHryRight a:hover {
    color: #ffffff !important;
}

.container .boxHryRight.bottomStickyBlock h4,
.container .boxHryRight.bottomStickyBlock h4 a {
    color: #f7b135 !important;
    font-family: "tablet_gothic_compressed";
    font-size: 16px;
    line-height: 26px;
    height: 20px;
    text-transform: uppercase;
    padding: 0;
}

.container .boxHryRight .image {
    float: left;
    overflow: hidden;
    width: 120px;
}
.container .boxHryRight .title {
    float: left;
    margin-left: 8px;
    width: 140px;
    padding: 1px 0 0 0;
}
.container .boxHryRight .title span {
    display: block;
    font-family: "tablet_gothic_compressed";
    font-size: 20px;
    font-weight: bold;
}

.container .boxHryRight .categoryArrow {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    right: 0px;
}

.container .boxHryRight .categoryArrow .arrow {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat scroll -300px -216px transparent !important;
    width: 8px;
    height: 10px;
    display: block;
}

.boxSlevy .recentArticles .header,
.boxSlevy .headTitle,
.boxShopy .recentArticles .header,
.boxShopy .headTitle {
    margin-bottom: 5px;
}

.container .bottomStickyBlock .recentArticles h4 {
    background: none !important;
    color: #99a4aa !important;
    border-top: 2px solid #98a3af !important;
    font-family: "tablet_gothic_compressed";
    font-size: 16px;
    line-height: 26px;
    height: 20px;
    text-transform: uppercase;
    padding: 0;
}

.container .bottomStickyBlock .recentArticles .item .title a {
    font-weight: normal;
    line-height: 17px;
}

.bottomStickyBlock .recentArticles h4 a {
    background: none !important;
    color: #99a4aa !important;
}

.container .bottomStickyBlock .recentArticles a.hoverColor:hover {
    color: #99a4aa !important;
    text-decoration: none;
}

.container .bottomStickyBlock .recentArticles a .red {
    color: #ff0000 !important;
}

.container .bottomStickyBlock .recentArticles .categoryArrow .arrow {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat scroll -50px -216px transparent !important;
}

.vyrobekRight {
    float: right;
    margin-right: 2px;
}

.vyrobekRight.vyrobekArticle {
    position: relative;
    top: -3px;
}

.vyrobekTop,
.vyrobekBottom {
    margin: 0;
}

/* reklama - komerční sdělení na HP
-----------------------------------------------------------------------------*/
.ksHp.latestArticles {
    padding-top: 20px;
}
.ksHp.latestArticles .item {
    background: transparent;
    border-top: 2px solid #98a3af !important;
    border-bottom: 0px solid #98a3af !important;
    position: relative;
    padding: 0 10px 5px;
    background: #fff;
    height: 107px;
    overflow: hidden;
}
.ksHp.chateau .categoryArrow .arrow {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -50px -216px no-repeat !important;
}
.ksHp.latestArticles .item .title {
    float: left;
    width: 140px;
    height: 68px;
    padding: 0 0 0 10px;
}
.ksHp.latestArticles .item .categoryArrow {
    right: 5px;
}
.ksHp.latestArticles .komercniTitle {
    float: left;
    font-size: 8px;
    color: #99a4aa;
    padding: 5px 0 0 0;
}
.ksHp.latestArticles .item:first-child {
    padding: 0 10px 5px;
}

.chateau.ksHp.latestArticles .color,
.chateau.ksHp.latestArticles a.color,
.chateau.ksHp.latestArticles a.hoverColor:hover,
.chateau.ksHp.latestArticles .articleContainer:hover a.hoverColor {
    color: #99a4aa !important;
}

/* Vyzva v clanku - kontaktujte redakci
-----------------------------------------------------------------------------*/
.callUp {
    border-top: 1px solid #d3dae0;
    border-bottom: 1px solid #d3dae0;
    padding: 19px 0px;
}

.callUp .title {
    color: #ff0000;
    font-family: "tablet_gothic_condensed";
    font-size: 30px;
    line-height: 30px;
    float: left;
}

.callUp .contactNumber {
    color: #16212d;
    font-family: "tablet_gothic_condensed";
    font-size: 23px;
    line-height: 30px;
    float: right;
}

.callUp .contactNumber .phoneIco {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -950px -350px;
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-right: 7px;
}

.callUp form {
    color: #505a65;
    margin-top: 20px;
}

.callUp form input[type="text"] {
    border: 0;
    padding: 5px 10px;
    font-size: 15px;
    color: #505a65;
    line-height: 15px;
    margin: 5px 0;
    height: 24px;
    width: 200px;
}

.callUp form textarea {
    width: 380px;
    resize: none;
    height: 102px;
    border: none;
    padding: 10px;
    margin: 5px 0;
    color: #505a65;
    font-family: Arial;
    font-size: 15px;
}

.callUp form input[type="submit"] {
    color: #fff;
    float: right;
    margin-top: 10px;
}

.callUp form .inputs {
    float: left;
    width: 220px;
    padding-right: 20px;
}

.callUp form textarea {
    float: right;
}

.callUp .info {
    color: #717d8a;
    font-size: 13px;
    font-family: Arial;
    margin-top: 10px;
}

/* Bannery na HP
-----------------------------------------------------------------------------*/
.container .full940 {
    overflow: hidden;
    margin: 0 10px;
    width: 940px;
}

.container .full940 img {
    width: 940px;
}

/* reklama - v pravém sloupci
-----------------------------------------------------------------------------*/
.itemArticle148 {
    margin: 0 10px 10px 10px;
}
.itemArticle148 a {
    color: #ff0000;
}

.itemArticle148 h3 {
    color: #16212d;
    font-size: 16px;
    margin: 5px 0;
}
.itemArticle148 h3 a {
    color: #16212d;
    font-size: 16px;
}

.itemArticle148 p {
    color: #16212d;
    font-size: 13px;
    line-height: 16px;
}

#ads-pr-perex .adTitle,
#ads-pr-perex2 .adTitle,
#komercniSdeleni .adTitle {
    margin: 0 10px;
    color: #707b87;
}

#komercniSdeleni a {
    font-weight: bold;
    color: #16212d;
}

/* Arrows in perex
-----------------------------------------------------------------------------*/
.articleContainer p.perex:before {
    content: "";
    float: left;
    width: 10px;
    height: 10px;
    margin: 5px 3px 0 0;
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -250px -216px;
}

/* Tabs
-----------------------------------------------------------------------------*/
.tabsNavigation li {
    display: block;
    float: left;
    margin: 0 2px 0 0;
}

.tabsNavigation a {
    display: block;
    width: 88px;
    height: 40px;
    line-height: 20px;
    padding: 5px;
    font-size: 16px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    background-color: #98a3af;
    font-family: "tablet_gothic_condensed";
    text-decoration: none;
    transition: all 0.2s ease-out;
}

.tabsNavigation a:hover,
.tabsNavigation a.active {
    background-color: #ff0000;
}

.tabsNavigation a.active:after {
    content: "";
    width: 20px;
    height: 10px;
    position: absolute;
    top: 50px;
    left: 40px;
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -50px -900px;
}

.homepageTabs .inner_4 {
    margin: 0px;
}
.homepageTabs .latestArticles {
    min-height: 330px;
}

/* Facebook homepage
-----------------------------------------------------------------------------*/
.facebook {
    background-color: #fff;
    height: 70px;
}

/* Latest articles
-----------------------------------------------------------------------------*/
.latestArticles h4.header {
    color: #ffffff;
    font-family: "tablet_gothic_compressed";
    background: #ff0000;
    text-transform: uppercase;
    padding: 5px 10px;
    margin-bottom: 20px;
    width: 99px;
    text-align: center;
}

.latestArticles .item .time {
    float: left;
    width: 60px;
    font-family: Arial;
    font-size: 15px;
    color: #98a3af;
    font-weight: bold;
    line-height: 20px;
}

.latestArticles .item .position {
    float: left;
    width: 60px;
    font-family: Arial;
    font-size: 45px;
    color: #98a3af;
    font-weight: bold;
}

.latestArticles .item .title {
    float: left;
    width: 220px;
}

.latestArticles .item .image {
    float: left;
    width: 120px;
    overflow: hidden;
}

.latestArticles .item .image img {
    width: 120px;
}

.latestArticles.promoboxSmall .item .title {
    width: 155px;
    padding-right: 15px;
    padding-top: 4px;
    float: right;
    font-family: "tablet_gothic_condensed";
    /*text-transform: uppercase;*/
    font-size: 22px;
}

.latestArticles .item {
    padding: 10px 0px 9px;
    border-bottom: 1px solid #cad5df;
    position: relative;
    border-top: 0px;
    background-color: transparent;
}

.latestArticles .item:first-child {
    padding: 0px 0px 9px;
}

.latestArticles .item .title a {
    color: #16212d;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    transition: all 0.1s ease-out;
}

.latestArticles.promoboxSmall .item .title a {
    font-size: 22px;
    font-weight: normal;
    line-height: 24px;
}

.latestArticles .showAll {
    text-align: right;
    color: #ff0000;
    margin: 5px 0px;
}

.latestArticles .showAll a {
    color: #ff0000;
    text-decoration: none;
}

.latestArticles .showAll a:hover {
    text-decoration: underline;
}

.latestArticles .categoryArrow {
    width: 10px;
    float: left;
    height: 10px;
    margin: auto auto;
    position: absolute;
    right: 0px;
    top: 50%;
}

.latestArticles .categoryArrow .arrow {
    width: 8px;
    height: 10px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -250px -216px;
    display: block;
}

.goToLink {
    font-family: "tablet_gothic_compressed";
    color: #fff;
    background: #ff0000;
    font-size: 17px;
    letter-spacing: 1px;
    height: 30px;
    line-height: 30px;
    margin: 10px 10px 10px;
    padding: 0 10px;
    display: block;
    text-transform: uppercase;
    clear: both;
}

.whiteArrow {
    width: 8px;
    height: 10px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -0px -900px;
    display: inline-block;
    float: right;
    position: relative;
    top: 10px;
    right: 0px;
}

/* Article - policejni denik
-----------------------------------------------------------------------------*/

.goToLink.police {
    font-family: "tablet_gothic_compressed";
    color: #fff;
    background: #1a5bb5;
    font-size: 20px;
    letter-spacing: 0px;
    height: 40px;
    line-height: 40px;
    margin: 10px 0px 10px;
    padding: 0 10px;
    display: block;
    text-transform: none;
    clear: both;
    position: relative;
    display: block;
}

.goToLink.police .whiteArrow {
    top: 15px;
}

/* Sections headers
-----------------------------------------------------------------------------*/
.sectionHeader {
    border-top: 4px solid #ff0000;
    padding: 10px 0px;
    margin-top: 50px;
    margin-bottom: 0px;
    position: relative;
    overflow: visible;
}

.sectionHeader .categoryLogo {
    position: absolute;
    top: -5px;
    left: 0px;
    width: 170px;
    height: 75px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat 0px 0;
}

.proMuze .sectionHeader .categoryLogo {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -962px -959px;
}

.hobby .sectionHeader .categoryLogo {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -962px -1050px;
}

.sectionHeader h1,
.sectionHeader h2,
.sectionHeader .headline {
    display: block;
    color: #16212d;
    font-size: 50px;
    font-family: "tablet_gothic_compressed";
    float: left;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.sectionHeader.bg h1,
.sectionHeader.bg h2,
.sectionHeader.bg .headline {
    margin: 0px 10px;
}

.sectionHeader h1 a,
.sectionHeader h2 a {
    color: #16212d;
    transition: all 0.1s ease-out;
}

.sectionHeader .subSections {
    float: right;
    line-height: 50px;
}

.sectionHeader .subSections li {
    color: #ff0000;
    display: block;
    float: left;
    padding: 0px 0px;
    border-left: 1px solid #cad5df;
    position: relative;
}

.sectionHeader .subSections li.last {
    padding-right: 0px;
}

.sectionHeader .subSections a {
    color: #ff0000;
    font-family: "tablet_gothic_compressed";
    text-transform: uppercase;
    font-size: 23px;
    padding: 0px 20px;
    display: block;
    letter-spacing: 1px;
}

.sectionHeader .shadowDown {
    top: 10px;
}

.sectionHeader .dropdown {
    width: 250px;
    position: absolute;
    top: 50px;
    right: 0px;
    z-index: 100;
    display: none;
    padding-top: 20px;
}

.sectionHeader .dropdown .spaceWrapper {
    height: 19px;
    background: #e9eff4 !important;
}

.sectionHeader .dropdown.withSpace {
    padding-top: 0px;
}

.sectionHeader .dropdown li {
    display: block;
    float: none;
    border-left: 0px;
}

.sectionHeader .dropdown li a {
    border-top: 1px solid #fff;
}

.sectionHeader .subSections li:hover .dropdown {
    display: block;
}
.sectionHeader li a.dropdownMenu {
    margin-right: 10px;
}
.sectionHeader li a.dropdownMenu:after {
    width: 10px;
    content: "";
    height: 10px;
    position: absolute;
    top: 19px;
    right: 10px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -100px -900px;
    background-origin: content-box;
    background-clip: content-box;
}

.sectionHeader.withDropdowns {
}

.sectionHeader.withDropdowns ul.subSections {
}

.sectionHeader.withDropdowns ul.subSections li {
    position: relative;
}

.sectionHeader.withDropdowns ul.subSections li .dropdown {
    left: 0px;
    padding-top: 0px;
    top: 41px;
}

.sectionHeader.withDropdowns ul.subSections li:last-child .dropdown {
    left: auto;
}

.heliotrope .sectionHeader.withDropdowns li a.dropdownMenu:after {
    top: 20px;
}

.sectionHeader.withDropdowns ul.subSections li .dropdown a {
    line-height: 28px;
    padding: 11px 20px;
}

.sectionHeader .subSections li.special-obi {
    color: #ff0000;
    display: block;
    width: 135px;
    float: left;
    padding: 0px 0px;
    border-left: none;
    position: relative;
    top: -10px;
    background: transparent url(https://img2.cncenter.cz/images/blesk/partner-sekce-obi.png?v=200) no-repeat;
}

.sectionHeader .subSections li.special-obi a {
    color: #ff0000;
    font-family: "tablet_gothic_compressed";
    text-transform: uppercase;
    font-size: 12px;
    padding: 0 20px;
    display: block;
    letter-spacing: 1px;
    position: relative;
    bottom: -35px;
    text-align: center;
}

.sectionHeader .subSections li.noBorder {
    border-left: none;
}

/* Vertikalni vypis nejnovejsich clanku
-----------------------------------------------------------------------------*/

.recentArticles {
    margin-top: 10px;
    float: right;
}

.menuSection .recentArticles {
    float: left;
}

.recentArticles .header,
.headTitle {
    height: 35px;
    font-size: 16px;
    text-transform: uppercase;
    background: #ff0000;
    color: white;
    margin-bottom: 0px;
    line-height: 35px;
    padding-left: 10px;
    font-family: "tablet_gothic_condensed";
}

.recentArticles .footer.more {
    height: 30px;
    font-size: 30px;
    text-transform: uppercase;
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-2013/loadmore-sprites.png") no-repeat;
    color: #fff;
    margin: 0px;
    line-height: 30px;
    padding: 0px;
    font-family: "tablet_gothic_condensed";
    text-align: center;
    margin-bottom: 0px;
}

.recentArticles .footer.more a {
    font-family: "tablet_gothic_condensed";
    font-size: 16px;
    line-height: 30px;
    color: #98a3af !important;
    width: 300px;
    display: inline-block;
    text-decoration: none;
}

.recentArticles .footer.more:hover {
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-2013/loadmore-sprites.png") no-repeat 0px -50px;
}

.recentArticles .header a,
.headTitle a {
    color: #fff;
    display: block;
}

.recentArticles .item {
    border-bottom: 1px solid #cad5df;
    padding: 10px 0px 11px;
    position: relative;
    background-color: transparent !important;
    border-top: 0px !important;
    height: 66px;

    overflow: visible;
}

.recentArticles .item.first {
    padding-top: 10px;
}

.recentArticles .item.last {
    border-bottom: 0px;
}

.recentArticles .item .image {
    float: left;
    width: 120px;
    overflow: hidden;
}

.recentArticles .item .title {
    float: left;
    width: 150px;
    margin-left: 10px;
}

.recentArticles .item .title a {
    color: #16212d;
    font-weight: bold;
    font-size: 15px;
    transition: all 0.1s ease-out;
}

.recentArticles .item .image img {
    width: 120px;
    height: 68px;
}

.recentArticles .categoryArrow {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    right: 0px;
}

.recentArticles .categoryArrow .arrow {
    width: 8px;
    height: 10px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -250px -216px;
    display: block;
}

/* Specialni hlavicky ve vertikalnim vypisu
-----------------------------------------------------------------------------*/
.sportVertical .recentArticles .iconImage {
    height: 52px;
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -300px -450px;
    border-top: 1px solid #cad5df;
    border-bottom: 1px solid #cad5df;
    padding-top: 2px;
    margin-bottom: 0px;
    background-origin: content-box;
    background-clip: content-box;
    padding-left: 87px;
    padding-bottom: 0px;
    display: block;
}

.recentArticles .iconImage.autoCZ {
    height: 56px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -695px -1646px;
    border-top: 1px solid #cad5df;
    border-bottom: 1px solid #cad5df;
    padding-top: 4px;
    margin-bottom: 15px;
    background-origin: content-box;
    background-clip: content-box;
    padding: 6px 0 6px 10px;
    display: block;
}
.container .grid_12 .fb_autocz_banner {
    margin-top: 24px;
    position: relative;
    float: left;
}

/* Výpis článků z ČTK - velký
-----------------------------------------------------------------------------*/
.articleContainer.liveArticleBig {
    border: none;
}
.articleContainer.liveArticleBig .wrapper {
    border-top: 1px #cad5df solid;
    padding: 20px 20px 20px 0;
    min-height: 108px;
    height: auto;
}
.articleContainer.liveArticleBig.first {
    padding-top: 0;
}
.articleContainer.liveArticleBig.first .wrapper {
    border-top: none;
    padding-top: 5px;
}
.articleContainer.liveArticleBig h3.title {
    font-family: "tablet_gothic_condensed";
    font-size: 36px;
    line-height: 36px;
    /* color: #16212d; */
    margin: 0px;
    padding-left: 20px;
    letter-spacing: 0px;
    word-spacing: 3px;
    position: relative;
}
.articleContainer.liveArticleBig h3.title a {
    color: #16212d;
    -webkit-transition: all 0.1s ease-out;
    -moz-transition: all 0.1s ease-out;
    -o-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
}
.articleContainer.liveArticleBig.sectionHeader {
    border-top-width: 4px;
    border-top-style: solid;
    padding: 0 10px;
}
.articleContainer.liveArticleBig .articlePhoto {
    float: left;
    margin-right: 30px;
}
.articleContainer.liveArticleBig .articlePhoto img {
    max-width: 280px;
    max-height: 158px;
}
.articleContainer.liveArticleBig .date {
    color: #98a3af;
    font-size: 15px;
    padding-left: 20px;
    margin-top: 10px;
}
.articleContainer.liveArticleBig .date .time {
    font-weight: bold;
}
.liveArticleSmall {
    border-top: 1px #cad5df solid;
    padding: 20px 0;
}
.recentArticles .liveArticleSmall.first {
    border-top: none;
    padding: 10px 0;
}
.recentArticles .liveArticleSmall {
    padding: 10px 0;
}
.liveArticleSmall.first {
    padding: 20px 0;
}
.liveArticleSmall .title {
    font: bold 15px/18px Arial;
    color: #16212d;
}
.liveArticleSmall .title .time {
    font-weight: normal;
    color: #848f9b;
}
.liveArticleSmall .title a,
.liveArticleSmallHeading .title a {
    color: inherit;
}
.liveArticleSmall .articlePhoto {
    float: left;
    margin-right: 10px;
}
.liveArticleSmall .articlePhoto img {
    max-width: 120px;
    max-height: 68px;
}
.liveArticleSmallHeading h3 {
    font:
        bold 36px/1 "tablet_gothic_compressed",
        "Arial Narrow",
        Arial;
    margin-bottom: 1em;
    color: #16212d;
    word-spacing: 3px;
    letter-spacing: 0px;
}
.liveArticleSmallHeading.sectionHeader {
    padding: 0;
    border-top: none;
}

.liveArticleContainer {
    background: #fff;
    border-top: 4px solid #ff0000;
    position: relative;
}
.liveArticleContainer .wrapper {
    padding: 0 10px;
    min-height: 450px;
    margin-bottom: 10px;
}
.liveArticleContainer .categoryTitle {
    font-family: "tablet_gothic_compressed";
    font-size: 16px;
    text-transform: uppercase;
    color: #ff0000;
    line-height: 26px;
    word-spacing: 3px;
}
.liveArticleContainer .categoryLink {
    position: absolute;
    bottom: 0;
    right: 30px;
    font-family: "tablet_gothic_condensed";
    font-size: 16px;
    line-height: 20px;
    text-align: right;
}
.liveArticleContainer .categoryLink span {
    text-transform: uppercase;
}
.liveArticleContainer .categoryLink:after {
    width: 8px;
    height: 10px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -250px -216px;
    display: block;
    content: " ";
    position: absolute;
    right: -15px;
    top: 3px;
}
.liveArticleContainer a {
    color: #16212d;
}
.liveArticleHomeBig {
    padding: 10px 0;
    border-bottom: 1px #cad5df solid;
}
.liveArticleHomeBig img {
    max-width: 120px;
    max-height: 68px;
    float: left;
    margin-top: 5px;
}
.liveArticleHomeBig .title {
    padding-left: 20px;
    font:
        bold 20px/22px "tablet_gothic_condensed",
        "Arial Narrow",
        Arial;
    min-height: 70px;
    overflow: visible;
}
.liveArticleHomeSmall .title {
    font:
        normal 15px Arial,
        sans-serif;
    padding-left: 20px;
    margin: 1em 0;
    position: relative;
}
.liveArticleHomeSmall .title:before {
    width: 8px;
    height: 10px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -250px -216px;
    display: block;
    content: " ";
    position: absolute;
    left: 0px;
    top: 4px;
}
.liveArticleHomeBig .time,
.liveArticleHomeSmall .time {
    color: #848f9b;
}
.liveArticleHomeBig .time {
    font-size: 90%;
}
.pagingGrid4 .loadMore .load {
    width: 320px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/loadmore-sprites.png") no-repeat;
}
.pagingGrid4 .loadMore .load:hover,
.pagingGrid4 .loadMore .load:focus,
.pagingGrid4 .loadMore .load:active {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/loadmore-sprites.png") no-repeat 0px -50px;
}

/* Targeted articles
-----------------------------------------------------------------------------*/
.targetedArticles .sectionHeader {
    text-transform: uppercase;
    font-family: "tablet_gothic_compressed";
    font-size: 25px;
    background-color: #fff;
    padding: 10px;
    margin: 0;
}

.targetedArticles .smallArticle.inverted {
    border-top: 0px;
    background: #fe60b2;
    height: 440px;
    width: 280px;
    margin-top: 0px;
}

.targetedArticles .smallArticle.inverted h3.title a {
    color: #ffffff;
}

.targetedArticles .smallArticle.inverted.odd {
    margin-left: 0px;
    margin-right: 0px;
    padding-right: 19px;
}

.targetedArticles .smallArticle.inverted.even {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 19px;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
}

.targetedArticles .smallArticle.inverted.even .articleContext {
    left: 20px;
}

.targetedArticles .smallArticle.inverted .articleContext {
    color: #ffffff;
}

.targetedArticles .smallArticle.inverted .forumIcon {
    background-position: -100px -850px;
}

.targetedArticles .smallArticle.inverted .sharesIcon {
    background-position: -50px -850px;
}

.targetedArticles .smallArticle.inverted .galleryIcon {
    background-position: 0px -850px;
}

.targetedArticles .smallArticle.inverted .articleContext .forumCount,
.targetedArticles .smallArticle.inverted .articleContext .sharesCount,
.targetedArticles .smallArticle.inverted .articleContext .galleryCount {
    color: #ffffff;
}

.targetedArticles .smallArticle.inverted p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -200px -232px;
}

/* Enquiry
-----------------------------------------------------------------------------*/
.enquiry {
    margin: 10px 0;
    padding: 15px;
    background-color: white;
}

.enquiry ul li {
    margin-bottom: 40px;
    list-style: none;
}

.enquiry strong.question {
    display: block;
    color: #16212d;
    font-size: 36px;
    font-family: "tablet_gothic_compressed";
    margin: 0 0 20px;
    color: #16212d;
    line-height: 100%;
}

.enquiry a.option {
    display: block;
    color: #5c6773;
    margin: 5px 0;
}

.enquiry a.option.yourVote {
    color: #ff0000;
}

.enquiry a.option .circle {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    float: left;
    border: 1px solid #cad5df;
    margin: 0 10px 0 0;
    transition: background-color 0.2s ease-in-out;
}

.enquiry.voted a.option .circle {
    display: none;
}

.enquiry a.option:hover .circle {
    background-color: #ff0000;
}

.enquiry a.option .value {
    display: none;
    width: 30px;
    height: 30px;
    float: left;
    margin: 0 10px 0 0;
    font-size: 15px;
}

.enquiry.voted a.option .value {
    display: block;
}

.enquiry a.option .answer {
    font-family: "Arial";
    font-size: 20px;
    line-height: 25px;
    top: 5px;
    position: relative;
    width: 90%;
    float: left;
    padding-bottom: 10px;
}

.enquiry a.option .graph {
    display: none;
    margin: 0 0 0 40px;
    height: 4px;
    width: 100%;
    clear: both;
}

.enquiry a.option .graph .graphValue {
    display: block;
    height: 4px;
    min-width: 1px;
    background-color: #5c6773;
    margin-left: -40px;
}

.enquiry a.option.yourVote .graph .graphValue {
    background-color: #ff0000;
}

.enquiry.voted a.option .graph {
    display: block;
}

.enquiry .countVotes {
    padding: 5px 0;
    margin: 10px 0 0;
    font-size: 12px;
    border-top: 1px solid #cad5df;
    border-bottom: 1px solid #cad5df;
    text-align: center;
    color: #343f4b;
}

.enquiry .countVotes {
    padding: 5px 0;
    margin: 10px 0 0;
    font-size: 12px;
    border-top: 1px solid #cad5df;
    border-bottom: 1px solid #cad5df;
    text-align: center;
    color: #343f4b;
}
.enquiry .enq-answer-pic {
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 10px;
    vertical-align: middle;
    margin-top: -20px;
}

/* Enquiry article
-----------------------------------------------------------------------------*/
.enquiryArticle {
    color: #ff0000;
    background: transparent;
    border-top: 4px solid #ff0000;
    padding: 0 10px 10px;
    position: relative;
    border-bottom: 1px solid #cad5df;
}

.enquiryArticle .title {
    font-size: 18px;
    line-height: 18px;
    font-weight: bold;
    font-family: "tablet_gothic_condensed";
    text-transform: uppercase;
    padding: 10px 0;
}
.enquiryArticle .votes {
    color: #16212d;
    font-size: 12px;
    font-family: Arial;
}

.enquiryArticle .votes .total {
    font-weight: bold;
}

/* Enquiry homepage
-----------------------------------------------------------------------------*/
.enquiryHP {
    background: #fff;
    border-top: 4px solid #99a4aa;
    padding: 0 10px 10px;
    position: relative;
    color: #99a4aa;
}

.enquiryHP .title {
    font-family: "tablet_gothic_compressed";
    font-size: 16px;
    text-transform: uppercase;
    line-height: 26px;
}

.enquiryHP .question {
    color: #16212d;
    font-size: 30px;
    font-family: "tablet_gothic_condensed";
    text-align: center;
    line-height: 30px;
    position: relative;
    top: -10px;
    letter-spacing: 0px;
}

.enquiryHP .firstChoice {
    float: left;
    width: 50%;
    background: #91c837;
    height: 50px;
    max-width: 655px;
    min-width: 265px;
}

.enquiryHP .firstChoice .image,
.enquiryHP .firstChoice .answer,
.enquiryHP .firstChoice .percents {
    float: left;
    font-family: "tablet_gothic_condensed";
    line-height: 50px;
    font-size: 36px;
}

.enquiryHP .firstChoice .answer {
    color: #fff;
    padding: 0px 10px;
}

.enquiryHP .firstChoice .percents {
    color: #355401;
}

.enquiryHP .secondChoice {
    float: right;
    /*width: 50%;*/
    min-width: 250px;
    background: #ff0000;
    height: 50px;
    max-width: 655px;
    min-width: 265px;
}

.enquiryHP .secondChoice .image,
.enquiryHP .secondChoice .answer,
.enquiryHP .secondChoice .percents {
    float: right;
    font-family: "tablet_gothic_condensed";
    line-height: 50px;
    font-size: 36px;
}

.enquiryHP .firstChoice .image img {
    height: 50px;
}
.enquiryHP .secondChoice .image img {
    height: 50px;
}

.enquiryHP .secondChoice .answer {
    color: #fff;
}

.enquiryHP .secondChoice .percents {
    color: #540101;
    padding: 0px 0px;
}

.enquiryHP .secondChoice .answer {
    margin: 0 10px;
}

.enquiryHP .votes {
    font-size: 15px;
    margin-top: 10px;
}

.enquiryHP .votes .first {
    float: left;
}

.enquiryHP .votes .second {
    float: right;
}

.container .inner_7 .article-body .inner_12 {
    width: 620px;
    margin: 0 0 0 -90px;
}

.container .inner_7 .article-body .enquiryHP .firstChoice {
    max-width: 335px;
    min-width: 265px;
}

.container .inner_7 .article-body .enquiryHP .secondChoice {
    max-width: 335px;
    min-width: 265px;
}

.enquiryHP.portraits {
    min-height: 140px;
    padding: 0 10px 0px;
    margin-bottom: 30px;
}

.enquiryHP.portraits .choicesWrapper {
    width: 800px;
    position: absolute;
    left: 70px;
    top: 56px;
    z-index: 1;
}

.enquiryHP.portraits .firstChoiceImage {
    position: relative;
    float: left;
    z-index: 2;
}

.enquiryHP.portraits .secondChoiceImage {
    position: relative;
    float: right;
    z-index: 2;
}

.enquiryHP.portraits .votes {
    font-size: 15px;
    line-height: 25px;
}

.enquiryHP.portraits .question {
    top: 0px;
}

.enquiryHP.portraits .firstChoice,
.enquiryHP.portraits .secondChoice {
    max-width: 730px;
    min-width: 70px;
}

.enquiryHP.portraits .answersWrapper {
    position: relative;
    /*bottom: -20px;*/
    width: 100%;
    margin-top: 10px;
    left: 0px;
    font-family: "tablet_gothic_condensed";
    font-size: 16px;
}

.enquiryHP.portraits .answersWrapper .firstChoiceAnswer {
    float: left;
    padding-left: 10px;
}

.enquiryHP.portraits .answersWrapper .secondChoiceAnswer {
    float: right;
    padding-right: 10px;
}

.enquiryHP.portraits .answersWrapper .secondChoiceAnswer a,
.enquiryHP.portraits .answersWrapper .firstChoiceAnswer a {
    color: #848f9b;
}

.enquiryHP.portraits .secondChoice .percents {
    padding-right: 10px;
}

.enquiryHP.portraits .firstChoice .percents {
    padding-left: 10px;
}

/* Videoportal
-----------------------------------------------------------------------------*/
.recentVideos {
    background: #16212d;
    overflow: visible;
    padding: 20px 0;
}
.recentArticles.recentVideos {
    float: none;
    width: 940px !important;
}
.recentArticles.recentVideos .header {
    color: #b6c1cc;
    float: none;
    font-size: 50px;
    line-height: 50px;
    background: none;
    height: auto;
    padding: 0px 0px 0px 10px;
    margin-bottom: 0px;
}

.recentArticles.recentVideos .left {
    float: left;
    width: 220px;
}

.recentArticles.recentVideos .header a {
    color: #b6c1cc;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -750px -700px;
    text-indent: -9999px;
    width: 190px;
    height: 63px;
}

.recentArticles.recentVideos .header a:hover {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -750px -800px;
}

.recentArticles.recentVideos .partyWithMarketa {
    display: block;
    width: 188px;
    height: 120px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -1000px -690px;
    position: relative;
    left: 0px;
    margin-left: 10px;
    border: 1px solid #5c6773;
    margin-top: 10px;
}

.recentArticles.recentVideos .partyWithMarketa a {
    display: block;
    color: #b469f5;
    position: absolute;
    width: 80px;
    font-family: "tablet_gothic_condensed";
    font-size: 22px;
    line-height: 25px;
    left: 0px;
    top: 0px;
    cursor: pointer;
    padding-left: 100px;
    padding-top: 30px;
    padding-right: 10px;
    padding-bottom: 40px;
}

.recentVideos .item {
    float: left;
    width: 160px;
    border: none;
    border-left: 1px solid #5c6773;
    padding-left: 9px;
    padding-right: 10px;
    padding-top: 0px;
    height: 210px;
}

.recentArticles.recentVideos .item {
    height: auto;
    padding: 0px 10px;
    border-bottom: none;
    float: left;
    width: 160px;
    border: none;
    border-left: 1px solid #5c6773;
    padding-left: 9px;
    padding-right: 10px;
    padding-top: 0px;
}

.recentVideos .item .image {
    width: 160px;
    height: 90px;
}

.recentVideos .item .image img {
    width: 160px;
    height: auto;
}

.recentVideos .item .title {
    margin: 25px 0px 20px;
    width: 160px;
    height: 80px;
}

.recentVideos .item .title a {
    color: #e9eff4;
    font-size: 20px;
    font-family: "tablet_gothic_condensed";
}

.recentVideos .item .title a:hover {
    color: #b469f5 !important;
}

.recentVideos .item .views {
    font-size: 12px;
    color: #98a3af;
    position: absolute;
    bottom: 0px;
    right: 10px;
}

.recentVideos .item .iconPlay {
    position: absolute;
    top: 70px;
    left: 71px;
}

/* Videoportal v souvisejicich
-----------------------------------------------------------------------------*/
.relatedWrapper .recentArticles.recentVideos {
    margin: 0;
    padding: 10px 10px 5px;
    width: 620px !important;
}

.relatedWrapper .recentArticles.recentVideos .header {
    padding: 0px;
    height: 40px;
}

.relatedWrapper .recentArticles.recentVideos .header a {
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-2013/logo-tv-blesk.png") no-repeat;
    width: 122px;
    background-clip: content-box;
    background-origin: content-box;
    background-size: 122px;
    height: auto;
}

.relatedWrapper .recentVideos .item .title a {
    font-size: 12px;
    font-family: Arial;
    line-height: 14px;
    font-weight: bold;
}

.relatedWrapper .recentVideos .item .image img {
    width: 140px;
}

.relatedWrapper .recentVideos .item .iconPlay {
    left: 60px;
    top: 55px;
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -150px -150px no-repeat !important;
}

.relatedWrapper .recentArticles.recentVideos .item {
    width: 140px;
    padding: 0px 13px;
}

.relatedWrapper .recentArticles.recentVideos .left {
    width: 132px;
}

.relatedWrapper .recentVideos .item .image {
    width: 140px;
    height: 80px;
}

.relatedWrapper .recentVideos .item .title {
    margin: 15px 0px 0px;
    height: 43px;
    width: 140px;
}

.relatedWrapper .recentArticles.recentVideos .item.last {
    padding-right: 0px;
}

.relatedWrapper .relatedWrapper .recentArticles.recentVideos .partyWithMarketa {
}

.relatedWrapper .recentArticles.recentVideos .partyWithMarketa {
    width: 120px;
    margin: 0px;
    height: 96px;
    width: 120px;
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -970px -700px;
}

.relatedWrapper .recentArticles.recentVideos .partyWithMarketa a {
    padding-left: 5px;
    padding-top: 47px;
    padding-bottom: 0px;
    padding-right: 23px;
    text-shadow: 2px 2px 2px #000;
}

/* Videa z hvězdných reportérů - pokud chybí v sloupci reklama
-----------------------------------------------------------------------------*/
.recentVideos.missingCommerce {
    background: #ffffff;
    padding: 10px 0 35px;
    position: relative;
    margin-top: 10px;
}

.recentVideos.missingCommerce .top {
    padding-bottom: 25px;
}

.recentVideos.missingCommerce .top .logo {
    width: 175px;
    height: 50px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -850px -1300px no-repeat;
    float: left;
    margin-left: 10px;
}

.recentVideos.missingCommerce .top .header {
    float: right;
    font-family: "tablet_gothic_condensed";
    color: #16212d;
    font-size: 22px;
    line-height: 25px;
    width: 95px;
    text-transform: uppercase;
    padding-right: 10px;
}

.recentVideos.missingCommerce .top .header a {
    color: #16212d;
}

.recentVideos.missingCommerce .item {
    border: none;
}

.recentVideos.missingCommerce .articleContainer.big {
}
.recentVideos.missingCommerce .articleContainer .iconPlay {
    position: absolute;
    top: 150px;
    left: 133px;
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -600px -150px no-repeat !important;
}
.recentVideos.missingCommerce .articleContainer.small .iconPlay {
    top: 83px;
    left: 61px;
}

.recentVideos.missingCommerce .articleContainer.big .title {
    position: relative;
    width: 230px;
    color: #16212d;
    top: 0px;
}

.recentVideos.missingCommerce .articleContainer .title a {
    color: #16212d;
}

.recentVideos.missingCommerce .articleContainer.big .title a {
    font-size: 24px;
}

.recentVideos.missingCommerce .articleContainer.big .image img,
.recentVideos.missingCommerce .articleContainer.big .image {
    width: 280px;
    height: 168px;
}

.recentVideos.missingCommerce .articleContainer.small .image,
.recentVideos.missingCommerce .articleContainer.small .image img {
    width: 140px;
    height: 100px;
}

.recentVideos.missingCommerce .articleContainer.small {
    color: #16212d;
}

.recentVideos.missingCommerce .marketa {
    width: 168px;
    height: 360px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -1050px -900px;
    position: absolute;
    right: -20px;
    bottom: 0;
    pointer-events: none;
}

/* Fotogalerie na hlavni strance
-----------------------------------------------------------------------------*/
.jcarousel {
    position: relative;
    overflow: hidden;
    height: 90px;
    width: 900px;
    left: 20px;
    margin: 5px 0px;
    float: left;
}

.jcarousel ul {
    width: 20000em;
    position: absolute;
    list-style: none;
    margin: 0;
    padding: 0;
}

.jcarousel li {
    float: left;
}

/* Pro externi galerii */
.galleryHP .jcarousel li {
    position: relative;
}

a.jcarousel-prev {
    width: 10px;
    height: 10px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -250px -216px;
    display: block;
    text-indent: 9999px;
    padding-top: 40px;
    background-clip: content-box;
    background-origin: content-box;
    padding-bottom: 40px;
    position: absolute;
    left: 5px;
    top: 35px;
    padding-left: 10px;
    transform: rotate(180deg);
}

a.jcarousel-next {
    width: 10px;
    height: 10px;
    background: #16212d url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -250px -216px;
    display: block;
    text-indent: -9999px;
    position: absolute;
    right: 5px;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 10px;
    background-clip: content-box;
    background-origin: content-box;
    top: 35px;
}

.jcarousel ul li img {
    width: 160px;
    height: 90px;
    margin: 0px 10px;
}

.galleryHP {
    background: #16212d;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: "tablet_gothic_condensed";
    font-size: 16px;
    position: relative;
    overflow: hidden;
}

.galleryHP .title {
    color: #ffffff;
    margin: 0 5px 0 10px;
    line-height: 20px;
    display: block;
    float: left;
    text-transform: uppercase;
}

.galleryHP a.galleryLink {
    color: #ff0000;
    line-height: 20px;
    display: block;
    float: left;
    text-transform: uppercase;
}

/* Blesk pro zeny - horoskopy
-----------------------------------------------------------------------------*/
.horoscopeBPZ .header {
    color: #fff;
    background: #fe60b2;
    padding: 0px 10px;
    margin-bottom: 5px;
    margin-top: 10px;
    line-height: 32px;
    font-family: "tablet_gothic_condensed";
    text-transform: uppercase;
}

.horoscopeBPZ .sign {
    background: #ffffff;
    padding: 8px 5px;
    border-bottom: 1px solid #cad5df;
    color: #16212d;
    font-size: 15px;
}

.horoscopeBPZ .sign .right {
    float: right;
    width: 200px;
}

.horoscopeBPZ .sign .caption {
    font-weight: bold;
    float: left;
    margin-right: 10px;
}

.horoscopeBPZ .sign .image {
    float: left;
    width: 50px;
    height: 50px;
    margin: 25px 25px 0px 15px;
}

.horoscopeBPZ .sign .date {
    color: #98a3af;
}

.horoscopeBPZ .sign .horoscope {
    line-height: 16px;
    font-size: 13px;
    margin-top: 0px;
}

.horoscopeBPZ .sign.aries .image {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat 0px -50px;
}

.horoscopeBPZ .sign.taurus .image {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -50px -50px;
}

.horoscopeBPZ .sign.gemini .image {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -100px -50px;
}

.horoscopeBPZ .sign.cancer .image {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -150px -50px;
}

.horoscopeBPZ .sign.leo .image {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -200px -50px;
}

.horoscopeBPZ .sign.virgo .image {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -250px -50px;
}

.horoscopeBPZ .sign.libra .image {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -300px -50px;
}

.horoscopeBPZ .sign.scorpio .image {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -350px -50px;
}

.horoscopeBPZ .sign.sagittarius .image {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -400px -50px;
}

.horoscopeBPZ .sign.capricorn .image {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -450px -50px;
}

.horoscopeBPZ .sign.aquarius .image {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -500px -50px;
}

.horoscopeBPZ .sign.pisces .image {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -550px -50px;
}

/* Vertikalni promoboxy na HP
-----------------------------------------------------------------------------*/
.verticalPromo {
    position: relative;
}
.verticalPromo .title {
    position: absolute;
    bottom: 100px;
    font-size: 57px;
    color: white;
    font-weight: bold;
    font-family: "tablet_gothic_condensed";
    left: 20px;
    width: 260px;
    border-bottom: 10px solid #ffffff;
}

.verticalPromo .title a {
    color: #ffffff;
}

.verticalPromo .description {
    position: absolute;
    bottom: 30px;
    color: #fff;
    left: 20px;
    width: 260px;
    font-size: 18px;
    font-weight: normal;
}

/* Komentare Frantisky
-----------------------------------------------------------------------------*/
.frantiskaComments {
    position: relative;
    border-bottom: 4px solid #00c8be;
    height: 436px;
    overflow: visible;
    background: transparent;
}

.frantiskaComments .relatedComments .comment a {
    color: #16212d;
}

.frantiskaComments .articleContainer {
    background: transparent;
    padding: 0 0 10px 0;
}

.frantiskaComments .articleContainer .articleContext {
    left: 0px;
    position: relative;
    z-index: 10;
}

.frantiskaComments .categoryTitle {
    font-size: 36px;
    line-height: 36px;
    margin: 10px 0px;
    text-transform: none;
}

.frantiskaComments .perex {
    font-size: 15px;
    line-height: 20px;
    overflow: hidden;
    margin-bottom: 20px;
    position: relative;
    z-index: 10;
}

.frantiskaComments .relatedComments .comment {
    color: #16212d;
    font-size: 15px;
    padding: 5px 0px;
}

.frantiskaComments h3.title {
    margin-bottom: 10px;
}

.frantiskaComments h3.title a {
    color: #16212d;
    font-size: 33px;
    line-height: 36px;
    font-family: "tablet_gothic_condensed";
    transition: all 0.1s ease-out;
}

.frantiskaComments .iconImage {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -500px -445px;
    height: 203px;
    position: absolute;
    bottom: -33px;
    right: 0;
    width: 275px;
    z-index: 1;
}

.frantiskaComments .relatedComments .comment a:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -450px -216px no-repeat !important;
    content: "";
    float: left;
    width: 10px;
    height: 10px;
    margin: 3px 3px 0 0;
}

.fashionTrends .iconImage {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -400px -300px;
    overflow: visible;
    background-clip: content-box;
    background-origin: content-box;
    width: 155px;
    height: 108px;
    float: left;
    position: absolute;
    bottom: 10px;
}

.fashionTrends .sectionHeader h1,
.fashionTrends .sectionHeader h2 {
    margin-left: 150px;
}

/* Modni policie na HP
-----------------------------------------------------------------------------*/
.modniPolicie .articleContainer {
    height: 676px;
    background: #16212d;
    color: #c0ced5;
    position: relative;
}

.modniPolicie .articleContainer h3 {
    margin-top: 520px;
    position: relative;
    padding-right: 10px;
    width: 270px;
}

.modniPolicie .articleContainer h3 a {
    color: #c0ced5;
    font-family: "tablet_gothic_condensed";
    font-size: 30px;
    line-height: 30px;
    height: 120px;
    display: block;
    transition: all 0.1s ease-out;
}

.modniPolicie .articleContainer h3 span {
    width: 10px;
    height: 10px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -450px -216px;
    display: block;
    text-indent: -9999px;
    position: absolute;
    right: 0px;
    bottom: 66px;
}

.modniPolicie .articleContainer .imageTop {
    position: absolute;
    top: 26px;
    left: 10px;
    display: block;
    width: 280px;
    height: 510px;
    overflow: hidden;
    background: #fff;
}

.modniPolicie .articleContainer .imageTop img {
    height: 520px;
}

.modniPolicie .articleContainer .perex {
    font-family: Arial;
    font-size: 15px;
    line-height: 20px;
    margin-top: 18px;
}

/* Detail modni policie
-----------------------------------------------------------------------------*/
#article .fashionPolice .leadsection {
    padding-right: 200px;
    border-bottom: 4px solid #00c8be;
    position: relative;
    margin-bottom: 80px;
}

#article .fashionPolice .leadsection .fashionPoliceIcon {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/frantiska4.png?v=68") no-repeat;
    width: 240px;
    height: 260px;
    position: absolute;
    right: -15px;
    bottom: 0px;
}

#article .fashionPolice .leadsection p {
    font-size: 18px;
    line-height: 25px;
    font-weight: normal;
    color: #16212d;
    margin-bottom: 60px;
}

/* Modni policie - skryta data pro mobilní aplikaci
---------------------------------------------------------------*/
.articleLink.objectInText {
    display: none !important;
}

/* Dívka dne na HP
-----------------------------------------------------------------------------*/
.divkaDne .articleContainer {
    height: 680px;
    background: #16212d;
    color: #c0ced5;
    position: relative;
    padding: 0;

    overflow: visible;
    border-top: none;
}

.divkaDne .articleContainer .topLayer {
    height: 680px;
    background: url("https://img2.cncenter.cz/images/blesk-2013/layer-divka.png") no-repeat;
    color: #c0ced5;
    position: absolute;
    z-index: 5;
    display: block;
    width: 300px;
    top: 0;
    left: 0;
}
.divkaDne .articleContainer .topLayer:hover {
    text-decoration: none;
}

.divkaDne .articleContainer .mainImage {
    position: relative;
    z-index: 2;
    width: 300px;
}

.divkaDne .articleContainer h3 {
    position: absolute;
    z-index: 5;
    display: block;
    width: 255px;
    bottom: 140px;
    left: 21px;
    font-family: "tablet_gothic_condensed";
    font-size: 57px;
    line-height: 60px;
    text-transform: uppercase;
}

.divkaDne .articleContainer h3 a {
    display: block;
    color: #fff;
    border-bottom: 10px solid #fff;
    transition: all 0.1s ease-out;
}

.divkaDne .articleContainer h3 a .superTitle {
    font-size: 16px;
    line-height: 20px;
}

.divkaDne .articleContainer h3 a:hover {
    /*	border-bottom: 10px solid #8b7c5e; */
    border-bottom: 10px solid #ff5a00;
}
.divkaDne .articleContainer h3 a:hover,
.divkaDne .articleContainer h3 a:hover * {
    text-decoration: none;
}

.divkaDne .articleContainer .joke {
    position: absolute;
    z-index: 5;
    display: block;
    width: 255px;
    bottom: 40px;
    left: 21px;
}
.divkaDne .articleContainer .joke .today span {
    width: 10px;
    height: 10px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat 0px -216px;
    display: block;
    text-indent: -9999px;
    position: absolute;
    right: -10px;
    bottom: 30px;
}

.divkaDne .articleContainer .joke .today {
    color: #707b87;
    font-weight: bold;
    line-height: 19px;
}

.divkaDne .articleContainer .joke a {
    font-family: Arial;
    font-size: 15px;
    line-height: 19px;
    /*text-transform: uppercase;*/
    color: #e9eff4;
}

.divkaDne .articleContainer .joke .today {
    color: #707b87;
    font-weight: bold;
    line-height: 19px;
}

.divkaDne .articleContainer .joke.yesterday {
    left: 220px;
    bottom: 20px;
    width: 60px;
}

.divkaDne .articleContainer .joke.yesterday img {
    width: 50px;
    height: 90px;
    border: 5px solid #6f634b;
}

/* Dívka dne - detail
-----------------------------------------------------------------------------*/
.divkaDneDetail {
    background: #16212d;
    color: #c0ced5;
}

.divkaDneDetail h1 {
    font-family: "tablet_gothic_condensed";
    font-size: 36px;
    line-height: 36px;
    padding: 10px;
}

.divkaDneDetail .jokeContainer {
    /*	background-color: #8b7c5e; */
    background-color: #ff5a00;
    font-family: "tablet_gothic_condensed";
    font-size: 26px;
    line-height: 28px;
    padding: 10px;
    color: #fff;
}

.divkaDneDetail .jokeContainer .header {
    font-family: "tablet_gothic_condensed";
    font-size: 16px;
    line-height: 16px;
    color: #fff;
    margin: 0 0 10px;
    text-transform: uppercase;
}

.divkaDneDetail .detail {
    min-height: 300px;
    position: relative;
}

.divkaDneDetail .detail .image {
    margin: 10px;
    float: left;
    max-width: 50%;
}

.divkaDneDetail .detail {
    min-height: 480px;
    position: relative;
    margin: 0 10px 0 0;
}

.divkaDneDetail .detail .smsCode {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 45%;
    color: #cad5df;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
}

.divkaDneDetail .detail .smsCode .or {
    display: block;
    font-weight: bold;
}

.divkaDneDetail .detail .smsCode .title {
    text-align: center;
    font-family: "tablet_gothic_condensed";
    color: #fff;
    font-size: 36px;
    line-height: 36px;
    margin: 0 0 10px;
}

.divkaDneDetail .voting .top {
    margin: 15px 0;
    border-top: 1px solid #848f9b;
    border-bottom: 1px solid #848f9b;
    width: 46%;
    float: left;
    padding: 10px 0;
}

.divkaDneDetail .voting .top .header {
    text-align: center;
    font-size: 12px;
    color: #848f9b;
}

.divkaDneDetail .voting .votes {
    text-align: center;
    letter-spacing: -1px;
    font-size: 160px;
    font-family: "tablet_gothic_condensed";
}

.divkaDneDetail .voting .voteNumbers {
    margin: 5px 0 0;
    text-align: center;
    font-family: "tablet_gothic_condensed";
}

.divkaDneDetail .voting .voteNumbers a {
    color: #848f9b;
    text-decoration: none;
    display: inline-block;
    margin: 0 10px;
}

/* Divka dne vypis
-----------------------------------------------------------------------------*/
.articleContainer.middleArticle.tiles {
    padding-top: 5px;
    height: 235px;
}

.articleContainer.middleArticle.tiles .wrapper {
    padding-left: 0;
    padding-top: 170px;
    height: 65px;
}

.articleContainer.middleArticle.tiles h3.title {
    top: 0;
}

.articleContainer.middleArticle.tiles .articlePhoto {
    padding-top: 5px;
}

.articleContainer.middleArticle.tiles h3.title a {
    font-size: 36px;
}

/* Markéta
-----------------------------------------------------------------------------*/
.partyMarketa .iconImage {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -1000px -690px;
    overflow: visible;
    background-clip: content-box;
    background-origin: content-box;
    width: 120px;
    height: 120px;
    float: left;
    position: absolute;
    bottom: 10px;
    left: 15px;
}

.partyMarketa .sectionHeader h1,
.partyMarketa .sectionHeader h2 {
    margin-left: 150px;
}
.partyMarketa .iconImage2 {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -750px -800px;
    overflow: visible;
    background-clip: content-box;
    background-origin: content-box;
    width: 190px;
    height: 60px;
    float: left;
    position: absolute;
    bottom: 10px;
    left: 0px;
}

/* Hry
-----------------------------------------------------------------------------*/
.games {
    background: #16212d;
    padding: 20px 10px;
    overflow: visible;
    color: #fff;
}

.games .header {
    font-size: 50px;
    color: #f7b135;
    font-family: "tablet_gothic_condensed";
    text-transform: uppercase;
    float: left;
    width: 200px;
}

.games .game {
    float: left;
    border-left: 1px solid #5c6773;
    padding: 10px 10px 10px 9px;
    width: 160px;
    height: 170px;
}

.games .game h3 {
    margin: 10px 0px;
}

.games .game h3 a {
    font-size: 20px;
    color: #fff;
}

.games .game img {
    width: 160px;
    height: 90px;
}

.games .game a {
    font-size: 20px;
    font-family: "tablet_gothic_condensed";
}

.games .game p {
    font-size: 15px;
}

.games .game p:before {
    content: "";
    float: left;
    width: 10px;
    height: 10px;
    margin: 3px 3px 0 0;
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -300px -216px;
}

/* Výpis článku
-----------------------------------------------------------------------------*/

#article {
    padding-top: 0px;
    color: #16212d;
    font-size: 18px;
    line-height: 25px;
    text-align: left;
}

#article .inner_7 {
    margin-left: 80px;
    overflow: visible;
}

#article h1 {
    font-size: 50px;
    line-height: 60px;
    font-family: "tablet_gothic_condensed";
}

#article .topGallery {
    position: relative;
    left: -80px;
    margin-top: 20px;
    background: #16212d;
    width: 620px;
}

#article .topGallery img.topImg {
    width: 620px;
    height: 328px;
}

#article .topGallery .jcarousel {
    width: 540px;
    left: 40px;
    margin: 0;
    padding-bottom: 10px;
}

#article .topGallery .photoDesc {
    color: #ffffff;
    padding: 20px;
    font-size: 18px;
}

#article .topGallery .photoSource {
    color: #98a3af;
    margin-left: 5px;
}

#article .topGallery .photoCarousel {
    position: relative;
    margin: 20px 0;
}

#article .topGallery .photoCarousel.noControls {
    margin: 10px 0;
}

#article .topGallery .photoCarousel .jcarousel-next,
#article .topGallery .photoCarousel .jcarousel-prev {
    background: #16212d url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -50px -216px;
    background-clip: content-box;
    background-origin: content-box;
    top: 0px;
    border-left: 1px solid #98a3af;
}

#article .topGallery .photoCarousel .jcarousel-next:hover,
#article .topGallery .photoCarousel .jcarousel-prev:hover {
    border-left: 1px solid #3d4952;
    background-position: -50px -232px;
}

/* Article - img, decription, author, icon-NEW
-------------------------------------------*/
#article .imgInArt {
    display: block;
    /*background: #16212d;*/
    margin: 10px 0 10px 0;
    max-width: 100%;
}

#article .imgInArt.panoramaImg {
    width: 100%;
}

#article .imgInGallery {
    display: block;
}

#article .imgInArt .imgInArtDescription {
    font: bold 12px Arial;
    line-height: 15px;
    color: #e9eff4;
    padding: 10px 0 10px 10px;
    background: #16212d;
}
#article .imgInArt .imgAuthor {
    font: normal 12px Arial;
    line-height: 15px;
    color: #e9eff4;
    margin: 0px 0 0;
    background: #16212d;
    padding: 0 0 10px 10px;
}

#article .imgInArt img {
    max-width: 540px;
    /*width: auto;*/
}

/*#article.listed .imgInArt, article.notlisted .imgInArt {
	padding: 10px;
	background: #16212D;
	margin: 25px auto;
}*/

#article.listed .imgInArt img,
#article.notlisted .imgInArt img {
    max-width: 620px;
    width: 100%;
}
#article.listed .imgInArt,
#article.notlisted .imgInArt {
    padding: 10px;
    max-width: 620px;
    margin: 25px 0px;
}

#article.notlisted .imgInArt {
    padding: 10px;
    background: #16212d;
    margin: 25px 0px;
}

#article.listed .imgInArt .imgInArtDescription,
#article.notlisted .imgInArt .imgInArtDescription {
    font: bold 12px Arial;
    line-height: 15px;
    color: #e9eff4;
    background: #16212d;
    padding-left: 10px;
}

#article.listed .imgInArt .imgAuthor,
#article.notlisted .imgInArt .imgAuthor {
    padding-top: 0;
    font: bold 12px Arial;
    line-height: 15px;
    color: #e9eff4;
    background: #16212d;
    padding-left: 10px;
}
#article.listed #related .relatedArticles .icon {
    margin-left: 125px;
    margin-top: 80px;
}
/* Article - img, author, description, icon-OLD
-------------------------------------------
#article .imgInArt {
	padding: 10px;
	background: #16212d;
}

#article .imgInGallery {
	display: block;
}

#article .imgInArt .imgInArtDescription {
	font: bold 12px Arial;
	line-height: 15px;
	color: #e9eff4;
	padding: 10px 0;
}
#article .imgInArt .imgAuthor {
	font: normal 12px Arial;
	line-height: 15px;
	color: #e9eff4;
	margin: 0px 0 0;
}

#article .imgInArt img {
	max-width: 600px;
}

#article.listed .imgInArt, article.notlisted .imgInArt {
	padding: 10px;
	background: #16212D;
	margin: 25px auto;
}

#article.listed .imgInArt img, #article.notlisted .imgInArt img {
	max-width: 620px;
	width: 100%;
}
#article.listed .imgInArt, #article.notlisted .imgInArt {
	padding: 10px;
	background: #16212D;
	max-width: 620px;
	margin: 25px 0px;

}

#article.notlisted .imgInArt {
	padding: 10px;
	background: #16212D;
	margin: 25px 0px;
}

#article.listed .imgInArt .imgInArtDescription, #article.notlisted .imgInArt .imgInArtDescription {
	font: bold 12px Arial;
	line-height: 15px;
	color: #e9eff4;
	background: #16212d;
}

#article.listed .imgInArt .imgAuthor, #article.notlisted .imgInArt .imgAuthor {
	padding-top: 0;
	font: bold 12px Arial;
	line-height: 15px;
	color: #e9eff4;
	background: #16212d;
}
#article.listed #related .relatedArticles .icon {
	margin-left: 125px;
	margin-top: 80px;
}*/

/* article listed ala isport */

#article .headerWrapper {
    width: 100%;
}
#article.listed h1,
#article.notlisted h1 {
    margin-bottom: 20px;
}
#article.listed ul.main,
#article.notlisted ul.main {
    padding: 0;
}
#article.listed ul.main {
    border-top: none;
}
#article.listed ul.listed > li:first-child > .photoDesc,
#article.notlisted ul.notlisted > li:first-child > .photoDesc {
    text-align: right;
}
#article.notlisted h2 {
    display: inline-block;
    padding-left: 20px;
    font-size: 24px;
    line-height: 24px;
}
#article.notlisted .number {
    display: none;
}
#article.notlisted ul.main > li {
}
#article.notlisted ul.main > li:not(:first-child) {
    margin-left: 160px;
}
#article.notlisted ul.main > li:first-child {
    width: 640px;
}
#article.notlisted ul.main > li:first-child .leadsection,
#article.notlisted ul.main > li:first-child > p {
    margin-left: 160px;
}

#article.notlisted ul.main > li .number {
    display: none;
}

#article.notlisted ul.main > li .headerWrapper > h2 {
    padding-left: 0 !important;
}
#article.notlisted ul.main > li .leadsection {
    font-weight: bold;
}
#article.listed .social,
#article.notlisted .social {
    display: block;
    float: left;
}
#article.listed ul.listed,
#article.notlisted ul.notlisted {
    position: relative;
    width: auto;
    overflow: hidden;
    list-style: none;
    color: #333f48;
    font-size: 15px;
    line-height: 22px;
}

/* overflow:visible doplneno 18.2.2014, aby sdileni nebylo prekryto listovaci listou */
#article.listed ul.listed {
    overflow: visible;
}

#article.listed .number {
    display: none;
}
#article.listed h2 {
    display: inline-block;
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 20px;
}
#article.listed ul.listed > li {
    float: left;
    display: none;
    padding-bottom: 10px;
    /*border-bottom: 2px solid #e7e6e6;*/
}
#article.listed ul.listed li.active {
    display: block;
    padding: 10px;
}
#article.listed ul.listed li.active p {
    clear: both;
}
#article .bottom {
    padding: 10px;
}
#article.listed .bottom .socialShares {
    margin-top: 6px;
    width: auto;
    min-width: 320px;
}
#article.listed .articleDetails .socialShares,
#article.notlisted .articleDetails .socialShares {
    width: auto;
    min-width: 320px;
}
#article.listed .bottom .counter {
    display: block;
    float: left;
}
#article.listed .listingControlButtons {
    display: block;
    float: right;
    font-family: "tablet_gothic_condensed";
    font-size: 21px;
    color: #ff0000;
    z-index: 1000;
}
#article .listingControlButtons {
    display: none;
    position: relative;
}
#article .listingControlButtons button {
    border: 0;
    position: relative;
    background: transparent;
    outline: 0;
    padding: 0;
    margin: 0;
    display: block;
    float: left;
}
#article .listingControlButtons.smallControlButtons {
    position: absolute;
    z-index: 10;
    top: 0px;
    right: 0px;
}
#article .listingControlButtons.smallControlButtons .startListing.showNext {
    display: none !important;
}
#article .listingControlButtons.smallControlButtons .showStart.start {
    display: none !important;
}
#article .listingControlButtons.smallControlButtons .showNext {
    width: 17px;
    height: 17px;
    max-width: 17px;
    max-height: 17px;
    margin-left: 2px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/article-listed-sprite.png") -17px -34px no-repeat;
}
#article .listingControlButtons.smallControlButtons .showPrev,
#article .listingControlButtons.smallControlButtons .showPrev.showStart {
    width: 17px;
    height: 17px;
    max-width: 17px;
    max-height: 17px;
    margin-right: 2px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/article-listed-sprite.png") 0px -34px no-repeat;
}
#article .listingControlButtons.smallControlButtons .showStart.showPrev {
    display: none !important;
}

#article.listed .articleDetails,
#article.notlisted .articleDetails {
    position: relative;
    margin: 10px 0;
}

#article.listed .authors {
    font-weight: bold;
    line-height: 20px;
    text-align: left;
}
#article.listed .authors.auth-margin {
    text-align: left;
}
#article.listed .dateTime,
#article.notlisted .dateTime {
    width: auto;
    margin: 30px 0;
}
#article.listed ul > li:nth-child(1) img {
    display: block;
    margin: 0 auto;
}

#article.listed .listingControlButtons {
    display: none;
}
#article.listed .listingControlButtons .counter {
    line-height: 32px;
}
#article.listed .errorsFooter {
    background-color: #e9eff4;
}
#article.listed .relativeObject {
    padding: 0;
    margin-left: 0px;
    margin-right: 0;
}
#article.listed .relativeObject.videoInArticle {
    padding: 0 10px;
    margin-left: 0px;
    margin-right: 0;
}
#article.listed .socialShareArrow {
    left: 0;
}
#article.listed .relativeObject .imgInArtDescription,
#article.listed .relativeObject .imgAuthor,
#article.listed .relativeObject .videoHeader,
#article.notlisted .relativeObject .videoHeader {
    padding: 0 10px;
}
#article.listed li#related h2 {
    display: block;
    float: none;
}
#article.listed .articleListItem,
#article.notlisted .articleListItem {
    float: right;
}
/*paging*/
ul.listed,
ul.notlisted {
    display: block;
    float: none;
}

.paging {
    display: block;
    position: relative;
    float: none;
    font-weight: bold;
    font-family: Arial;
    line-height: 38px;
    padding: 1px;
    width: 620px;
    background-color: white;
    margin-bottom: 20px;
}
.paging .pagingHolder {
    /*position: relative;*/
    display: block;
    margin: 0 auto;
    float: left;
    padding-left: 0px;
    text-align: center;
    width: 400px;
}
.paging .pagingHolder.centered {
    /*    margin-left: 68px;*/
}
.paging .pagingHolder a {
    display: inline-block;
}
.paging a.next {
    height: 30px !important;
}
.paging button {
    display: block;
    width: 100px;
    max-height: 30px;
    background-color: #dc0032;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    line-height: 30px;
    color: #fff;
    outline: 0;
    border: none;
    margin: 0px;
    padding: 5px;
}
.paging button:hover {
    background-color: #64001e;
}
.paging button.next {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    float: right;
}
.paging button.next.disabled {
    opacity: 0.7;
    cursor: default;
}
.paging button.next.disabled:hover {
    background-color: #dc0032;
}
.paging button.prev {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    float: left;
    width: 14%;
}
.pagingPrevArrow {
    display: block;
    float: left;
    margin-left: 5px;
    background: transparent url("https://img2.cncenter.cz/images/isport/elements.png?v=67") -750px -1042px no-repeat;
    height: 11px;
    width: 7px;
}
.pagingNextArrow {
    display: block;
    float: right;
    margin-right: 5px;
    background: transparent url("https://img2.cncenter.cz/images/isport/elements.png?v=67") -761px -1042px no-repeat;
    height: 11px;
    width: 7px;
}
.pagingNextArrow,
.pagingPrevArrow {
    margin-top: 9px;
}
.paging a {
    display: inline-block;

    /* float: left;*/
    height: 100%;
    min-width: 29px;
    min-height: 30px;
    height: 100%;
    color: black;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    line-height: 30px;
    font-size: 14px;
    border: none;
    margin: 0 1px;
    padding: 5px 0px;
}
.paging a[data-id="intro"] {
    display: inline-block;
    padding-right: 4px;
    padding-left: 4px;
    text-transform: capitalize;
}
.paging a[data-id="related"] {
    display: inline-block;
    padding-right: 4px;
    padding-left: 4px;
    text-transform: capitalize;
}

.paging a[data-id="intro"].active,
.paging a[data-id="related"].active {
    display: inline-block;
    background-color: #b9c7cd;
}
.paging a:hover {
    text-decoration: none;
}
.paging a:not(.threeDots):not(.active):hover {
    background-color: #ff0000;
    color: white;
}
.paging a.active:not([data-id="intro"]):not([data-id="related"]) {
    display: inline-block;
    padding: 5px 0px;
    min-width: 29px;
    background-color: #b9c7cd;
}
.social {
    display: block;
    margin: 10px 0;
}
.social .authors {
    font-weight: normal;
}

/* Article gallery
-------------------------------------------*/
.galleryInArticle {
    padding: 10px;
    background: #16212d;
}

.galleryInArticle .header {
    padding: 0 40px 10px 40px;
}

.galleryInArticle .header a {
    font-size: 18px;
    line-height: 18px;
    font-weight: bold;
    font-family: "tablet_gothic_condensed";
    text-transform: uppercase;
}

.galleryInArticle .header .galleryCount {
    float: right;
    display: inline-block;
    line-height: 18px;
    font-size: 15px;
    color: #e9eff4;
}

.galleryInArticle .description {
    padding: 10px 40px;
    font-size: 12px;
    line-height: 15px;
    color: #e9eff4;
}

.galleryInArticle .imgAuthor {
    color: #e9eff4;
    font-size: 12px;
    line-height: 15px;
    margin: 0 40px;
}
.galleryInArticle .bigPhoto {
    position: relative;
}

.galleryInArticle .bigPhoto .photoIn {
    display: inline-block;
    position: relative;
    margin: 0 0 0 40px;
    width: 520px;
}

.galleryInArticle .galleryPrev {
    display: block;
    height: 10px;
    left: 5px;
    padding-bottom: 40px;
    padding-left: 10px;
    padding-top: 40px;
    position: absolute;
    text-indent: 9999px;
    width: 10px;
    top: 85px;
    border-left: 1px solid #3d4952;
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat scroll -50px -232px content-box #16212d;
    background-position: -50px -232px;
    transform: rotate(180deg);
}

.galleryInArticle .galleryNext {
    display: block;
    height: 10px;
    right: 5px;
    padding-bottom: 40px;
    padding-left: 10px;
    padding-top: 40px;
    position: absolute;
    text-indent: 9999px;
    top: 85px;
    width: 10px;
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat scroll -50px -216px content-box #16212d;
    border-left: 1px solid #98a3af;
}

.galleryInArticle .photoFrame {
    background-color: #16212d;
}

/* List - Article gallery
-------------------------------------------*/
.article-gallery {
    background: #16212d;
    width: 100%;
    margin-bottom: 25px;
    color: #323232;
    float: left;
}

.article-gallery a {
    color: #323232;
}
.article-gallery .list-gallery {
    width: 610px;
    margin-top: 0;
    padding: 20px 40px 10px;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
}
.article-gallery .list-gallery .dataimg-arrow-light-left {
    width: 10px;
    height: 10px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -50px -216px;
    display: block;
    text-indent: 9999px;
    padding-top: 40px;
    background-clip: content-box;
    background-origin: content-box;
    padding-bottom: 40px;
    position: absolute;
    left: 5px;
    top: 20px;
    padding-left: 10px;
    transform: rotate(180deg);
}
.dataimg-arrow-light-left {
    background: #16212d url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -50px -216px;
    background-clip: border-box;
    background-origin: padding-box;
    background-clip: content-box;
    background-origin: content-box;
    top: 0;
    border-left: 1px solid #98a3af;
}
.article-gallery .list-gallery .dataimg-arrow-light-right {
    width: 10px;
    height: 10px;
    background: #16212d url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -50px -216px;
    display: block;
    text-indent: -9999px;
    position: absolute;
    right: 5px;
    top: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 10px;
    background-clip: content-box;
    background-origin: content-box;
}
.dataimg-arrow-light-right {
    background: #16212d url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -50px -216px;
    background-clip: border-box;
    background-origin: padding-box;
    background-clip: content-box;
    background-origin: content-box;
    top: 0;
    border-left: 1px solid #98a3af;
}
.article-gallery .list-gallery .dataimg-arrow-light-left:hover,
.article-gallery .list-gallery .dataimg-arrow-light-right:hover {
    border-left: 1px solid #3d4952;
    background-position: -50px -232px;
}
.article-gallery .list-gallery .gallery-item {
    float: left;
    width: 156px;
    margin: 0 10px;
}
.article-gallery .list-gallery .gallery-item img {
    width: 100%;
}

/* Article - video
-------------------------------------------*/
#article .videoInArticle {
    background: #16212d;
    /*color: #e9eff4;*/
    padding: 10px;
    /* float:left;*/
}

#article .videoInArticle .videoHeader {
    padding: 0px 0px 10px;
    color: #e9eff4;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    font-family: "tablet_gothic_condensed";
}

#article .videoInArticle .videoHeader .videoTime {
    font: normal 14px Arial;
    color: #e9eff4;
    float: right;
}

#article .article-body .videoInArticle p {
    font: bold 12px Arial;
    color: #e9eff4;
    margin: 10px 0;
}

#article .article-body .videoInArticle p span.videoAuthor {
    font: normal 12px Arial;
    color: #c0ced5;
}
#article .videoInArticle .videoThumb {
    display: block;
    position: relative;
}
#article .videoInArticle .videoThumb img {
    width: 100%;
}

#article .videoInArticle .videoThumb span.videoPlay {
    display: block;
    width: 280px;
    height: 66px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    background: transparent url("https://img2.cncenter.cz/images/isport/elements.png?v=68") -470px -563px no-repeat;
}

#article .videoInArticle .videoThumb:hover span.videoPlay {
    background: transparent url("https://img2.cncenter.cz/images/isport/elements.png?v=68") -470px -634px no-repeat;
}

/*#article .videoInArticle img,
#article .articleVideoTop img {
	width: 100%;
}
*/
#breadcrumbs {
    color: #5c6773;
    font-size: 12px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat 30px -1000px;
    height: 29px;
    line-height: 29px;
    border-bottom: 1px solid #cad5df;
}

#breadcrumbs a {
    color: #98a3af;
    font-size: 12px;
    line-height: 29px;
}

#breadcrumbs span {
    display: inline-block;
    color: #98a3af;
    margin: 0 5px;
    width: 11px;
    height: 11px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -975px -405px;
    text-indent: -99px;
    overflow: hidden;
    position: relative;
    top: 1px;
}

.centerLine p {
    margin: 5px 0px 25px 0px;
}

.centerLine h1 {
    font-size: 65px;
    line-height: 65px;
    font-family: "tablet_gothic_condensed";
    margin: 20px 0px 20px;
}

.centerLine h2 {
    font-size: 40px;
    line-height: 45px;
    margin: 20px 0px 5px;
    font-family: "tablet_gothic_condensed";
}

.centerLine h3 {
    font-size: 30px;
    line-height: 35px;
    margin: 10px 0px;
    font-family: "tablet_gothic_condensed";
}

.centerLine h4 {
    font-size: 20px;
    line-height: 25px;
    margin: 10px 0px 5px;
    font-family: "tablet_gothic_condensed";
}

#article #ads-widesquare {
    margin: 30px 0;
}

#article .metaArticle {
    position: relative;
    width: 540px;
    height: 80px;
    margin: 22px 0 0 0;
}

#article .leadsection p {
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
    margin: 25px 0 30px;
}

#article .article-body {
    font-size: 17px;
    line-height: 26px;
}

#article .article-body p {
    font-size: 17px;
    line-height: 26px;
    margin: 0 0 30px;
}

#article .article-body sup {
    vertical-align: super;
    font-size: smaller;
}

#article .article-body sub {
    vertical-align: sub;
    font-size: smaller;
}

#article .article-body h2 {
    font-size: 30px;
    line-height: 1.1em;
    font-family: "tablet_gothic_condensed";
    margin: 30px 0px 20px;
}

#article .article-body h3 {
    font-size: 24px;
    line-height: 1.2em;
    font-family: "tablet_gothic_condensed";
    font-weight: 700;
    margin: 30px 0;
}

#article .article-body h3 {
    text-decoration: none;
}

#article .article-body h3 a {
    font-size: 20px;
    text-decoration: underline;
}

#article .article-body h3 *:hover {
    text-decoration: none;
}

#article .article-body .embed-mall-tv {
    float: left;
}

#article .article-body .linkArticle h2 {
    padding: 6px 20px 4px 20px;
    margin: 0;
    font-size: 30px;
    line-height: 29px;
}

#article .article-body .linkArticle h2 a {
    padding-top: 15px;
    line-height: 29px;
    overflow: hidden;
    max-height: 145px;
    display: inline-block;
    text-decoration: none;
}

#article .article-body .linkArticle h2 a:hover {
    text-decoration: underline;
}

#article .authors {
    float: left;
    width: 50%;
    color: #5c6773;
    font-size: 15px;
    font-weight: bold;
    line-height: 18px;
}

#article .authors .author {
    padding-right: 5px;
}

#article .authors.underArticle {
    font-size: 18px;
    color: #16212d;
    margin: 25px 0px 0;
}

#article .dateTime {
    float: right;
    width: 190px;
    color: #98a3af;
    font-size: 15px;
    font-weight: bold;
    line-height: 20px;
    text-align: right;
    margin: 30px 0;
}

#article .commercial-article {
    color: #98a3af;
    font-size: 15px;
}

/* Tagy v clanku
-----------------------------------------------------------------------------*/
.tagsFooter {
    text-align: left;
    margin: 20px 10px 20px 10px;
    color: #16212d;
    border-top: 1px solid #cad5df;
    border-bottom: 1px solid #cad5df;
    position: relative;
    width: 640px;
}

.tagsFooter h4 {
    padding: 0;
    color: #98a3af;
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    font-family: "tablet_gothic_condensed";
    margin: 10px 0 10px 0;
    display: inline-block;
    text-transform: uppercase;
    float: left;
    width: 70px;
}

.tagsFooter p {
    display: inline-block;
    /*position: relative;*/
    top: 5px;
    float: left;
    width: 560px;
}

.tagsFooter a {
    color: #5c6773;
    display: inline-block;
    font-size: 15px;
    margin: 0 0 0 10px;
    padding: 0;
    font-family: Arial;
    font-weight: bold;
}
.tagsFooter a:hover {
    color: #ff0000;
    text-decoration: none;
}

/* Objekty v clanku - souvisejici clanky
-----------------------------------------------------------------------------*/
.relatedArticles {
    background: #fff;
    width: 300px;
    padding: 10px 10px 30px;
    float: left;
}

#article .relatedArticles .header h4,
.zpravyLive .relatedArticles .header h4,
#article .mixedArticles .header h4 {
    font-family: "tablet_gothic_condensed";
    color: #ff0000;
    text-transform: uppercase;
    font-size: 25px;
    line-height: 25px;
    margin: 10px 0px;
    border-bottom: 1px solid #cad5df;
    padding-bottom: 10px;
}

#article .mixedArticles .header h4,
.zpravyLive .mixedArticles .header h4 {
    border-bottom: 1px solid #a7b3c3;
}

#article .relatedArticles .article,
.zpravyLive .relatedArticles .article {
    border-bottom: 1px solid #cad5df;
    padding: 10px 0px;
}

#article .relatedArticles .article img,
.zpravyLive .relatedArticles .article img {
    float: left;
    width: 120px;
    height: 68px;
    margin-right: 10px;
}

#article .relatedArticles .article a.title,
.zpravyLive .relatedArticles .article a.title {
    color: #16212d;
    font-size: 15px;
    line-height: 16px;
    font-weight: bold;
    float: left;
    width: 160px;
    height: 69px;

    overflow: visible;
}

.mixedArticles {
    float: left;
    width: 300px;
    padding: 10px;
    background: #dee6ee;
}

.mixedArticles div.article {
    height: 44px;

    overflow: visible;
    border-bottom: 1px solid #a7b3c3;
    color: #a7b3c3;
    font-size: 14px;
    line-height: 16px;
}

.mixedArticles div.article a {
    display: block;
    height: 37px;
    margin-top: 5px;
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    color: #121824;
    padding-right: 20px;
    position: relative;
    text-decoration: none;
}

.mixedArticles div.article a:after {
    content: "";
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-color: transparent transparent transparent #ff0000;
    position: absolute;
    bottom: 15px;
    right: 0px;
}

.mixedArticles div.article a:hover {
    color: #ff0000;
    text-decoration: none;
}

.mixedArticles div.article a:hover span.title {
    text-decoration: underline;
}

.mixedArticles div.article a span.from {
    color: #a7b3c3;
    font-weight: normal;
    text-decoration: none !important;
}

.relativeObject:not(.listedRelatedReal) {
    margin: 25px 0px 25px -80px;
}

.relativeObject.objectSoutez {
    margin: 25px 0px 25px 0px;
    float: right;
}

.relativeObject.objectSoutez .imageWrapper {
    width: 480px;
    margin: 0 0 0 130px;
}

.relatedWrapper {
    width: 640px;
    background: #dee6ee;
    float: left;
    margin-right: 25px;
    margin-top: 0px;
    font-family: Arial, sans-serif;
}

#article .relatedWrapper a {
    text-decoration: none;
}

#article .relatedWrapper a:hover {
    text-decoration: underline;
}

.zpravyLive .relatedWrapper {
    margin-bottom: 30px;
}

#article .linkArticle {
    width: 620px;
}

#article .linkArticle .wrapper {
    margin-top: 20px;
}

/* Detail článku */
.article .headerMenu {
    margin-bottom: 0px;
}
.article .topPanel {
    border-top: 1px solid #cad5df;
    border-bottom: none;
    padding-bottom: 0;
}

.article .topPanel {
    /*width: 625px;*/
    width: 670px;
}

.article .topPanel .profileActions,
.topPanel .calendar,
.topPanel .nameDay {
    line-height: 29px;
    color: #343f4b;
}

.article .headerMenu .nameDay {
    font-size: 12px;
    display: inline-block;
    float: left;
    line-height: 15px;
    margin: 2px 0px;
    border-right: 1px solid #cad5df;
    padding: 6px 10px 5px 10px;
    max-width: 195px;
}

.article .headerMenu .calendar {
    font-size: 12px;
    color: #343f4b;
    font-weight: bold;
    display: inline-block;
    float: left;
    line-height: 15px;
    border-left: 0px solid #cad5df;
    padding: 7px 10px 0px 0px;
    margin: 0px 0;
}

.article .topPanel .linkMagazines a {
    margin: 4px 0px 4px 5px;
    padding: 5px 7px 5px 7px;
    font-weight: bold;
    border-left: 0px solid #cad5df;
}

.article .topPanel .linkMagazines {
    float: left;
    display: block;
}

.article .searchBox .searchField {
    border-color: #fff;
}

.article .logoBlesk a {
    display: block;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -450px -772px;
    width: 150px;
    height: 40px;
}

.article #menu {
    margin-left: 150px;
    border-bottom: 1px solid #cad5df;
}

.article #menu ul.topLevelMenu li {
    width: 135px;
    height: 40px;
}

.article #menu #menuSectionContainer {
    top: 40px;
}

.article #menu ul.topLevelMenu li.celebrity {
    width: 170px;
}

.article #menu ul.topLevelMenu li.zpravy {
    width: 140px;
}

.article #menu ul.topLevelMenu li.sport {
    width: 140px;
}

.article #menu ul.topLevelMenu li.dalsi {
    width: 140px;
}

.article #menu #menuSectionContainer {
    width: 480px !important;
}

.article #menu .celebrity #menuSectionContainer {
    width: 490px !important;
}

.article #menu .dalsi #menuSectionContainer {
    width: 160px !important;
}

.article #menu .zpravy #menuSectionContainer .recentArticles {
    float: right !important;
}

.article #menu #menuSectionContainer .menuInMenu {
    width: 160px;
}

.article #menu .celebrity #menuSectionContainer .menuInMenu {
    width: 170px;
}

.article #menu ul.topLevelMenu li.program {
    width: 54px;
    height: 40px;
    padding: 0;
}
.article #menu ul.topLevelMenu li.horoskopy {
    position: relative;
    margin-top: 5px;
    margin-left: 10px;
    width: 126px;
    height: 35px;
    padding: 0;
}

.article #menu ul.topLevelMenu li.horoskopy a.category {
    display: block;
    width: 89px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 27px;
    padding-left: 10px;
    font-family: "tablet_gothic_condensed";
    font-size: 16px;
    line-height: 35px;
    text-align: left;
    text-transform: uppercase;
    color: white;
    background: #7323eb;
}
.article #menu ul.topLevelMenu li.horoskopy a.category:hover {
    text-decoration: underline;
}

.article #menu ul.topLevelMenu li.horoskopy a.category .weird-ico {
    position: absolute;
    width: 37px;
    height: 49px;
    z-index: 1;
    left: -10px;
    top: -5px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -700px 0;
}
.article #menu ul.topLevelMenu li.horoskopy a.category .arrow {
    width: 7px;
    height: 9px;
    position: absolute;
    right: 5px;
    top: 13px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -680px 0;
}

.article #menu ul.topLevelMenu li.program a.category {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -700px -402px;
    width: 36px;
    height: 25px;
    padding: 9px 14px 6px 9px;
    margin: 0;
    background-clip: content-box;
    background-origin: content-box;
    border-right: none;
    top: 0;
}

.article #menu ul.topLevelMenu li.dalsi a.category:after {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -50px -200px;
    background-origin: content-box;
    background-clip: content-box;
}

.article #menu ul.topLevelMenu li.celebrity a.category:after,
.article #menu ul.topLevelMenu li.sport a.category:after,
.article #menu ul.topLevelMenu li.zpravy a.category:after,
.article #menu ul.topLevelMenu li.dalsi a.category:after {
    width: 10px;
    content: "";
    height: 10px;
    float: right;
    padding-top: 7px;
    padding-right: 0px;
    position: relative;
    left: -12px;
}

.article #menu ul.topLevelMenu li a.category {
    color: #16212d;
    font-weight: bold;
    font-family: "tablet_gothic_condensed", Arial, sans-serif;
    font-size: 30px;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    display: block;
    line-height: 40px;
    padding-top: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 20px;
    border-right: 1px solid #cad5df;
}

.article #menu ul.topLevelMenu li.celebrity.hover a.category {
    color: #ff0000;
}

.article #menu ul.topLevelMenu li.zpravy.hover a.category {
    color: #6e78dc;
}

.article #menu ul.topLevelMenu li.sport.hover a.category {
    color: #91c837;
}

.article #menu ul.topLevelMenu li.dalsi.hover a.category {
    color: #9ca7ac;
}

.article #menu ul.topLevelMenu li a.category {
    width: 139px;
}

.article #menu ul.topLevelMenu li.celebrity a.category {
    width: 169px;
}

.article #menu ul.topLevelMenu li.program:hover {
    background: #fff;
}

/*.article #menu ul.topLevelMenu li.program a.category {
	width: 140px;
}*/

.article #menu ul.topLevelMenu li.hover a {
    border-right: none;
}

.article .facebookSelection {
    height: 140px;
    padding: 20px 10px;
    width: 920px;
}

.article .facebookSelection .bestOf {
    width: 150px;
    height: 115px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -250px -700px no-repeat;
    background-origin: content-box;
    background-clip: content-box;
}

/* Sdileni na socialnich sitich
-----------------------------------------------------------------------------*/
.socialShareArrow {
    display: block;
    position: absolute;
    left: -80px;
    top: -11px;
    width: 80px;
    height: 61px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -400px -1300px no-repeat;
    z-index: 2;
}

.socialShares {
    margin: 30px 0;
    line-height: 20px;
    float: left;
    position: relative;
    top: 0px;
    left: 0px;
    width: 350px;
    max-height: 20px;
}

/* Modni policie v clanku
-----------------------------------------------------------------------------*/

.policeCarousel {
    background: #16212d;
    color: #cad5df;
    margin: 10px 0;
    padding: 30px 10px 10px;
    position: relative;
    left: -80px;
    width: 600px;
}

#article .policeCarousel ul {
    margin: 0 0 20px 0px;
}

.policeCarousel .fpMain {
    /*padding: 0 0 20px;
	border-bottom: 1px solid #242f3b;*/
}

.policeCarousel .fpMainLeft {
    float: left;
    width: 299px;
    height: 450px;
    margin-right: 20px;
}

.policeCarousel .fpMainRight {
    float: right;
    width: 280px;
    min-height: 570px;
}

.policeCarousel .fpMainRightName {
    font-size: 36px;
    font-family: "tablet_gothic_condensed";
    line-height: 42px;
}

.policeCarousel .fpMainRightVerdict {
    font-size: 15px;
    font-weight: normal;
    float: left;
    line-height: 20px;
}

.policeCarousel .fpMainRightVerdict p:before {
    content: "";
    float: left;
    width: 10px;
    height: 10px;
    margin: 5px 3px 0 0;
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -450px -216px no-repeat !important;
}

.policeCarousel .fpMainRightRatingSign {
    font-size: 160px;
    line-height: 160px;
    color: #00c8be;
    font-family: "tablet_gothic_condensed";
    width: 140px;
    display: inline-block;
    text-align: center;
    float: left;
}

.policeCarousel .fpEnqQuestion {
    color: #848f9b;
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
    margin-bottom: 10px;
}

.policeCarousel .fpEnqVotingWrapper {
    width: 75px;
    height: 100px;
    padding: 10px 30px;
    margin: 20px 0;
    border-left: 1px solid #242f3b;
    float: left;
}

.policeCarousel .fpCarousel {
    padding-top: 0px;
    padding-bottom: 0px;
    width: 240px;
    margin-top: 10px;
    position: absolute;
    bottom: auto;
    top: 490px;
    left: 38px;
}

.policeCarousel .fpCarousel li {
    float: left;
    margin: 0;
    cursor: pointer;
}

.policeCarousel .fpCarousel li img {
    width: 50px;
    height: 75px;
    margin: 0 10px;
}

.policeCarousel .votesFpFirstChoice {
    font-size: 12px;
    padding-top: 0px;
    margin-top: 0px;
    float: left;
    width: 30px;
    text-align: left;
}

.policeCarousel .votesFpSecondChoice {
    font-size: 12px;
    padding-top: 0px;
    float: right;
    width: 30px;
    text-align: center;
}

.policeCarousel .fpEnqFirstChoiceWrapper a {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -850px -350px;
    width: 20px;
    display: inline-block;
    float: left;
    height: 20px;
}

.policeCarousel .fpEnqSecondChoiceWrapper a {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -800px -350px;
    width: 20px;
    display: inline-block;
    float: right;
    height: 20px;
}

.policeCarousel .fpEnqWrapper {
    clear: both;
}

.policeCarousel a.jcarousel-prev {
    background: #16212d url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -50px -216px;
    background-clip: content-box;
    background-origin: content-box;
    bottom: auto;
    top: 500px;
    border-left: 1px solid #98a3af;
    padding-top: 32px;
    padding-bottom: 32px;
}

.policeCarousel a.jcarousel-next {
    background: #16212d url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -50px -216px;
    background-clip: content-box;
    background-origin: content-box;
    top: 500px;
    bottom: auto;
    border-left: 1px solid #98a3af;
    left: 289px;
    padding-top: 32px;
    padding-bottom: 32px;
}

.policeCarousel .jcarousel ul li img {
    margin: 0px 5px;
}

.policeCarousel .fpMainLeftImg img {
    width: 299px;
    height: 450px;
    overflow: hidden;
}

.policeCarousel #fpPreviousItem {
    width: 35px;
    height: 450px;
    top: 30px;
    background: rgba(30, 30, 30, 0.6);
    display: block;
    position: absolute;
    z-index: 10;
    cursor: pointer;
}

.policeCarousel #fpPreviousItem .icon-prev {
    background: transparent url(https://img2.cncenter.cz/images/blesk/arrow-light-left.png?v=1) no-repeat;
    width: 11px;
    height: 20px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 4%;
    right: 4%;
}
.policeCarousel #fpNextItem {
    width: 35px;
    height: 450px;
    top: 30px;
    left: 274px;
    background: rgba(30, 30, 30, 0.6);
    display: block;
    position: absolute;
    z-index: 10;
    cursor: pointer;
}

.policeCarousel #fpNextItem .icon-next {
    background: transparent url(https://img2.cncenter.cz/images/blesk/arrow-light-right.png?v=1) no-repeat;
    width: 11px;
    height: 20px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 12px;
}

#article ul.fashionPoliceList {
    list-style: none !important;
}

/* Socialni boxy
-----------------------------------------------------------------------------*/
.socialButtons.underArticle {
    margin: 10px 0 30px;
    padding: 10px 0 10px 65px;
    min-height: 60px;
}

.socialButtons.underArticle .divider {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat 0px -1200px;
    width: 620px;
    height: 10px;
    margin-left: -60px;
    margin-bottom: 30px;
}

.socialButtons.underArticle .fb-like {
    top: 1px;
    margin-right: 20px;
}

.socialButtons.underArticle .twitter-share-button {
    margin-right: 20px;
    position: relative;
    top: -32px;
}

.socialButtons.underPhoto .fb-like {
    display: inline-block;
}

.socialButtons.underPhoto .twitter-share-button {
    margin-left: 15px;
    visibility: visible !important;
    width: 78px !important;
}
.socialButtons.underPhoto iframe.twitter-widget-2 {
    position: static;
    visibility: visible;
    width: 78px;
    height: 20px;
    margin-left: 0px !important;
}
.socialButtons.underPhoto iframe.twitter-widget-2 .widget {
    width: 190px !important;
    position: relative !important;
    float: left !important;
}
.socialButtons.underPhoto iframe.twitter-widget-2 .widget .btn-o {
    width: 54px !important;
    float: left !important;
    position: relative !important;
}
.socialButtons #___plus_0 {
    width: 83px !important;
}
.socialButtons #___plus_0 iframe {
    width: 83px !important;
}

.socialShares.inlineVertical {
    min-height: 70px;
}

/* Article - social buttons
-------------------------------------------*/
.socialMedia {
    float: left;
    width: 100%;
}

.socialMedia .googleAndFBLike {
    margin-top: 40px;
}

.socialMedia .title {
    border-radius: 4px 4px 0 0;
    background-color: #f5f5f5;
    border-bottom: 1px solid #d8e1e5;
    color: #5b6770;
    font-family: Arial, sans-serif;
    font-size: 11px !important;
    text-transform: uppercase;
    font-weight: bold;
    padding: 2px 10px 0;
    text-align: left;
    margin-top: 0;
    line-height: 27px;
}

.socialMedia .fb-like {
    font-size: 0px;
}
.scoailMedia .fb-comments {
    min-height: 210px;
}

.socialMedia .others div {
    float: left !important;
    margin: 0 20px 0 0 !important;
}

.socialMedia .inner_5.fb {
    padding-right: 0;
}

.socialMedia .twitter {
    width: 58px;
    height: 61px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -100px -1300px;
    position: relative;
}

.socialMedia .twitter .twitterCount {
    background-repeat: no-repeat;
    display: block;
    font:
        bold 14px/14px Arial,
        "Helvetica Neue",
        Helvetica,
        sans-serif;
    height: 52px;
    padding: 9px 0 0 1px;
    text-align: center;
    width: 58px;
    cursor: default;
    color: #666666;
    position: absolute;
    height: 30px;
}

.socialMedia .twitter iframe {
    position: absolute !important;
    top: 39px;
    opacity: 0;
}

.socialMedia .facebook {
    width: 54px;
    height: 61px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat 0px -1300px;
    position: relative;
}

.socialMedia .facebook .facebookCount {
    background-repeat: no-repeat;
    display: block;
    font: bold 14px/14px Arial;
    height: 52px;
    padding: 9px 0 0 1px;
    text-align: center;
    width: 54px;
    cursor: default;
    color: #666666;
}

#gPlusOneBtn {
    float: left;
}

.fblikefix {
    float: left;
    margin-left: 20px;
}

.socialMedia .facebook .sharer {
    position: absolute;
    top: 39px;
    left: 0;
    width: 56px;
    height: 29px;
    opacity: 0;
}
#article .socialTopShare {
    position: absolute;
    margin-top: 16px;
    overflow: hidden;
    margin-left: 426px;
    height: 22px;
}
#article .socialTopShare .fb-like {
    font-size: 0px;
    margin-right: 10px;
}

/* Forum pod clankem
-----------------------------------------------------------------------------*/
.headerComments {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 20px;
    border-top: 3px solid #404a55;
    color: #16212d;
    font-family: "tablet_gothic_condensed";
}

.headerComments a {
    color: #16212d;
}

.forumArticle .commentsBox .commentsBubble {
    background: #fff;
    padding: 20px;
    color: #16212d;
    font-size: 15px;
    margin-bottom: 10px;
}

.forumArticle .commentsBox .commentsBubble p {
    line-height: 20px;
    margin: 10px 0;
}

.forumArticle .commentsBox .commentsBubble.noComments {
    text-align: center;
}

/* Forum pod clankem
-----------------------------------------------------------------------------*/
.headerComments {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 20px;
    border-top: 3px solid #404a55;
    color: #16212d;
    font-family: "tablet_gothic_condensed";
}

.headerComments a {
    color: #16212d;
}

.forumArticle .commentsBox .commentsBubble {
    background: #fff;
    padding: 20px;
    color: #16212d;
    font-size: 15px;
    margin-bottom: 10px;
}

.forumArticle .commentsBox .commentsBubble p {
    line-height: 20px;
    margin: 10px 0;
}

.forumArticle .commentsBox .commentsBubble.noComments {
    text-align: center;
}

/* Forum - detail
-----------------------------------------------------------------------------*/
.forumBoxReturn .articleContainer.middleArticle .wrapper {
    margin: 15px 0 0 0;
}

/* Chat - online rozhovor
-----------------------------------------------------------------------------*/
.inner_7 .article-body .chatArticle {
    width: 620px;
    margin: 0 0 0 -80px;
}

.inner_7 .article-body .chatArticle .dates {
    display: inline;
}

#OTmsgs .dalsiDotaz {
    text-align: center;
    position: relative;
    border: 1px solid #cad5df;
    padding: 10px 0 10px 0;
    margin: 0 0 10px 0;
    background: #ffffff;
}

#frmOT {
    position: relative;
    border-top: 1px solid #cad5df;
    padding: 20px 0 20px 0;
}

#frmOT textarea {
    background: #ffffff;
    border: 1px solid #cad5df;
    padding: 5px 10px;
    width: 598px;
    height: 88px;
    min-width: 598px;
    min-height: 88px;
    max-width: 598px;
    max-height: 88px;
    font-family: Arial;
    font-size: 15px;
    font-weight: normal;
    color: #98a3af;
}

#frmOT textarea {
    background: #ffffff;
    border: 1px solid #cad5df;
    padding: 5px 10px;
    width: 598px;
    height: 88px;
    min-width: 598px;
    min-height: 88px;
    max-width: 598px;
    max-height: 88px;
    font-family: Arial;
    font-size: 15px;
    font-weight: normal;
    color: #98a3af;
}

#frmOT .normalInp {
    margin: 10px 0 0 0;
    padding: 0 10px;
    font-family: Arial;
    font-size: 15px;
    font-weight: normal;
}

#frmOT #OTbtnSubmit {
    margin: 10px 0 0 0;
}

.chatRecords {
    padding: 20px 0 0 0;
    position: relative;
}

.chatRecords .shadowUp {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat 0px -1250px;
}

.chatRecords .record {
    margin: 10px 0 0 0;
    padding: 15px 20px;
    background: #ffffff none;
    font-family: Arial;
    font-size: 15px;
    font-weight: normal;
    line-height: 20px;
}
.chatRecords .record.w540 {
    max-width: 540px;
}
.chatRecords .record .question {
    font-weight: bold;
}

.chatRecords .record .answer {
    display: block;
    position: relative;
    min-height: 50px;
    margin: 10px 0 0 0;
}

.chatRecords .record .answer .obrazek,
.chatRecords .record .answer .obrazek img {
    display: block;
    width: 50px;
    height: 50px;
    float: left;
}

.chatRecords .record .answer .text {
    display: block;
    padding: 0 0 0 60px;
    position: relative;
}

.onlineTalkDiv .paging {
    margin: 20px 0;
    text-align: center;
    color: #707b87;
    font-family: "tablet_gothic_condensed";
    font-size: 16px;
    line-height: 16px;
}

.onlineTalkDiv .paging a:hover {
    color: #ff0000;
}

.onlineTalkDiv .paging a {
    color: #707b87;
    font-family: "tablet_gothic_condensed";
    font-size: 16px;
    padding: 0 5px;
    text-decoration: none;
    margin-top: 0px;
}
.onlineTalkDiv .paging a.aktpage {
    color: #16212d;
}

.onlineTalkDiv .paging .previousPage {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -850px -400px no-repeat;
    display: inline-block;
    width: 10px;
    height: 20px;
    padding: 0;
    margin-right: 10px;
    margin-top: 0px;
    position: relative;
    top: 3px;
}

.onlineTalkDiv .paging .previousPage:hover {
    background-position: -850px -450px;
}

.onlineTalkDiv .paging .nextPage {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -900px -400px no-repeat;
    display: inline-block;
    width: 10px;
    height: 20px;
    padding: 0;
    margin-left: 10px;
    position: relative;
    top: 3px;
}

.onlineTalkDiv .paging .nextPage:hover {
    background-position: -900px -450px;
}

.online_report .date {
    background: transparent;
    display: block;
    float: left;
    font-family: Arial;
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    width: 62px;
    margin: 0 0px 0 0px;
    color: #99a3af;
    margin-bottom: 2px;
    line-height: 15px;
    text-align: left;
}

.chatArticle .headerComments {
    padding-top: 5px;
}

.chatArticle .filters {
    line-height: 34px;
    border-top: 1px solid #d7e0e8;
    border-bottom: 1px solid #d7e0e8;
    margin-bottom: 20px;
}

.chatArticle .calendar a,
.chatArticle .calendar .title,
.chatArticle .filters a,
.chatArticle .filters .title {
    font-family: "tablet_gothic_condensed";
    font-size: 16px;
    color: #98a3af;
    text-transform: uppercase;
    margin: 0px 0 0px 30px;
    display: inline-block;
    padding-bottom: 10px;
    margin-bottom: -10px;
}

.chatArticle .filters .title,
.chatArticle .calendar .title {
    margin-left: 0;
}

.chatArticle .filters a.active {
    color: #16212d;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/selected-tab.png") no-repeat bottom center;
}

.chatArticle .filters a.refresh {
    width: 94px;
    height: 30px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat 0px -1440px;
    font-family: "tablet_gothic_condensed";
    font-size: 16px;
    color: #98a3af;
    text-transform: uppercase;
    margin: 3px 0 0 0;
    display: inline-block;
    float: right;
    text-indent: -9000px;
}

.chatArticle .filters .groups {
    float: right;
}

.chatArticle .calendar {
    font-size: 15px;
    color: #98a3af;
}

.chatArticle .calendar a {
    color: #98a3af;
    font-size: 15px;
    text-decoration: none;
}

.chatArticle .calendar a:hover {
    color: #16212d;
}

.chatArticle .filters .groups select {
    padding: 4px 4px 5px 4px;
    margin: 0;
    background: #fff;
    color: #acb6c0;
    border: none;
    outline: none;
    display: inline-block;
    appearance: none;
    cursor: pointer;
    text-transform: uppercase;
    font-family: "tablet_gothic_condensed";
    font-size: 16px;
    border-right: 0px solid #d7e0e8;
    padding-right: 40px;
}

/*.chatArticle .filters .groups*/

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    select {
        padding-right: 18px;
    }
}

.chatArticle .filters .groups label {
    position: relative;
}
.chatArticle .filters .groups label:after {
    content: "\25BA";
    font:
        30px "Consolas",
        monospace;
    color: #aaa;
    transform: rotate(90deg);
    right: 8px;
    top: -10px;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
}
.chatArticle .filters .groups label:before {
    content: "";
    right: 1px;
    top: -6px;
    width: 30px;
    height: 32px;
    background: #fff;
    position: absolute;
    pointer-events: none;
    display: block;
    border-left: 1px solid #eee;
}

.chatArticle .online_report .important {
    width: 23px;
    height: 23px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -1150px 0px;
    float: left;
    margin: 5px 0 8px;
    overflow: hidden;
    text-indent: 9999px;
}

.chatArticle .online_report .details {
    width: 80px;
    float: left;
}

.chatArticle .online_report .fb_iframe_widget {
    margin: 30px 0px 0px -70px;
}

/* Online report
-----------------------------------------------------------------------------*/
.inner_7 .article-body .chatArticleScroll {
    height: 328px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.chatArticlLinkFull.goToLink {
    display: block;
    margin: 10px 0;
}

.online_report {
    position: relative;
    padding: 30px 0 0 0;
}

.online_report .shadowUp {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat scroll 0 -1250px transparent;
}

.online_report .report_item {
    border-bottom: 1px solid #cad5df;
    padding: 10px 0 15px 0;
}

.online_report .left-details {
    float: left;
    overflow: hidden;
}

.online_report .obrazekKomentatora {
    display: block;
    float: left;
    width: 60px;
    min-width: 60px;
    clear: both;
}

.online_report .obrazekKomentatora img {
    margin: 0;
    width: 60px;
    height: 60px;
}

.online_report .time {
    background: transparent;
    display: block;
    float: left;
    font-family: Arial;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    width: 57px;
    margin: 0 0px 0 0px;
    color: #ff0000;
    margin-bottom: 2px;
    line-height: 15px;
    text-align: left;
}

.online_report .automatic_text {
    background: transparent;
    display: block;
    float: right;
    font-family: Arial;
    font-size: 15px;
    font-weight: normal;
    width: 480px;
}

.online_report .text {
    background: transparent;
    display: block;
    float: right;
    font-family: Arial;
    font-size: 15px;
    font-weight: normal;
    width: 530px;
}

.online_report .text em {
    font-size: 16px;
    font-weight: bold;
    padding: 20px 0px 20px 30px;
    display: inline-block;
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-2013/quotes-bg.png?v=68") no-repeat;
    margin: 20px 0px;
}

#article .article-body .online_report .text p {
    font-size: 15px;
    line-height: 20px;
    margin: 0;
}

.online_report .media {
    background: transparent;
    display: block;
    float: right;
    width: 480px;
}

.online_report .media .reportImage {
    background: transparent;
    display: block;
    float: right;
    width: 480px;
    margin: 15px 0 0 0;
}

.online_report .media .reportImage img {
    width: 100%;
}

.online_report .media .reportVideo {
    background: transparent;
    display: block;
    float: right;
    width: 480px;
    margin: 15px 0 0 0;
}

/* Forum pod clankem
-----------------------------------------------------------------------------*/
.headerComments {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 20px;
    border-top: 3px solid #404a55;
    color: #16212d;
    font-family: "tablet_gothic_condensed";
}

.headerComments a {
    color: #16212d;
}

.headerComments a.colorBtn {
    color: #fff;
    margin: 15px 0;
}

.forumArticle .commentsBox .commentsBubble {
    background: #fff;
    padding: 10px;
    color: #16212d;
    font-size: 15px;
    margin-bottom: 10px;
}

.forumArticle .commentsCount {
    line-height: 30px;
    color: #98a3af;
    font-size: 22px;
    font-family: "tablet_gothic_compressed";
}

.forumArticle .commentsCount.noComments {
    margin-left: 260px;
}

.forumArticle .commentsBox .commentsBubble p {
    line-height: 20px;
    margin: 0;
}

.forumArticle .commentsBox .commentsBubble.noComments {
    text-align: center;
}

.commentsBox .headerSimple {
    font-size: 50px;
    font-family: "tablet_gothic_condensed";
    line-height: 60px;
    margin: 10px 0;
}

.commentsBox .level0 {
    padding: 0px 0 0;
    background: #fff;
    margin-top: 10px;
}

.commentsBox .level1 {
    padding: 10px 0 0 40px;
    background: #fff;
}

.commentsBox .level2 {
    padding: 10px 0 0 80px;
    background: #fff;
}

.commentsBox .level3 {
    padding: 10px 0 0 120px;
    background: #fff;
}

.commentsBox .level4 {
    padding: 10px 0 0 160px;
    background: #fff;
}

.commentsBox .level5 {
    padding: 10px 0 0 200px;
    background: #fff;
}

.commentsBox .message {
    background: #fff;
    padding: 10px;
}

.commentsBox .metaMessage {
    color: #23aaff;
    float: left;
    margin-top: 10px;
    margin-left: 10px;
}

.commentsBox .metaMessage a {
    color: #23aaff;
    font-size: 12px;
    font-style: italic;
}

.commentsBox .metaMessage a.notify {
    margin-left: 10px;
}

.commentsBox .message .date {
    color: #98a3af;
    font-size: 12px;
    display: block;
    float: left;
    margin-top: 10px;
}

.commentsBox .message p {
    font-size: 15px;
    line-height: 18px;
    color: #16212d;
    word-wrap: break-word;
    overflow: hidden;
}

.commentsBox .message.deleted {
    text-decoration: line-through;
}

.commentsBox .message .author {
    font-size: 15px;
    color: #16212d;
    font-weight: bold;
    font-style: italic;
    margin-right: 10px;
}

.forumLoginBox .input input {
    background: #fff;
    border: 1px solid #ecf0f4;
    color: #343f4b;
    font-size: 16px;
    line-height: 16px;
    margin: 0px;
}

.button {
    font-size: 16px;
    padding: 7px 45px;
    margin: 10px 0;
    text-transform: uppercase;
    font-family: "tablet_gothic_condensed";
    float: right;
    position: relative;
    cursor: pointer;
    border: none;
    line-height: 16px;
    color: #fff;
    text-decoration: none;
}

.button:after {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 20%;
    right: 2px;
    left: auto;
    width: 50%;
    top: 8px;
    max-width: 100px;
    background: #777;
    -webkit-box-shadow: 0 2px 10px #777;
    -moz-box-shadow: 0 2px 10px #777;
    box-shadow: 0 2px 10px #777;
    -webkit-transform: rotate(7deg);
    -moz-transform: rotate(7deg);
    -o-transform: rotate(7deg);
    -ms-transform: rotate(7deg);
    transform: rotate(7deg);
}

.button.red {
    color: #fff;
    background: #ff0000;
}

.button.red:hover {
    background: #eb0000;
    text-decoration: none;
}

.button .nextArrow:after {
    content: "";
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-color: transparent transparent transparent #fff;
    position: absolute;
    top: 10px;
    right: 6px;
}

.forumLoginBox .submitButton {
    margin-top: 24px;
}

.loginCont {
    font-size: 15px;
    color: #707b87;
}

.loginCont a {
    color: #ff0000;
}

.forumLoginBox .input input {
    padding: 5px;
}

.loginCont .input {
    float: left;
    margin: 10px;
}

.loginCont a {
    color: #707b87;
    font-size: 15px;
    padding: 0 15px 0 15px;
    display: block;
    float: left;
}

.loggedBox {
    margin: 20px 0 0;
    border-top: 1px solid #cad5df;
    padding-top: 15px;
}

.loggedBox .formButtonProfile {
    border-left: 1px solid #707b87;
    border-right: 1px solid #707b87;
}

.insertForm textarea {
    width: 618px;
    height: 98px;
    resize: none;
    padding: 10px;
    color: #16212d;
    border: 1px solid #cad5df;
}

.insertForm .formInputs {
    background: #fff;
    border: 1px solid #cad5df;
    border-top: none;
    width: 640px;
    padding: 10px;
}

.insertForm .notify {
    font-size: 15px;
    color: #707b87;
    margin: 5px 0;
    float: left;
}

.loginCont a.formButtonLogout {
    float: right;
}

.loginCont .headerSimple {
    float: left;
    font-weight: bold;
    color: #16212d;
    padding: 0 20px 0 10px;
}

.insertForm .formInputs .remaining {
    float: left;
    margin: 8px 10px;
    color: #98a3af;
    font-size: 12px;
}

.insertForm .formInputs .smilies {
    margin: 5px;
}

.insertForm .formInputs .smilies a:hover {
    text-decoration: none;
}

.loginCont .headerSimple .userIco {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -900px -350px;
    width: 14px;
    height: 14px;
    float: left;
    margin-right: 10px;
}

.loginCont .headerSimple .userNick {
    float: left;
}

.insertForm .formCond {
    float: left;
    margin: 5px 10px;
}

.insertForm .formButtonCond {
    font-size: 15px;
    line-height: 21px;
    color: #23aaff;
}

#notifyCont {
    display: none;
}

#badwordsCont {
    display: none;
}

#badwords {
    width: 500px;
    height: 200px;
}

#wordCont .reason {
    width: 300px;
    height: 100px;
    display: block;
}

.diskCont .headerGray {
    font-size: 50px;
    font-family: "tablet_gothic_condensed";
    line-height: 60px;
    margin: 10px 0;
    border-top: 3px solid;
}

.diskCont .warning--unverified {
    padding: 30px 10px;
    color: #16212d;
    border: 1px solid #cad5df;
    font-size: 16px;
    line-height: 16px;
    background: #ffffff;
}

.loggedBox .forumAdminPanel a {
    color: #23aaff;
    font-size: 15px;
}

.forumAdminPanel {
    border-top: 1px solid #cad5df;
    padding: 10px;
}

.forumAdminPanel .showReportedComments {
    float: left;
    line-height: 30px;
}

.forumAdminPanel .showReportedComments a {
    padding: 0;
    margin: 0;
}

.forumAdminPanel .discussionStatus {
    float: right;
    line-height: 30px;
}

.commentsBox .metaAdmin {
    text-align: right;
    color: #707b87;
    font-size: 12px;
}

.commentsBox .metaAdmin a {
    color: #23aaff;
    font-size: 12px;
    font-style: italic;
}

.commentsBox .author a {
    color: #23aaff;
    font-size: 15px;
}

.forumLoginBox .terms label a {
    padding: 0;
    display: inline;
    float: none;
}

.forumLoginBox .terms,
.forumLoginBox .forgot {
    margin: 10px 0 20px;
}

.diskCont {
    margin: 20px 0;
    min-height: 3620px;
    /*	font-family: 'tablet_gothic_condensed';
	font-size: 22px;*/
    color: #16212d;
}

/* Styled select
-----------------------------------------------------------------------------*/
.s-hidden {
    display: none;
}

.styledSelect {
    cursor: pointer;
    display: inline-block;
    position: relative;
    font:
        normal 16px Arial,
        Sans-Serif;
    border: 1px solid #cad5df;
    color: #707b87;
    width: 250px;
}

.styledSelect .select {
    background-color: #fff;
    padding: 0 20px 0 10px;
    color: #707b87;
    font-family: "tablet_gothic_condensed";
    text-transform: uppercase;
    height: 30px;
    line-height: 30px;
}

.styledSelect:after {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 20%;
    right: 2px;
    left: auto;
    width: 50%;
    top: 8px;
    max-width: 100px;
    background: #777;
    -webkit-box-shadow: 0 2px 10px #777;
    -moz-box-shadow: 0 2px 10px #777;
    box-shadow: 0 2px 10px #777;
    -webkit-transform: rotate(7deg);
    -moz-transform: rotate(7deg);
    -o-transform: rotate(7deg);
    -ms-transform: rotate(7deg);
    transform: rotate(7deg);
}

.styledSelect .select:hover {
    background-color: #e9eff4;
}

.styledSelect .select.active:hover {
    background-color: #dee6ee;
}

.styledSelect .select:after {
    content: "";
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-color: #707b87 transparent transparent transparent;
    position: absolute;
    top: 13px;
    right: 6px;
}

.styledSelect .select:active,
.styledSelect .select.active {
    background-color: #dee6ee;
}

.styledSelect .options {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    left: -1px;
    z-index: 999;
    margin: 0 0;
    padding: 0 0;
    list-style: none;
    width: 100%;
    border: 1px solid #cad5df;
    border-top: 0px;
    background-color: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.styledSelect .options li {
    margin: 0 10px;
    border-bottom: 1px solid #e7edf3;
    height: 30px;
    line-height: 30px;
    font-weight: bold;
}

.styledSelect .options li:hover {
    color: #ff0000;
}

/* Stránkování
-----------------------------------------------------------------------------*/
.pagination {
    text-align: center;
    color: #707b87;
    margin: 20px 0;
}

.pagination a {
    color: #707b87;
    text-decoration: none;
    font-weight: bold;
    font-size: 15px;
    margin: 0px 7px;
    line-height: 20px;
}

.pagination a.aktpage {
    color: #ff0000;
}

.pagination a:hover {
    color: #16212d;
}

.pagination a.nextPage {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -900px -400px;
    text-indent: -9999px;
    display: inline-block;
    width: 10px;
}

.pagination a.nextPage:hover {
    background-position: -900px -450px;
}

.pagination a.prevPage {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -850px -400px;
    text-indent: -9999px;
    display: inline-block;
    width: 10px;
}

.pagination a.prevPage:hover {
    background-position: -850px -450px;
}

/* XML import - auto.cz
-----------------------------------------------------------------------------*/
.articleContainer.bigArticle .articlePhoto .autoCz img {
    width: 600px;
    height: auto;
    position: relative;
    top: -30px;
    left: 0px;
}

.articleContainer.bigArticle .articlePhoto .autoCz {
    width: 600px;
    height: 240px;
    position: absolute;
    top: 25px;
    left: 10px;
    display: block;
    overflow: hidden;
}

.articleContainer.smallArticle .articlePhoto.autoCZ {
    width: 280px;
    height: 158px;
    position: absolute;
    top: 0px;
    left: 0px;
    overflow: hidden;
}

.recentArticles .item .image .autoCZ,
.recentArticles .item .image .iSport {
    display: block;
    width: 120px;
    height: 68px;
    overflow: hidden;
    position: relative;
}

.recentArticles .item .image .autoCZ img {
    width: 120px;
    height: 85px;
    position: absolute;
    top: -7px;
}

.recentArticles .item .image .iSport img {
    width: 120px;
    height: auto;
    position: absolute;
    top: -2px;
}

.leadsection {
    position: relative;
}

.autoBookmark {
    display: none;
    width: 620px;
    height: 30px;
    background: #ff0000 url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat 540px -1392px;
    position: relative;
    top: 20px;
    left: -80px;
    border-radius: 3px 3px 0 0 !important;
}

.autoBookmark:hover,
.autoBookmark:hover span {
    text-decoration: underline;
}

.autoBookmark span {
    display: block;
    width: 130px;
    float: right;
    color: #ffffff;
    line-height: 30px;
    font-style: italic;
    text-align: right;
    padding: 0 0 0 10px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -1295px -1388px;
    margin: 0 87px 0 0;
}

.autoBookmarkLeft {
    display: none;
    width: 70px;
    padding: 3px;
    background: #ff0000;
    border-radius: 3px !important;
    position: absolute;
    left: -80px;
    top: 3px;
}

.autoBookmarkLeft:hover,
.autoBookmarkLeft:hover p {
    text-decoration: underline;
}

.autoBookmarkLeft p {
    display: block;
    color: #ffffff;
    line-height: 16px !important;
    font-size: 16px !important;
    font-style: italic;
    text-align: center;
    padding: 0 10px 0 10px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -1288px -1395px;
    margin: 0 !important;
}

.autoBookmarkLeft span {
    display: block;
    height: 24px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat 00px -1392px;
}

/* prepinani verzi
-----------------------------------------------------------------------------*/
.prepinacBleskNew {
    display: inline-block;
    width: 150px;
    margin: 0 10px 0 0;
    text-align: center;
    position: relative;
    top: 1px;
}

.prepinacBleskNew.homepage {
    position: absolute;
    left: 0px;
    top: 68px;
}

.prepinacBleskNew.homepage a {
    font-size: 15px;
    line-height: 25px;
}

.prepinacBleskNew a {
    font-family: "tablet_gothic_condensed";
    font-size: 16px;
    font-weight: bold;
    line-height: 23px;
    color: #ff0000;
}

.prepinacBleskNew span {
    width: 10px;
    height: 10px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -250px -216px;
    display: block;
    text-indent: 9999px;
    position: absolute;
    left: 0px;
    top: 7px;
    transform: rotate(180deg);
}

/* load more with ajax
-----------------------------------------------------------------------------*/
.loadMore {
    display: flex;
    justify-content: center;
    height: 40px;
    margin: 24px 0 48px;
}

.loadMore a.load,
.loadMore button.load {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 30px 7px 30px;
    background-color: #ff0000;
    color: #ffffff !important;
    border: none;
    font-family: "tablet_gothic_condensed";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.18px;
    cursor: pointer;
}

.loadMore a.load:hover {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -117px -1100px;
}

/*Pocasi na HP a ikony
----------------------------------------------------------------------*/
.forecastHP {
    height: 40px;
    line-height: 36px;
    float: left;
}

.forecastHP .forecast {
    color: #848f9b;
    /*	background: #ffffff; */
    font-size: 30px;
    font-family: "tablet_gothic_condensed";
    float: left;
    min-width: 100px;
    height: 38px;
    padding-top: 2px;
    margin-left: 25px;
}

.forecastHP .forecast a {
    color: #848f9b;
    text-decoration: none;
    line-height: 40px;
}

.forecastHP .forecast.tomorrow span,
.forecastHP .forecast.today span {
    font-size: 14px;
    float: left;
    margin-right: 5px;
}

.forecastHP .forecast .ico {
    display: block;
    width: 45px;
    height: 36px;
    float: left;
    padding-right: 6px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -850px 0px;
}

.forecastHP .forecast .ico.jasno {
    background-position: -800px -0px;
}

.forecastHP .forecast .ico.polojasno {
    background-position: -860px -0px;
}

.forecastHP .forecast .ico.mlha {
    background-position: -920px -0px;
}

.forecastHP .forecast .ico.mlha-dest {
    background-position: -980px -0px;
}

.forecastHP .forecast .ico.mlha-snih {
    background-position: -1040px -0px;
}

.forecastHP .forecast .ico.oblacno {
    background-position: -800px -60px;
}

.forecastHP .forecast .ico.oblacno-bourka,
.forecastHP .forecast .ico.polojasno-bourka {
    background-position: -860px -59px;
}

.forecastHP .forecast .ico.oblacno-dest,
.forecastHP .forecast .ico.polojasno-dest {
    background-position: -920px -59px;
}

.forecastHP .forecast .ico.oblacno-dest-snih {
    background-position: -980px -59px;
}

.forecastHP .forecast .ico.oblacno-snih,
.forecastHP .forecast .ico.polojasno-snih {
    background-position: -1040px -59px;
}

.forecastHP .forecast .ico.zatazeno {
    background-position: -800px -120px;
}

.forecastHP .forecast .ico.zatazeno-bourka {
    background-position: -860px -119px;
}

.forecastHP .forecast .ico.zatazeno-dest {
    background-position: -920px -120px;
}

/*
Původně: .ico.zatazeno-dest-snih - předěláno kvůli datům z BE
*/
.forecastHP .forecast .ico.zatazeno-dests {
    background-position: -980px -120px;
}

.forecastHP .forecast .ico.zatazeno-snih {
    background-position: -1040px -120px;
}

/* Pocasi - zahlavi na HP
----------------------------------------------------------------------*/
.forecastHP .pocasiHP {
    display: block;
    background: #23aaff;
    font-size: 18px;
    line-height: 18px;
    color: #ffffff;
    font-family: "tablet_gothic_condensed";
    float: left;
    width: 77px;
    height: 38px;
    padding-top: 3px;
    padding-left: 75px;
    position: relative;
    text-transform: uppercase;
}
.forecastHP .pocasiHP .frog-ico {
    position: absolute;
    width: 71px;
    height: 59px;
    z-index: 1;
    left: 0px;
    top: -10px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -700px -132px;
}
.forecastHP .pocasiHP .arrow {
    width: 7px;
    height: 11px;
    position: absolute;
    right: 10px;
    top: 14px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -680px -0px;
}

/* Predplatne
----------------------------------------------------------------------*/
.boxPaidLeft {
    display: block;
    width: 460px;
    padding: 0;
    margin: 0;
    float: left;
    position: relative;
}

.boxPaidRight {
    display: block;
    width: 460px;
    padding: 0;
    margin: 0;
    float: right;
    position: relative;
}

/* formulář - aktivace kódu
---------------------------------------*/
.formPaid {
    display: block;
    width: 420px;
    float: left;
    margin: 0 0 20px 0;
    padding: 15px 20px;
    background: #d01a1b;
    border-radius: 3px !important;
    min-height: 75px;
}

.formPaid form span {
    display: block;
    padding: 0 0 5px 0;
    font-size: 30px;
    color: #fff;
    font-weight: bold;
}

.formPaid form.std {
    font-size: 35px;
    color: #fff;
    font-weight: bold;
}

.formPaid form .inputText {
    display: block;
    float: left;
    width: 290px;
    height: 37px;
    font-size: 20px;
    font-weight: normal;
    line-height: 40px;
    color: #3b4548;
    /*	background: #ffffff; */
    margin: 4px 0 0 0;
    padding: 0 5px;
    border: 1px solid #dcdcdc;
    border-radius: 3px !important;
    background: #ffffff url("https://img2.cncenter.cz/images/newblesk/bg-paid-input-01.png?v=68") 5px center no-repeat;
}

.formPaid form .inputSubmit {
    display: block;
    float: right;
    width: 98px;
    height: 40px;
    font-size: 17px;
    font-weight: bold;
    line-height: 34px;
    color: #252525;
    margin: 4px 0 0 0;
    padding: 0 5px;
    border: 1px solid #740000;
    border-radius: 3px !important;
    background: #ee8900 url("https://img2.cncenter.cz/images/newblesk/bg-paid-btn-gradient.png?v=68") left top repeat-x;
}

.infoTextPaid {
    display: block;
    width: 440px;
    float: left;
    margin: 0;
    padding: 15px 10px;
    background: #e0e8eb;
    border-radius: 3px !important;
    color: #3b4548;
}

.infoTextPaid h3,
.infoTextPaid h4,
.archivFormCal h3,
.archivFormCal h4 {
    display: block;
    padding: 0 0 5px 0;
    color: #3b4548;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}

.archivFormCal h3,
.archivFormCal h4 {
    padding: 0;
}

.infoTextPaid h4 {
    font-size: 18px;
    font-weight: normal;
}

.infoTextPaid .infoTextIn {
    display: block;
    margin: 0;
    margin: 15px 20px 0 20px;
    padding: 10px 0px 10px 0px;
    background: #ffffff;
    border: 1px solid #babdbf;
    border-radius: 3px !important;
}

.infoTextPaid p {
    display: block;
    padding: 0 0 15px 0;
    color: #3b4548;
    font-size: 15px;
    font-weight: normal;
    text-align: center;
}

.infoTextPaid p.cenaLeft {
    width: 180px;
    float: left;
    padding: 0 0 0 10px;
    color: #d01a1b;
    font-size: 60px;
    font-weight: bold;
    text-align: center;
    border-right: 1px solid #c6c6c6;
}

.infoTextPaid p.cenaRight {
    width: 180px;
    float: right;
    padding: 0 10px 0 0;
    font-size: 60px;
    font-weight: bold;
    text-align: center;
}

.infoTextPaid p.cenaLeft span,
.infoTextPaid p.cenaRight span {
    font-size: 35px;
}

/* formulář - přihlášení
---------------------------------------*/
.formLogin {
    display: block;
    width: 420px;
    float: right;
    margin: 0 0 20px 0;
    padding: 31px 20px;
    background: #3b4548;
    border-radius: 3px !important;
    min-height: 75px;
}

.formLogin form span.spanLeft {
    display: block;
    width: 260px;
    float: left;
    padding: 0 0 5px 0;
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    height: 40px;
    overflow: hidden;
}

.formLogin form span.spanRight {
    display: block;
    width: 160px;
    float: right;
    padding: 0 0 5px 0;
    font-size: 14px;
    font-weight: normal;
    text-align: right;
    height: 40px;
    overflow: hidden;
}

.formLogin form span.spanRight a {
    display: block;
    color: #fff;
    text-decoration: none;
    font-weight: normal;
}

.formLogin form span.spanRight a:hover {
    color: #fff;
    text-decoration: underline;
    font-weight: normal;
}

.formLogin form .inputTextSmall {
    display: block;
    float: left;
    width: 135px;
    height: 28px;
    font-size: 16px;
    font-weight: normal;
    line-height: 26px;
    color: #3b4548;
    background: #ffffff;
    margin: 0 10px 0 0;
    padding: 0 5px;
    border: 1px solid #dcdcdc;
    border-radius: 3px !important;
}

.formLogin form #openid_identifier {
    background: #ffffff url("https://img2.cncenter.cz/images/newblesk/bg-paid-input-02.png?v=68") 5px center no-repeat;
}
.formLogin form #pass {
    background: #ffffff url("https://img2.cncenter.cz/images/newblesk/bg-paid-input-03.png?v=68") 5px center no-repeat;
}

.formLogin form .inputSubmit {
    display: block;
    float: right;
    width: 98px;
    height: 30px;
    font-size: 16px;
    font-weight: bold;
    line-height: 26px;
    color: #252525;
    margin: 0;
    padding: 0 2px;
    border: 1px solid #740000;
    border-radius: 3px !important;
    background: #ee8900 url("https://img2.cncenter.cz/images/newblesk/bg-paid-btn-gradient.png?v=68") left top repeat-x;
}

.formLogin form {
    display: block;
    position: relative;
    float: left;
}

.formLogin p {
    display: block;
    font-size: 13px;
    font-weight: normal;
    line-height: 17px;
    color: #ffffff;
    margin: 20px 0 15px 0;
    padding: 0px;
    text-align: left;
}

.formArchiv form .inputSubmit {
    display: block;
    width: 194px;
    height: 40px;
    font-size: 17px;
    font-weight: bold;
    line-height: 34px;
    color: #252525;
    margin: 20px auto 5px auto;
    padding: 0 5px;
    border: 1px solid #740000;
    border-radius: 3px !important;
    background: #ee8900 url("https://img2.cncenter.cz/images/newblesk/bg-paid-btn-gradient.png?v=68") left top repeat-x;
    cursor: pointer;
}

.infoTextPaid .infoTextIn {
    display: block;
    margin: 0;
    margin: 15px 20px 0 20px;
    padding: 10px 0px 10px 0px;
    background: #ffffff;
    border: 1px solid #babdbf;
    border-radius: 3px !important;
}

.infoTextPaid p {
    display: block;
    padding: 0 0 15px 0;
    color: #3b4548;
    font-size: 12px;
    font-weight: normal;
    text-align: center;
}

.infoTextPaid p.cenaLeft {
    width: 180px;
    float: left;
    padding: 0 0 0 10px;
    color: #d01a1b;
    font-size: 60px;
    font-weight: bold;
    text-align: center;
    border-right: 1px solid #c6c6c6;
}

.infoTextPaid p.cenaRight {
    width: 180px;
    float: right;
    padding: 0 10px 0 0;
    font-size: 60px;
    font-weight: bold;
    text-align: center;
}

.infoTextPaid p.cenaLeft span,
.infoTextPaid p.cenaRight span {
    font-size: 35px;
}

.archivCal.archivFormCal {
    width: 440px;
    padding: 10px 10px 20px 10px;
}

.archivCal {
    display: block;
    position: relative;
    width: 888px;
    margin: 0;
    padding: 15px 25px;
    border-radius: 3px !important;
    float: left;
    color: #3b4548;
    background: #e0e8eb;
}

.archivCal .archivCisloContainer {
    display: block;
    float: left;
    position: relative;
    width: 220px;
    height: 297px;
    margin: 0;
    padding: 0;
}

.archivCal .archivCisloContainer p {
    display: block;
    position: relative;
    top: 17px;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #3b4548;
}

.archivCal .archivCisloContainer p.textOpen {
    color: #4c6f00;
}

.archivCal .archivCislo {
    display: block;
    float: left;
    position: relative;
    width: 195px;
    height: 267px;
    margin: 15px 0 15px 14px;
    padding: 0 14px 0 0;
    border-right: 1px solid #cdd4d7;
    background: transparent url("https://img2.cncenter.cz/images/newblesk/bg-paid-titul-small.png?v=68") left top no-repeat;
    cursor: pointer;
}

.archivCal .archivCislo.lastLine {
    padding: 0;
    border-right: none;
}

.archivCal .archivCislo img {
    display: block;
    margin: 15px 0 0 15px;
    width: 160px;
    height: 235px;
}

.archivCal .archivCislo .paidClose,
.archivCal .archivCislo .paidOpen {
    display: block;
    width: 88px;
    height: 88px;
    position: absolute;
    bottom: -1px;
    right: 15px;
    z-index: 3;
}

.archivCal .archivCislo.lastLine .paidClose,
.archivCal .archivCislo.lastLine .paidOpen {
    bottom: -1px;
    right: 0px;
}

.archivCal .archivCislo .paidClose {
    background: transparent url("https://img2.cncenter.cz/images/newblesk/bg-paid-titul-close.png?v=14") right bottom no-repeat;
}
.archivCal .archivCislo .paidOpen {
    background: transparent url("https://img2.cncenter.cz/images/newblesk/bg-paid-titul-open.png?v=14") right bottom no-repeat;
}

.archivCal .archivCislo .archivCisloDark {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 194px;
    height: 267px;
    margin: 0;
    padding: 0;
    background: transparent url("https://img2.cncenter.cz/images/newblesk/bg-paid-titul-small-dark2.png?v=14") center center no-repeat;
}

.pagerContainer {
    position: relative;
    display: block;
    height: 40px;
}

.archivPrev,
.archivNext {
    display: inline-block;
    height: 28px;
    margin: 5px 0 0 0;
    padding: 0 10px 0 15px;
    color: #3b4548;
    text-decoration: none;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    position: absolute;
    left: 0;
    text-align: left;
    z-index: 3;
    background: transparent url("https://img2.cncenter.cz/images/newblesk/bg-paid-arrows-new.png?v=14") left -30px no-repeat;
}

.archivNext {
    left: auto;
    right: 0;
    text-align: right;
    padding: 0 15px 0 10px;
    background: transparent url("https://img2.cncenter.cz/images/newblesk/bg-paid-arrows-new.png?v=14") right top no-repeat;
}

.archivPrev:hover,
.archivNext:hover {
    color: #3b4548;
    text-decoration: underline;
}

.archivMounth {
    display: block;
    margin: 0;
    text-align: center;
    color: #3b4548;
    font-size: 18px;
    line-height: 30px;
    position: absolute;
    width: 978px;
    z-index: 2;
}

.paidTitle {
    margin: 0 0 20px 0;
    padding: 10px 15px 10px 155px;
    text-align: left;
    background: #e0e8eb url("https://img2.cncenter.cz/images/blesk-2013/bg-paid-logo-blesk.png?v=14") 10px 10px no-repeat;
    border: 0px solid #e0e8eb;
    -moz-border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    border-radius: 5px !important;
    display: block;
    /*	width: 330px; */
    height: 60px;
    line-height: 64px;
    color: #3b4548;
    font-size: 35px;
    position: relative;
    /*	float: left; */
    text-decoration: none;
}

.paidTitle a {
    color: #3b4548;
    text-decoration: none;
}

.paidTitle a:hover {
    color: #3b4548;
    text-decoration: underline;
}

.boxPaidLinky {
    margin: 0;
    padding: 15px 0px 15px 0px;
    text-align: center;
    background: #d01a1b;
    border: 0px solid #d01a1b;
    border-radius: 5px !important;
    display: block;
    width: 180px;
    height: 50px;
    line-height: 25px;
    color: #ffffff;
    font-size: 13px;
    float: right;
    position: absolute;
    top: 0;
    right: 0;
}

#infoConPaid1,
#infoConPaid2,
#infoConPaid3 {
    display: none;
}

.infoContainerPaid .infoTextPaid {
    z-index: 1;
    padding: 15px 10px 25px 10px;
}

.infoContainerPaid .formPaid {
    position: relative;
    margin: 0;
    top: -7px;
    z-index: 2;
}

.infoContainerPaid {
    display: block;
    width: 460px;
    padding: 10px;
    background: #ffffff;
    border: 1px solid #babdbf;
    border-radius: 3px !important;
    position: absolute;
    left: 50%;
    top: 50px;
    z-index: 51;
    margin: 0 0 0 -240px;
}

.infoContainerPaidDark {
    display: none;
    width: 100%;
    height: 100%;
    padding: 0;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/bg-paid-titul-dark.png?v=14") left top repeat;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 51;
    margin: 0;
}

.infoContainerPaidDark .btnClose {
    display: block;
    width: 29px;
    height: 29px;
    margin: 0;
    padding: 0;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/bg-paid-cross.png?v=14") left top no-repeat;
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 52;
    cursor: pointer;
}

.boxPaidLinky a {
    padding: 0 7px;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    font-weight: bold;
}

.boxPaidLinky a#boxPaidLogi,
.boxPaidLinky a.boxPaidLogi {
    border-right: 1px solid #ce7779;
}

.boxPaidLinky a:hover {
    color: #ffffff;
    text-decoration: underline;
    cursor: pointer;
}

#infoConPaid3 .formLogin {
    margin: 0 0 0 0;
}

#infoTextActual {
    display: block;
}

.textPaidSmall {
    font-size: 9px;
    font-weight: normal;
}

/* Horní Cross promo
--------------------------------------------------------------------------------*/
.crossPromo {
    background: transparent url("https://img2.cncenter.cz/images/isport/bg-promo-cross.png") top left repeat-x;
    height: 69px;
    margin: 0 0 15px;
    overflow: hidden;
    z-index: 5500;
    top: 0;
    width: 100%;
}

.crossPromo .promoLinks {
    width: 215px;
    float: left;
    margin: 0 0 0 5px;
}

.crossPromo .promoPhoto {
    width: 140px;
    float: left;
}

.crossPromo .promoPhoto img {
    width: 80px;
    height: 62px;
}

.crossPromo .promoPhoto {
    display: block;
    width: 80px;
    float: left;
}

.crossPromo .promoLinks .title {
    display: block;
    margin: 5px 0 0;
    color: #f0f4f6;
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
    line-height: 14px;
    height: 35px;
}

.crossPromo .promoLinks .title:hover {
    text-decoration: underline;
}

.crossPromo .outer_4:hover a.title {
    color: #d8e1e5;
}

.crossPromo .promoLinks a.magazineLink {
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    background: transparent url("https://img2.cncenter.cz/images/isport/elements.png?v=30") -362px 0px no-repeat;
    padding: 0 0 0 10px;
}

.crossPromo .promoLinks a.magazineLink:hover {
    background: transparent url("https://img2.cncenter.cz/images/isport/elements.png?v=30") -362px -24px no-repeat;
}

/*Horoskopy na HP*/

.zodiac.hp {
    float: left;
    border-left: 1px solid #cad5df;
    margin: 6px 0 9px;
    width: 190px;
    float: right;
}

.zodiac.hp li {
    width: 30px;
    height: 25px;
    float: left;
    overflow: hidden;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat;
    margin: 0px 15px 0px 14px;
    transition: all 0.2s ease-out;
}

.zodiac.hp.short {
    position: relative;
}

.zodiac.hp.short a {
    color: #16212d;
    text-transform: uppercase;
    font-size: 16px;
    font-family: "tablet_gothic_condensed";
    line-height: 25px;
    margin-left: 35px;
}

.zodiac.hp.short .arrow {
    display: block;
    width: 8px;
    height: 10px;
    position: absolute;
    top: 8px;
    right: 10px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -50px -216px;
}

.zodiac.hp.short .aries {
    width: 30px;
    height: 25px;
    float: left;
    overflow: hidden;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat;
    margin: 0px 25px 0px 14px;
    transition: all 0.2s ease-out;
}

.zodiac.hp.short .aries:hover {
    background-position: -0px -50px;
}

.zodiac.hp li.aquarius {
    background-position: -500px 0px;
}
.zodiac.hp li.aquarius:hover {
    background-position: -500px -50px;
}
.zodiac.hp li.pisces {
    background-position: -550px 0px;
}
.zodiac.hp li.pisces:hover {
    background-position: -550px -50px;
}
.zodiac.hp li.aries {
    background-position: -0px 0px;
}
.zodiac.hp li.aries:hover {
    background-position: -0px -50px;
}
.zodiac.hp li.taurus {
    background-position: -50px 0px;
}
.zodiac.hp li.taurus:hover {
    background-position: -50px -50px;
}
.zodiac.hp li.gemini {
    background-position: -100px 0px;
}
.zodiac.hp li.gemini:hover {
    background-position: -100px -50px;
}
.zodiac.hp li.cancer {
    background-position: -150px 0px;
}
.zodiac.hp li.cancer:hover {
    background-position: -150px -50px;
}
.zodiac.hp li.leo {
    background-position: -200px 0px;
}
.zodiac.hp li.leo:hover {
    background-position: -200px -50px;
}
.zodiac.hp li.virgo {
    background-position: -250px 0px;
}
.zodiac.hp li.virgo:hover {
    background-position: -250px -50px;
}
.zodiac.hp li.libra {
    background-position: -300px 0px;
}
.zodiac.hp li.libra:hover {
    background-position: -300px -50px;
}
.zodiac.hp li.scorpio {
    background-position: -350px 0px;
}
.zodiac.hp li.scorpio:hover {
    background-position: -350px -50px;
}
.zodiac.hp li.sagittarius {
    background-position: -400px 0px;
}
.zodiac.hp li.sagittarius:hover {
    background-position: -400px -50px;
}
.zodiac.hp li.capricorn {
    background-position: -450px 0px;
}
.zodiac.hp li.capricorn:hover {
    background-position: -450px -50px;
}

.zodiac.hp.ugly {
    margin: 5px 0 0 0;
    position: relative;
    border-left: none;
}
.zodiac.hp.ugly a {
    display: block;
    height: 35px;
    margin-left: 27px;
    padding-left: 30px;
    font-family: "tablet_gothic_condensed";
    font-size: 22px;
    line-height: 35px;
    text-transform: uppercase;
    color: #ff3cd6;
    background: transparent;
}
.zodiac.hp.ugly a .weird-ico {
    position: absolute;
    width: 37px;
    height: 49px;
    z-index: 1;
    left: 8px;
    top: -5px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -700px 0;
}
.zodiac.hp.ugly a .arrow {
    width: 7px;
    height: 11px;
    position: absolute;
    right: 10px;
    top: 10px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -680px -30px;
}

/* Spodni cross promoboxy nad patickou
--------------------------------------------------------------------*/
.bottomPromo {
    margin: 10px 0 10px;
}

.bottomPromo .promoHead {
    background: #ff0000;
    color: #fff;
    margin: 20px 10px;
    padding: 10px;
}

.bottomPromo .promoHead a {
    color: #fff;
    line-height: 40px;
    text-align: center;
    display: block;
    /*text-transform: uppercase;*/
    font-size: 40px;
    font-family: "tablet_gothic_condensed";
    padding: 0px 0 0px;
}

.bottomPromo .articleContainer.littleArticle .articlePhoto img {
    width: 120px;
    height: 68px;
    margin-top: 0px;
}

/* Error reporting - hlaseni chyb
---------------------------------------------------------------*/
#errorForm {
    margin: 10px 0;
    display: none;
    background: #fff;
}
.errorFromWrapper {
    background: #fff;
    padding-bottom: 30px;
}
#showError {
    font-size: 28px;
    font-family: "tablet_gothic_condensed";
    text-transform: uppercase;
    color: #16212d;
    padding: 10px 10px 0px;
    display: inline-block;
    cursor: pointer;
}

#showError:hover {
    text-decoration: none;
}

.hlaseniChyby {
    width: 650px;
    font-family: "tablet_gothic_condensed";
    font-size: 18px;
    color: #16212d;
    line-height: 22px;
    background: #fff;
}
.hlaseniChyby .checkBox {
    text-transform: uppercase;
    width: 50%;
    display: inline-block;
    float: left;
    text-align: center;
    margin: 10px 0;
}
.hlaseniChyby label {
    margin: 10px 0px 5px 0px;
    display: block;
    color: #f00;
}
.hlaseniChyby #popischyby {
    resize: none;
    padding: 10px;
    width: 570px;
    color: #16212d;
    font-size: 16px;
    font-family: "tablet_gothic_condensed", Arial;
    margin: 10px;
    border: none;
    min-height: 100px;
    background: #dee6ee;
}
.hlaseniChyby #captchaText {
    margin: 10px 0 5px 0;
    display: block;
    color: #f00;
}
.hlaseniChyby #captcha {
    height: 24px;
    width: 135px;
    background: #dee6ee;
}
.hlaseniChyby .captchaIn {
    margin: 10px;
}
.hlaseniChyby .captchaIn img {
    margin-right: 10px;
    float: left;
}
.hlaseniChyby .tipSubmit {
    float: left;
    color: #fff;
    background: #f00;
    border: none;
    font-family: "tablet_gothic_condensed";
    font-size: 20px;
    /* text-align: right; */
    display: block;
    text-transform: uppercase;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    padding: 7px 15px 0px 15px;
    margin-left: 10px;
}

/* Errors 404,500
---------------------------------------------------------------
.error404 {
margin: 30px auto;
text-align: center;
padding: 10px;
width: 940px;
}

.error404 {
color: #16212d;
font-size: 36px;
font-family: 'tablet_gothic_condensed';
}

.error404 .status {
color: #cad5df;
margin: 10px 0;
}

.error404 .apologise {
margin: 10px 0 80px;
}*/

/* Article - odkaz na fantasy
-------------------------------------------*/
.fantasy .bottomPromoLink {
    border: 1px solid #46825a;
    color: #ffffff;
    background: #46825a;
    border-bottom: 0px;
    line-height: 32px;
    margin: 9px 0 0;
    padding: 0 8px 0;
    position: relative;
    left: 0px;
    border-radius: 5px 5px 0 0;
    text-align: left;
    font-style: italic;
    font-size: 15px;
    font-weight: bold;
    display: block;
    text-decoration: none;
}

.fantasy .bottomPromoLink {
    background: #28325a;
    border: 1px solid #000a32;
}

.fantasy .bottomPromoLink {
    border-radius: 5px;
    margin: 20px 0;
}

.fantasy .bottomPromoLink:hover {
    background-color: #000a32;
}

.fantasy .bottomPromoLink span {
    display: block;
    width: 88px;
    height: 87px;
    text-indent: -9000px;
    background: transparent url("https://img2.cncenter.cz/images/fantasy/logo-mini.png?v=1") no-repeat left bottom;
    position: absolute;
    top: -15px;
    right: 0px;
}
.fantasy .bottomPromoLink span {
    top: -30px;
    right: 5px;
}

/* Souteze - contests bannery
---------------------------------------------------------------------*/

.contestCat {
    float: left;
    position: relative;
    top: -50px;
    left: -80px;
}

.elections2013Related {
    color: #fff;
    font-family: "tablet_gothic_condensed";
    float: left;
    position: relative;
    top: -25px;
    left: -80px;
    padding: 0px 0px;
    font-size: 19px;
    line-height: 22px;
    width: 640px;
    background: #f00 url("https://img2.cncenter.cz/images/blesk-2013/extra/volby2013/volby_baner.jpg") no-repeat top left;
    height: 99px;
}

.tricolorBorderTop {
    border-image: url("https://img2.cncenter.cz/images/blesk-2013/extra/volby2013/tricolor.png") 4 repeat !important;
    border-top-width: 4px;
}

.elections2013Related a {
    color: #fff;
    text-decoration: underline;
    text-indent: -9999px;
    display: inline-block;
    width: 100%;
    height: 100%;
}

.tricolor .sectionHeader {
    border-image: url("https://img2.cncenter.cz/images/blesk-2013/extra/volby2013/tricolor.png") 4 repeat !important;
}

/* tip types
---------------------------------------------------------------------*/
.tipForm {
    background-color: #f6f6f6;
    width: 387px;
    margin: 20px 0;
    text-align: center;
    padding: 10px 0;
}

.tipForm form {
    margin: 10px;
    text-align: left;
}

.tipForm label {
    display: inline-block;
    width: 80px;
    margin: 5px 0;
}

.tipForm label.full {
    width: inherit;
    display: inline;
    margin: 0;
    font-size: 10px;
}

.tipForm input {
    display: inline-block;
    width: 238px;
    height: 27px;
    margin: 5px 0;
    border: 1px solid #d8d8d8;
}

.tipForm .tipSubmit {
    width: 85px;
    height: 36px;
    background: #d80000;
    border-bottom: 3px solid #a40000;
    border-radius: 3px;
    line-height: 36px;
    color: #fff;
    cursor: pointer;
    font-size: 17px;
    font-weight: bold;
}

.vyrobekLinky {
    font-size: 18px;
    margin: 0;
    clear: both;
}

.vyrobekLinky a {
    color: #000000;
}

/* tip types  - dobry ucet
---------------------------------------------------------------------*/
.tipForm.dobryUcet {
    background: #bac3c6 url(http://img2.cncenter.cz/static/data/blesk/dobryvyrobek/dobry-ucet-right-form.jpg?v=65) left top no-repeat;
    width: 300px;
    margin: 0px;
    text-align: center;
    padding: 1px 0;
}

.tipForm.dobryUcet .nadpisForm {
    text-align: center;
    font-weight: bold;
}

.tipForm.dobryUcet form {
    margin: 6px;
}

.tipForm.dobryUcet label {
    font-weight: bold;
    font-size: 15px;
}

.tipForm.dobryUcet label.full {
    font-size: 10px;
    font-weight: normal;
}

.tipForm.dobryUcet input {
    display: inline-block;
    width: 190px;
    height: 27px;
    margin: 3px 0;
    border: 1px solid #dd3650;
}

.tipForm.dobryUcet .narozen {
    position: relative;
    top: -7px;
}

.tipForm.dobryUcet .povinne {
    text-align: right;
    font-size: 10px;
}

.tipForm.dobryUcet input#captcha {
    width: 120px;
}

.tipForm.dobryUcet .tipSubmit {
    color: #ffffff;
    background: #dd3650;
    border: none;
    font-family: "tablet_gothic_condensed";
    font-size: 20px;
    text-align: center;
    display: block;
    text-transform: uppercase;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    padding: 0 0 4px 0;
    margin: 10px auto;
}

/* Souteze - contests bannery
---------------------------------------------------------------------*/
.onlineReportBox {
    background: #fff;
    margin-top: 10px;
    border-top: 4px solid #ff0000;
}

.onlineReportBox.fixHeight {
    height: 370px;
    overflow: hidden;
}

.onlineReportBox.fixHeight .headerComments {
    font-size: 16px;
    line-height: 40px;
    margin-bottom: 0px;
    font-family: "tablet_gothic_compressed";
    padding-left: 0px;
    color: #fff;
    text-transform: uppercase;
    min-width: 60px;
    float: left;
    text-align: center;
    display: block;
    background: #ff0000;
    border-top: none;
}
.onlineReportBox.fixHeight .title {
    color: #2a3440;
    line-height: 40px;
    float: left;
    font-family: "tablet_gothic_condensed";
    font-size: 16px;
    text-transform: uppercase;
    padding-left: 10px;
}

.onlineReportBox .record.first {
    border-bottom: 1px solid #cad5df;
    margin: 10px 10px 0px 10px;
    padding: 0px 0px 19px;
}

.onlineReportBox .record {
    padding: 10px 0px;
    font-size: 15px;
    border-bottom: 1px solid #cad5df;
    margin: 0px 10px;
}

.onlineReportBox .question {
    padding-bottom: 10px;
}

.onlineReportBox .question .author {
    font-weight: bold;
    padding-left: 5px;
    font-size: 11px;
}

.onlineReportBox .live {
    position: absolute;
    top: 14px;
    right: 10px;
    color: #fff;
    background: #ff0000;
    padding: 5px 15px;
    font-size: 16px;
    font-family: "tablet_gothic_condensed";
    text-transform: uppercase;
}

.onlineReportBox .live a {
    color: #fff;
}

.onlineReportBox .records {
    width: 100%;
    height: 330px;
    overflow-x: hidden;
    overflow-y: auto;
}

.onlineReportBox .record .text {
    color: #333333;
    font-size: 15px;
    line-height: 17px;
}

.onlineReportBox .record .important {
    width: 23px;
    height: 23px;
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -1150px 0px;
    float: left;
    margin-left: 9px;
    margin-top: 5px;
}

.onlineReportBox .record .media.firstImage {
    float: left;
}

.onlineReportBox .record .media.rest {
    margin-top: 10px;
}

.onlineReportBox .record .media.rest img {
    margin: 0px 2px;
    float: left;
}

.onlineReportBox .record .media.firstImage img {
    margin: 0px 10px 10px 0px;
}

.onlineReportBox .record .text em {
    font-size: 16px;
    font-weight: bold;
    padding: 20px 0px 20px 30px;
    display: inline-block;
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-2013/quotes-bg.png?v=65") no-repeat;
    margin: 20px 0px;
}

.onlineReportBox .record .answer .reply {
    float: left;
    width: 50px;
    display: inline-block;
    margin-left: 0px;
    margin-right: 0px;
}

.onlineReportBox .footerReport {
    text-align: right;
    text-transform: uppercase;
    color: #2a3440;
    margin-right: 10px;
    padding: 0px;
    font-size: 16px;
    font-family: "tablet_gothic_condensed";
    line-height: 40px;
}

.onlineReportBox .footerReport a {
    font-size: 16px;
    color: #2a3440;
}
.onlineReportBox .footerReport .arrow {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -250px -216px no-repeat !important;
    display: block;
    float: right;
    width: 30px;
    height: 12px;
    margin-top: 14px;
    margin-left: 10px;
}

.onlineReportBox .record .answer .text {
    float: left;
    width: 520px;
    margin-left: 10px;
}

.onlineReportBox .record .avatar {
}

.onlineReportBox .record .time {
    color: #ff0000;
    font-size: 15px;
    padding: 5px 0 0;
    text-align: center;
    font-weight: bold;
}
.onlineReportBox .record .date {
    color: #16212d;
    font-size: 12px;
    padding: 5px 0 0;
    text-align: center;
    font-weight: normal;
    overflow: hidden;
}

/* Fixed footer
---------------------------------------------------------------*/
.footer-always {
    position: fixed;
    z-index: 500;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    color: #666;
    height: 25px;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    border-top: 1px solid #b6b6b6;
    padding: 2px 0px;
}

/* Condolence
---------------------------------------------------------------*/
.container .condolence {
    background: #16212d;
    margin-top: 10px;
    padding: 10px;
    width: 280px;
    position: relative;
}

.container .condolence .title {
    font-family: "tablet_gothic_condensed";
    color: #cad5df;
    font-size: 16px;
    text-transform: uppercase;
    line-height: 16px;
    padding-bottom: 10px;
}

.container .condolence .image {
    width: 280px;
}

.container .condolence .description {
    color: #cad5df;
    padding: 10px 0px;
    font-family: "tablet_gothic_condensed";
    font-size: 28px;
    line-height: 32px;
}

.container .condolence .addCondolence {
    background: #5c6773;
    color: #fff;
    font-family: "tablet_gothic_condensed";
    display: block;
    float: right;
    padding: 5px 10px;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 20px;
}

.container .condolence .condolenceCarousel {
    position: relative;
    height: 90px;
    margin-bottom: 10px;
    border-top: 1px solid #48535f;
    border-bottom: 1px solid #48535f;
}

.container .condolence .condolenceCarousel a.jcarousel-prev {
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -50px -216px;
    background-clip: content-box;
    background-origin: content-box;
}

.container .condolence .condolenceCarousel a.jcarousel-next {
    top: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -50px -216px;
    background-clip: content-box;
    background-origin: content-box;
}

.container .condolence .enterSpecial {
    background: #ff0000;
    color: #fff;
    font-family: "tablet_gothic_condensed";
    display: block;
    float: left;
    padding: 5px 10px;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 20px;
}

.container .condolence .jcarousel {
    position: relative;
    overflow: hidden;
    height: 80px;
    width: 250px;
    left: 15px;
    margin: 5px 0px;
    float: left;
}

.container .condolence .jcarousel li {
    width: 250px;
    color: #cad5df;
    font-family: Arial;
    font-size: 15px;
    line-height: 20px;
    margin: 0px 0px;
}

.container .condolence .jcarousel li .from {
    color: #848f9b;
    padding-left: 5px;
}

/* Greetings
---------------------------------------------------------------*/
.container .greetings {
    background: #ff0000;
    margin-top: 10px;
    padding: 10px;
    width: 280px;
    position: relative;
}

.container .greetings .title {
    font-family: "tablet_gothic_condensed";
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    line-height: 16px;
    padding-bottom: 10px;
}

.container .greetings .image {
    width: 280px;
}

.container .greetings .description {
    color: #fff;
    padding: 10px 0px;
    font-family: "tablet_gothic_condensed";
    font-size: 28px;
    line-height: 32px;
}

.container .greetings .addgreetings {
    background: #540101;
    color: #fff;
    font-family: "tablet_gothic_condensed";
    display: block;
    float: right;
    padding: 5px 10px;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 20px;
}

.container .greetings .greetingsCarousel {
    position: relative;
    height: 90px;
    margin-bottom: 10px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
}

.container .greetings .greetingsCarousel a.jcarousel-prev {
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -250px -232px;
    background-clip: content-box;
    background-origin: content-box;
}

.container .greetings .greetingsCarousel a.jcarousel-next {
    top: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -250px -232px;
    background-clip: content-box;
    background-origin: content-box;
}

.container .greetings .enterSpecial {
    background: #fff;
    color: #ff0000;
    font-family: "tablet_gothic_condensed";
    display: block;
    float: left;
    padding: 5px 10px;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 20px;
}

.container .greetings .jcarousel {
    position: relative;
    overflow: hidden;
    height: 80px;
    width: 250px;
    left: 15px;
    margin: 5px 0px;
    float: left;
}

.container .greetings .jcarousel li {
    width: 250px;
    color: #fff;
    font-family: Arial;
    font-size: 15px;
    line-height: 20px;
    margin: 0px 0px;
}

.container .greetings .jcarousel li .from {
    color: #eee;
    padding-left: 5px;
}

/* Condolence article
---------------------------------------------------------------*/
.condolenceCount,
#article .topGallery .condolenceCount {
    color: #cad5df;
    font-size: 36px;
    font-family: "tablet_gothic_condensed";
    padding: 20px 10px;
    background: #16212d;
    display: block;
}

.condolenceCount {
    display: none;
}

.condolenceList ul li {
    font-size: 15px;
    line-height: 20px;
    color: #16212d;
    background: #fff;
    padding: 20px;
    margin-bottom: 20px;
    list-style: none;
}

#article .article-body .condolenceList ul li p {
    font-size: 15px;
    line-height: 20px;
    margin: 0px;
}
#article .article-body .condolenceTalk input {
    background: #fff;
    border: 1px solid #cad5df;
    padding: 5px 10px;
    font-family: Arial;
    font-size: 15px;
    font-weight: normal;
    color: #98a3af;
    margin: 10px 0px;
    float: left;
}
.condolenceList ul li .from {
    color: #98a3af;
    padding-left: 7px;
}

#article .article-body .condolenceTalk .darkBtn {
    background: #5c6773;
    color: #fff;
    font-family: "tablet_gothic_condensed";
    display: block;
    float: right;
    padding: 5px 10px;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 20px;
    float: right;
    border: none;
    height: 20px;
}

#article .article-body .condolenceTalk h3 {
    font-size: 50px;
    text-transform: uppercase;
    padding-top: 10px;
    border-top: 4px solid #16212d;
    line-height: 50px;
}

/* Greetings article
---------------------------------------------------------------*/
.greetingsCount,
#article .topGallery .greetingsCount {
    color: #fff;
    font-size: 36px;
    font-family: "tablet_gothic_condensed";
    padding: 20px 10px;
    background: #16212d;
    display: block;
}

.greetingsCount {
    display: none;
}

.greetingsList ul li {
    font-size: 15px;
    line-height: 20px;
    color: #16212d;
    background: #fff;
    padding: 20px;
    margin-bottom: 20px;
    list-style: none;
}

#article .article-body .greetingsList ul li p {
    font-size: 15px;
    line-height: 20px;
    margin: 0px;
}
#article .article-body .greetingsTalk input {
    background: #fff;
    border: 1px solid #cad5df;
    padding: 5px 10px;
    font-family: Arial;
    font-size: 15px;
    font-weight: normal;
    color: #98a3af;
    margin: 10px 0px;
    float: left;
}
.greetingsList ul li .from {
    color: #98a3af;
    padding-left: 7px;
}

#article .article-body .greetingsTalk .darkBtn {
    background: #ff0000;
    color: #fff;
    font-family: "tablet_gothic_condensed";
    display: block;
    float: right;
    padding: 5px 10px;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 20px;
    float: right;
    border: none;
    height: 20px;
}

#article .article-body .greetingsTalk h3 {
    font-size: 50px;
    text-transform: uppercase;
    padding-top: 15px;
    border-top: 4px solid #ff0000;
    line-height: 50px;
    color: #ff0000;
}

/* Auction default */

.auction .title {
    font-family: "tablet_gothic_condensed";
    font-size: 65px;
    line-height: 70px;
    color: #16212d;
    padding-left: 80px;
    width: 550px;
    margin-bottom: 40px;
    margin-top: 30px;
}

.auction .description {
    font-size: 18px;
    line-height: 24px;
    color: #16212d;
    padding-left: 80px;
    width: 550px;
    margin-bottom: 40px;
}

.auction .items {
    padding-left: 10px;
    padding-right: 0px;
    width: 630px;
}

.auction .items .item {
    float: left;
    width: 200px;
    background: #fff;
    margin: 10px 5px;
    border-top: 4px solid #f00;
}

.auction .items .item .imageThumb {
    width: 100%;
}

.auction .items .item .author {
    font-size: 25px;
    line-height: 30px;
    padding: 5px;
    font-family: "tablet_gothic_condensed";
    color: #16212d;
}

.auction .items .item .status {
    font-family: "tablet_gothic_condensed";
    font-size: 16px;
    color: #fff;
    background: #ff0000;
    padding: 5px;
    display: block;
    text-transform: uppercase;
    margin: 10px auto 10px;
    position: relative;
    width: 180px;
    line-height: 20px;
    text-align: center;
}

.auction .items .item .price {
    font-family: "tablet_gothic_condensed";
    font-size: 16px;
    color: #fff;
    background: #16212d;
    padding: 5px;
    display: block;
    text-transform: uppercase;
    margin: 10px auto 10px;
    position: relative;
    width: 160px;
    line-height: 20px;
    text-align: center;
}

.auction .items .item .status:after {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 0px;
    right: 10px;
    left: auto;
    width: 90%;
    top: 7px;
    max-width: 260px;
    background: #888;
    box-shadow: 0 15px 10px #888;
    transform: rotate(3deg);
    height: 8px;
}

/* Constests Guide
-----------------------------------------------------------------------------*/
.contestsGuide {
    height: 49px;
    font-family: "tablet_gothic_condensed";
    margin: 0 10px;
    border-top: 0px solid #cad5df;
    border-bottom: 1px solid #cad5df;
}

.contestsGuide li {
    float: left;
    height: 36px;
    line-height: 36px;
    width: 115px;
    text-transform: uppercase;
    text-indent: -9999px;
    padding: 6px 28px 6px 29px;
    border-right: 1px solid #cad5df;
    background-origin: content-box;
    position: relative;
}

.contestsGuide li.home {
    margin: 6px 0px;
    font-size: 16px;
    padding: 0px;
    line-height: 18px;
    text-indent: 0px;
    width: 71px;
    border-right: 1px solid #cad5df;
}

.contestsGuide li:hover {
}

.contestsGuide li.divkaLeta:hover,
.contestsGuide li.zlaticka:hover,
.contestsGuide li.kockaCeska:hover,
.contestsGuide li.nejHoubar:hover,
.contestsGuide li.bleskMissEarth:hover {
    background-color: #f4f6f7;
}

.contestsGuide li.noRightBorder {
    border-right: 0px;
}

.contestsGuide li.divkaLeta {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/contests/contests-sprites.png") no-repeat 0px 0px;
    background-origin: content-box;
}

.contestsGuide li.zlaticka {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/contests/contests-sprites.png") no-repeat 0px -50px;
    background-origin: content-box;
}

.contestsGuide li.kockaCeska {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/contests/contests-sprites.png") no-repeat 0px -201px;
    background-origin: content-box;
}

.contestsGuide li.nejHoubar {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/contests/contests-sprites.png") no-repeat 0px -102px;
    background-origin: content-box;
}

.contestsGuide li.bleskMissEarth {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/contests/contests-sprites.png") no-repeat 0px -152px;
    background-origin: content-box;
    width: 119px;
}

.contestsGuide li.nejPes {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/contests/contests-sprites.png?=1") no-repeat 0px -246px;
    background-origin: content-box;
}

.contestsGuide li .arrow {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/contests/contests-right-arrow.png") no-repeat 0px;
    width: 7px;
    height: 10px;
    display: block;
    position: absolute;
    top: 19px;
    right: 7px;
}

/* Horoskopy v pravem sloupci
-----------------------------------------------------------------------------*/
.horoscopesRight {
    background: #fff;
    border-top: 4px solid #7323eb;
    margin: 10px;
}

.horoscopesRight .header a {
    font-family: "tablet_gothic_condensed";
    color: #7323eb;
    text-transform: uppercase;
    margin: 5px 10px;
    display: inline-block;
}

.horoscopesRight .list {
    border-top: 1px solid #e6e1dc;
    margin-top: 5px;
    margin: 5px 10px;
}

.horoscopesRight .sign {
    float: left;
    margin: 5px 0px;
}

.horoscopesRight .sign span {
    display: block;
    width: 46px;
    height: 50px;
}

.horoscopesRight .sign .caption {
    font-size: 12px;
    width: 100%;
    text-align: center;
    color: #827d78;
    margin: 5px 0px;
}

.horoscopesRight .list a:hover .caption {
    text-decoration: underline;
}

.horoscopesRight .signMain {
    display: none;
    padding-bottom: 5px;
}

.horoscopesRight .signMain--displayed {
    display: block;
}

.horoscopesRight .signMain img {
    float: left;
}

.horoscopesRight .signMain .name {
    font-family: Arial;
    font-size: 19px;
    margin: 5px 0px;
    color: #7323eb;
    text-decoration: none;
}

.horoscopesRight .signMain a,
.horoscopesRight .signMain a:hover {
    text-decoration: none;
}

.horoscopesRight .signMain .name a {
    color: #7323eb;
}

.horoscopesRight .signMain .text {
    font-size: 15px;
    color: #827d78;
    height: 110px;
    overflow: hidden;
    line-height: 22px;
    width: 154px;
}

.horoscopesRight .signMain a {
    display: block;
    background-size: 136px 136px;
    padding-left: 136px;
    width: 100%;
    min-height: 136px;
    background-color: rgba(0, 0, 0, 0);
    background-repeat: no-repeat;
}

.horoscopesRight .signMain a.aries {
    background-image: url("https://img2.cncenter.cz/images/blesk-horoskopy/wc/aries-min.jpg");
}

.horoscopesRight .signMain a.taurus {
    background-image: url("https://img2.cncenter.cz/images/blesk-horoskopy/wc/taurus-min.jpg");
}

.horoscopesRight .signMain a.gemini {
    background-image: url("https://img2.cncenter.cz/images/blesk-horoskopy/wc/gemini-min.jpg");
}

.horoscopesRight .signMain a.cancer {
    background-image: url("https://img2.cncenter.cz/images/blesk-horoskopy/wc/cancer-min.jpg");
}

.horoscopesRight .signMain a.leo {
    background-image: url("https://img2.cncenter.cz/images/blesk-horoskopy/wc/leo-min.jpg");
}

.horoscopesRight .signMain a.virgo {
    background-image: url("https://img2.cncenter.cz/images/blesk-horoskopy/wc/virgo-min.jpg");
}

.horoscopesRight .signMain a.libra {
    background-image: url("https://img2.cncenter.cz/images/blesk-horoskopy/wc/libra-min.jpg");
}

.horoscopesRight .signMain a.scorpio {
    background-image: url("https://img2.cncenter.cz/images/blesk-horoskopy/wc/scorpio-min.jpg");
}

.horoscopesRight .signMain a.sagittarius {
    background-image: url("https://img2.cncenter.cz/images/blesk-horoskopy/wc/sagittarius-min.jpg");
}

.horoscopesRight .signMain a.capricorn {
    background-image: url("https://img2.cncenter.cz/images/blesk-horoskopy/wc/capricorn-min.jpg");
}

.horoscopesRight .signMain a.aquarius {
    background-image: url("https://img2.cncenter.cz/images/blesk-horoskopy/wc/aquarius-min.jpg");
}

.horoscopesRight .signMain a.pisces {
    background-image: url("https://img2.cncenter.cz/images/blesk-horoskopy/wc/pisces-min.jpg");
}

.horoscopesRight .aries span {
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-horoskopy/sprites/signs/signs.webp") no-repeat -15px 0;
}

.horoscopesRight .aries.active span {
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-horoskopy/sprites/signs/signs.webp") no-repeat -15px -60px;
}

.horoscopesRight .taurus span {
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-horoskopy/sprites/signs/signs.webp") no-repeat -95px 0;
}

.horoscopesRight .taurus.active span {
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-horoskopy/sprites/signs/signs.webp") no-repeat -95px -60px;
}

.horoscopesRight .gemini span {
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-horoskopy/sprites/signs/signs.webp") no-repeat -175px 0;
}

.horoscopesRight .gemini.active span {
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-horoskopy/sprites/signs/signs.webp") no-repeat -175px -60px;
}

.horoscopesRight .cancer span {
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-horoskopy/sprites/signs/signs.webp") no-repeat -255px 0;
}

.horoscopesRight .cancer.active span {
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-horoskopy/sprites/signs/signs.webp") no-repeat -255px -60px;
}

.horoscopesRight .leo span {
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-horoskopy/sprites/signs/signs.webp") no-repeat -335px 0;
}

.horoscopesRight .leo.active span {
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-horoskopy/sprites/signs/signs.webp") no-repeat -335px -60px;
}

.horoscopesRight .virgo span {
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-horoskopy/sprites/signs/signs.webp") no-repeat -415px 0;
}

.horoscopesRight .virgo.active span {
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-horoskopy/sprites/signs/signs.webp") no-repeat -415px -60px;
}

.horoscopesRight .libra span {
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-horoskopy/sprites/signs/signs.webp") no-repeat -495px 0;
}

.horoscopesRight .libra.active span {
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-horoskopy/sprites/signs/signs.webp") no-repeat -495px -60px;
}

.horoscopesRight .scorpio span {
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-horoskopy/sprites/signs/signs.webp") no-repeat -575px 0;
}

.horoscopesRight .scorpio.acitve span {
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-horoskopy/sprites/signs/signs.webp") no-repeat -575px -60px;
}

.horoscopesRight .sagittarius span {
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-horoskopy/sprites/signs/signs.webp") no-repeat -655px 0;
}

.horoscopesRight .sagittarius.active span {
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-horoskopy/sprites/signs/signs.webp") no-repeat -655px -60px;
}

.horoscopesRight .capricorn span {
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-horoskopy/sprites/signs/signs.webp") no-repeat -735px 0;
}

.horoscopesRight .capricorn.active span {
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-horoskopy/sprites/signs/signs.webp") no-repeat -735px -60px;
}

.horoscopesRight .aquarius span {
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-horoskopy/sprites/signs/signs.webp") no-repeat -815px 0;
}

.horoscopesRight .aquarius.active span {
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-horoskopy/sprites/signs/signs.webp") no-repeat -815px -60px;
}

.horoscopesRight .pisces span {
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-horoskopy/sprites/signs/signs.webp") no-repeat -895px 0;
}

.horoscopesRight .pisces.active span {
    background: rgba(0, 0, 0, 0) url("https://img2.cncenter.cz/images/blesk-horoskopy/sprites/signs/signs.webp") no-repeat -895px -60px;
}

/*****************************************************
********************* BARVY **************************
******************************************************/

/* Default - */

.articleContainer,
.sectionHeader {
    border-top-color: #ff0000 !important;
}

.alternative .articleContainer,
.alternative .sectionHeader,
.alternative .articleContainer,
.alternative .sectionHeader {
    border-top-color: #540101 !important;
}
.alternative .liveArticleContainer,
.alternative.liveArticleContainer {
    border-top-color: #540101;
}
.alternative .liveArticleHomeBig:hover .hoverColor {
    color: #540101;
}

.color,
a.color,
a.hoverColor:hover,
.articleContainer:hover a.hoverColor {
    color: #ff0000 !important;
}

.bg {
    background-color: #ff0000 !important;
}

.alternative .articleContainer {
    background-color: #ff0000 !important;
    color: #ff0000 !important;
}

.alternative .color,
.alternative a.color,
.alternative .color,
.alternative a.color,
.alternative .articleContainer a,
.alternative .articleContainer a {
    color: #fff !important;
}

.alternative a.hoverColor:hover,
.alternative .color.persist,
.alternative a.hoverColor:hover,
.alternative .color.persist,
.articleContainer:hover .color.persist,
.alternative .articleContainer:hover a.hoverColor,
.alternative .articleContainer:hover a.hoverColor {
    color: #540101 !important;
}

.articleContainer.bigOneArticle .title .arrow {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -150px no-repeat !important;
}

.recentArticles .categoryArrow .arrow,
.articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -250px -216px no-repeat !important;
}

.alternative .articleContainer p.perex:before,
.alternative .articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0px -900px no-repeat !important;
}

.sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -250px -200px no-repeat !important;
}

.alternative .sectionHeader li a.dropdownMenu:after,
.alternative .sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

.iconPlay {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -250px -150px no-repeat !important;
}
.iconPhoto {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -250px -100px no-repeat !important;
}

/* Pro muže - .domino */

.domino .articleContainer,
.domino .sectionHeader {
    /*	border-top-color: #8b7c5e !important; */
    border-top-color: #ff5a00 !important;
}
.domino .liveArticleContainer,
.domino.liveArticleContainer {
    /*	border-top-color: #8b7c5e; */
    border-top-color: #ff5a00;
}
.domino .liveArticleHomeBig:hover .hoverColor {
    /*	color: #8b7c5e; */
    color: #ff5a00;
}

.domino .alternative .articleContainer,
.domino .alternative .sectionHeader,
.domino.alternative .articleContainer,
.domino.alternative .sectionHeader {
    border-top-color: #362a13 !important;
}

.domino .color,
.domino a.color,
.domino a.hoverColor:hover,
.domino .articleContainer:hover a.hoverColor {
    /*	color: #8b7c5e !important; */
    color: #ff5a00 !important;
}

.domino .bg {
    /*	background-color: #8b7c5e !important; */
    background-color: #ff5a00 !important;
}

.domino.alternative .articleContainer {
    /*	background-color: #8b7c5e !important; */
    /*	color: #8b7c5e !important; */
    background-color: #ff5a00 !important;
    color: #ff5a00 !important;
}

.domino.alternative .color,
.domino.alternative a.color,
.domino .alternative .color,
.domino .alternative a.color,
.domino .alternative .articleContainer a,
.domino.alternative .articleContainer a {
    color: #fff !important;
}

.domino.alternative a.hoverColor:hover,
.domino.alternative .color.persist,
.domino .alternative a.hoverColor:hover,
.domino .alternative .color.persist,
.domino .articleContainer:hover .color.persist,
.domino .alternative .articleContainer:hover a.hoverColor,
.domino.alternative .articleContainer:hover a.hoverColor {
    color: #362a13 !important;
}

.domino .articleContainer.bigOneArticle .title .arrow {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -0px no-repeat !important;
}

.domino .recentArticles .categoryArrow .arrow,
.domino .articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -0px -216px no-repeat !important;
}

.domino.alternative .articleContainer p.perex:before,
.domino .alternative .articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0px -900px no-repeat !important;
}

.domino .sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -0px -200px no-repeat !important;
}

.domino.alternative .sectionHeader li a.dropdownMenu:after,
.domino .alternative .sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

/*.domino .iconPlay {
	background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -0px -150px no-repeat !important;
}

.domino .iconPhoto {
	background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -0px -100px no-repeat !important;
}*/

/* Digital - .chateau */

.chateau .articleContainer,
.chateau .sectionHeader {
    border-top-color: #99a4aa !important;
}
.chateau .liveArticleContainer,
.chateau.liveArticleContainer {
    border-top-color: #99a4aa;
}
.chateau .liveArticleHomeBig:hover .hoverColor {
    color: #99a4aa;
}

.chateau .alternative .articleContainer,
.chateau .alternative .sectionHeader,
.chateau.alternative .articleContainer,
.chateau.alternative .sectionHeader {
    border-top-color: #424e56 !important;
}

.chateau .color,
.chateau a.color,
.chateau a.hoverColor:hover,
.chateau .articleContainer:hover a.hoverColor {
    color: #99a4aa !important;
}

.chateau .bg {
    background-color: #99a4aa !important;
}

.chateau.alternative .articleContainer {
    background-color: #99a4aa !important;
    color: #99a4aa !important;
}

.chateau.alternative .color,
.chateau.alternative a.color,
.chateau .alternative .color,
.chateau .alternative a.color,
.chateau .alternative .articleContainer a,
.chateau.alternative .articleContainer a {
    color: #fff !important;
}

.chateau.alternative a.hoverColor:hover,
.chateau.alternative .color.persist,
.chateau .alternative a.hoverColor:hover,
.chateau .alternative .color.persist,
.chateau .articleContainer:hover .color.persist,
.chateau .alternative .articleContainer:hover a.hoverColor,
.chateau.alternative .articleContainer:hover a.hoverColor {
    color: #424e56 !important;
}

.chateau .articleContainer.bigOneArticle .title .arrow {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -30px no-repeat !important;
}

.chateau .recentArticles .categoryArrow .arrow,
.chateau .articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -50px -216px no-repeat !important;
}

.chateau.alternative .articleContainer p.perex:before,
.chateau .alternative .articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0px -900px no-repeat !important;
}

.chateau .sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -50px -200px no-repeat !important;
}

.chateau.alternative .sectionHeader li a.dropdownMenu:after,
.chateau .alternative .sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

/*.chateau .iconPlay {
	background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -50px -150px no-repeat !important;
}

.chateau .iconPhoto {
	background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -50px -100px no-repeat !important;
}*/

/* Cestování - .scooter */

.scooter .articleContainer,
.scooter .sectionHeader {
    border-top-color: #3cc3dc !important;
}
.scooter .liveArticleContainer,
.scooter.liveArticleContainer {
    border-top-color: #3cc3dc;
}
.scooter .liveArticleHomeBig:hover .hoverColor {
    color: #3cc3dc;
}

.scooter .alternative .articleContainer,
.scooter .alternative .sectionHeader,
.scooter.alternative .articleContainer,
.scooter.alternative .sectionHeader {
    border-top-color: #004754 !important;
}

.scooter .color,
.scooter a.color,
.scooter a.hoverColor:hover,
.scooter .articleContainer:hover a.hoverColor {
    color: #3cc3dc !important;
}

.scooter .bg {
    background-color: #3cc3dc !important;
}

.scooter.alternative .articleContainer {
    background-color: #3cc3dc !important;
    color: #3cc3dc !important;
}

.scooter.alternative .color,
.scooter.alternative a.color,
.scooter .alternative .color,
.scooter .alternative a.color,
.scooter .alternative .articleContainer a,
.scooter.alternative .articleContainer a {
    color: #fff !important;
}

.scooter.alternative a.hoverColor:hover,
.scooter.alternative .color.persist,
.scooter .alternative a.hoverColor:hover,
.scooter .alternative .color.persist,
.scooter .articleContainer:hover .color.persist,
.scooter .alternative .articleContainer:hover a.hoverColor,
.scooter.alternative .articleContainer:hover a.hoverColor {
    color: #004754 !important;
}

.scooter .articleContainer.bigOneArticle .title .arrow {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -300px no-repeat !important;
}

.scooter .recentArticles .categoryArrow .arrow,
.scooter .articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -500px -216px no-repeat !important;
}

.scooter.alternative .articleContainer p.perex:before,
.scooter .alternative .articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0px -900px no-repeat !important;
}

.scooter .sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -500px -200px no-repeat !important;
}

.scooter.alternative .sectionHeader li a.dropdownMenu:after,
.scooter .alternative .sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

/*.scooter .iconPlay {
	background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -500px -150px no-repeat !important;
}

.scooter .iconPhoto {
	background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -500px -100px no-repeat !important;
}*/

/* Digital - grey */

.container.grey .grid_12 .sectionHeader {
    border-top-color: rgb(153, 164, 170) !important;
}
.container.grey .grid_12 .sectionHeader h2 a:hover {
    color: rgb(153, 164, 170) !important;
}
.container.grey .grid_12 .sectionHeader .subSections li a {
    color: rgb(153, 164, 170) !important;
}
.container.grey .grid_12 .sectionHeader .subSections li a:hover {
    color: rgb(153, 164, 170) !important;
}
.container.grey .grid_12 div .inner_4 .articleCOntainer .wrapper .categoryTitle a {
    color: rgb(153, 164, 170) !important;
}
.container.grey .grid_12 div .inner_4 .articleCOntainer .wrapper .categoryTitle a:hover {
    color: rgb(153, 164, 170) !important;
}
.container.grey .grid_12 div .inner_4 .articleCOntainer .wrapper .title a {
    color: rgb(0, 0, 0) !important;
}
.container.grey .grid_12 div .inner_4 .articleCOntainer .wrapper .title a:hover {
    color: rgb(153, 164, 170) !important;
}
.container.grey .grid_12 .articleContainer .wrapper .categoryTitle a {
    color: rgb(153, 164, 170) !important;
}
.container.grey .grid_12 .articleContainer .wrapper .categoryTitle a:hover {
    color: rgb(153, 164, 170) !important;
}
.container.grey .grid_12 .articleContainer .wrapper .title a {
    color: rgb(0, 0, 0) !important;
}
.container.grey .grid_12 .articleContainer .wrapper .title a:hover {
    color: rgb(153, 164, 170) !important;
}
.container.grey .grid_12 .sectionHeader .subSections li ul {
    background-color: #e9eff4 !important;
}
.container.grey .grid_12 .sectionHeader .subSections li a:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -50px -200px no-repeat !important;
}

/* Hvězdní reportéři + TV.Blesk - .heliotrope */

.heliotrope .articleContainer,
.heliotrope .sectionHeader {
    border-top-color: #b469f5 !important;
}
.heliotrope .liveArticleContainer,
.heliotrope.liveArticleContainer {
    border-top-color: #b469f5;
}
.heliotrope .liveArticleHomeBig:hover .hoverColor {
    color: #b469f5;
}

.heliotrope .alternative .articleContainer,
.heliotrope .alternative .sectionHeader,
.heliotrope.alternative .articleContainer,
.heliotrope.alternative .sectionHeader {
    border-top-color: #3a016b !important;
}

.heliotrope .color,
.heliotrope a.color,
.heliotrope a.hoverColor:hover,
.heliotrope .articleContainer:hover a.hoverColor {
    color: #b469f5 !important;
}

.heliotrope .bg {
    background-color: #b469f5 !important;
}

.heliotrope.alternative .articleContainer {
    background-color: #b469f5 !important;
    color: #b469f5 !important;
}

.heliotrope.alternative .color,
.heliotrope.alternative a.color,
.heliotrope .alternative .color,
.heliotrope .alternative a.color,
.heliotrope .alternative .articleContainer a,
.heliotrope.alternative .articleContainer a {
    color: #fff !important;
}

.heliotrope.alternative a.hoverColor:hover,
.heliotrope.alternative .color.persist,
.heliotrope .alternative a.hoverColor:hover,
.heliotrope .alternative .color.persist,
.heliotrope .articleContainer:hover .color.persist,
.heliotrope .alternative .articleContainer:hover a.hoverColor,
.heliotrope.alternative .articleContainer:hover a.hoverColor {
    color: #3a016b !important;
}

.heliotrope .articleContainer.bigOneArticle .title .arrow {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -90px no-repeat !important;
}

.heliotrope .recentArticles .categoryArrow .arrow,
.heliotrope .articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -150px -216px no-repeat !important;
}

.heliotrope.alternative .articleContainer p.perex:before,
.heliotrope .alternative .articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0px -900px no-repeat !important;
}

.heliotrope .sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -150px -200px no-repeat !important;
}

.heliotrope.alternative .sectionHeader li a.dropdownMenu:after,
.heliotrope .alternative .sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

/*.heliotrope .iconPlay {
	background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -150px -150px no-repeat !important;
}

.heliotrope .iconPhoto {
	background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -150px -100px no-repeat !important;
}*/

/* Móda s Františkou - .robinblue */

.robinblue .articleContainer,
.robinblue .sectionHeader {
    border-top-color: #00c8be !important;
}
.robinblue .liveArticleContainer,
.robinblue.liveArticleContainer {
    border-top-color: #00c8be;
}
.robinblue .liveArticleHomeBig:hover .hoverColor {
    color: #00c8be;
}

.robinblue .alternative .articleContainer,
.robinblue .alternative .sectionHeader,
.robinblue.alternative .articleContainer,
.robinblue.alternative .sectionHeader {
    border-top-color: #004d49 !important;
}

.robinblue .color,
.robinblue a.color,
.robinblue a.hoverColor:hover,
.robinblue .articleContainer:hover a.hoverColor {
    color: #00c8be !important;
}

.robinblue .bg {
    background-color: #00c8be !important;
}

.robinblue.alternative .articleContainer {
    background-color: #00c8be !important;
    color: #00c8be !important;
}

.robinblue.alternative .color,
.robinblue.alternative a.color,
.robinblue .alternative .color,
.robinblue .alternative a.color,
.robinblue .alternative .articleContainer a,
.robinblue.alternative .articleContainer a {
    color: #fff !important;
}

.robinblue.alternative a.hoverColor:hover,
.robinblue.alternative .color.persist,
.robinblue .alternative a.hoverColor:hover,
.robinblue .alternative .color.persist,
.robinblue .articleContainer:hover .color.persist,
.robinblue .alternative .articleContainer:hover a.hoverColor,
.robinblue.alternative .articleContainer:hover a.hoverColor {
    color: #004d49 !important;
}

.robinblue .articleContainer.bigOneArticle .title .arrow {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -270px no-repeat !important;
}

.robinblue .recentArticles .categoryArrow .arrow,
.robinblue .articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -450px -216px no-repeat !important;
}

.robinblue.alternative .articleContainer p.perex:before,
.robinblue .alternative .articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0px -900px no-repeat !important;
}

.robinblue .sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -450px -200px no-repeat !important;
}

.robinblue.alternative .sectionHeader li a.dropdownMenu:after,
.robinblue .alternative .sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

/*.robinblue .iconPlay {
	background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -450px -150px no-repeat !important;
}

.robinblue .iconPhoto {
	background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -450px -100px no-repeat !important;
}*/

/* Zprávy - .chetwode */

.chetwode .articleContainer,
.chetwode .sectionHeader {
    border-top-color: #6e78dc !important;
}
.chetwode .liveArticleContainer,
.chetwode.liveArticleContainer {
    border-top-color: #6e78dc;
}
.chetwode .liveArticleHomeBig:hover .hoverColor {
    color: #6e78dc;
}

.chetwode .alternative .articleContainer,
.chetwode .alternative .sectionHeader,
.chetwode.alternative .articleContainer,
.chetwode.alternative .sectionHeader {
    border-top-color: #191e56 !important;
}

.chetwode .color,
.chetwode a.color,
.chetwode a.hoverColor:hover,
.chetwode .articleContainer:hover a.hoverColor {
    color: #6e78dc !important;
}

.chetwode .bg {
    background-color: #6e78dc !important;
}

.chetwode.alternative .articleContainer {
    background-color: #6e78dc !important;
    color: #6e78dc !important;
}

.chetwode.alternative .color,
.chetwode.alternative a.color,
.chetwode .alternative .color,
.chetwode .alternative a.color,
.chetwode .alternative .articleContainer a,
.chetwode.alternative .articleContainer a {
    color: #fff !important;
}

.chetwode.alternative a.hoverColor:hover,
.chetwode.alternative .color.persist,
.chetwode .alternative a.hoverColor:hover,
.chetwode .alternative .color.persist,
.chetwode .articleContainer:hover .color.persist,
.chetwode .alternative .articleContainer:hover a.hoverColor,
.chetwode.alternative .articleContainer:hover a.hoverColor {
    color: #191e56 !important;
}

.chetwode .articleContainer.bigOneArticle .title .arrow {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -360px no-repeat !important;
}

.chetwode .recentArticles .categoryArrow .arrow,
.chetwode .articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -600px -216px no-repeat !important;
}

.chetwode.alternative .articleContainer p.perex:before,
.chetwode .alternative .articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0px -900px no-repeat !important;
}

.chetwode .sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -600px -200px no-repeat !important;
}

.chetwode.alternative .sectionHeader li a.dropdownMenu:after,
.chetwode .alternative .sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

/*.chetwode .iconPlay {
	background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -600px -150px no-repeat !important;
}

.chetwode .iconPhoto {
	background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -600px -100px no-repeat !important;
}*/

/* Hobby - .jade */

.jade .articleContainer,
.jade .sectionHeader {
    border-top-color: #00b373 !important;
}
.jade .liveArticleContainer,
.jade.liveArticleContainer {
    border-top-color: #00b373;
}
.jade .liveArticleHomeBig:hover .hoverColor {
    color: #00b373;
}

.jade .alternative .articleContainer,
.jade .alternative .sectionHeader,
.jade.alternative .articleContainer,
.jade.alternative .sectionHeader {
    border-top-color: #00482e !important;
}

.jade .color,
.jade a.color,
.jade a.hoverColor:hover,
.jade .articleContainer:hover a.hoverColor {
    color: #00b373 !important;
}

.jade .bg {
    background-color: #00b373 !important;
}

.jade.alternative .articleContainer {
    background-color: #00b373 !important;
    color: #00b373 !important;
}

.jade.alternative .color,
.jade.alternative a.color,
.jade .alternative .color,
.jade .alternative a.color,
.jade .alternative .articleContainer a,
.jade.alternative .articleContainer a {
    color: #fff !important;
}

.jade.alternative a.hoverColor:hover,
.jade.alternative .color.persist,
.jade .alternative a.hoverColor:hover,
.jade .alternative .color.persist,
.jade .articleContainer:hover .color.persist,
.jade .alternative .articleContainer:hover a.hoverColor,
.jade.alternative .articleContainer:hover a.hoverColor {
    color: #00482e !important;
}

.jade .articleContainer.bigOneArticle .title .arrow {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -240px no-repeat !important;
}

.jade .recentArticles .categoryArrow .arrow,
.jade .articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -400px -216px no-repeat !important;
}

.jade.alternative .articleContainer p.perex:before,
.jade .alternative .articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0px -900px no-repeat !important;
}

.jade .sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -400px -200px no-repeat !important;
}

.jade.alternative .sectionHeader li a.dropdownMenu:after,
.jade .alternative .sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

/*.jade .iconPlay {
	background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -400px -150px no-repeat !important;
}

.jade .iconPhoto {
	background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -400px -100px no-repeat !important;
}*/

/* Hobby pod Bydlení - .homeorange .jade */

.homeorange.jade .articleContainer,
.homeorange.jade .sectionHeader {
    border-top-color: #00b373 !important;
}
.homeorange.jade .liveArticleContainer,
.homeorange.jade.liveArticleContainer {
    border-top-color: #00b373;
}
.homeorange.jade .liveArticleHomeBig:hover .hoverColor {
    color: #00b373;
}

.homeorange.jade .alternative .articleContainer,
.homeorange.jade .alternative .sectionHeader,
.homeorange.jade.alternative .articleContainer,
.homeorange.jade.alternative .sectionHeader {
    border-top-color: #00482e !important;
}

.homeorange.jade .color,
.homeorange.jade a.color,
.homeorange.jade a.hoverColor:hover,
.homeorange.jade .articleContainer:hover a.hoverColor {
    color: #00b373 !important;
}

.homeorange.jade .bg {
    background-color: #00b373 !important;
}

.homeorange.jade.alternative .articleContainer {
    background-color: #00b373 !important;
    color: #00b373 !important;
}

.homeorange.jade.alternative .color,
.homeorange.jade.alternative a.color,
.homeorange.jade .alternative .color,
.homeorange.jade .alternative a.color,
.homeorange.jade .alternative .articleContainer a,
.homeorange.jade.alternative .articleContainer a {
    color: #fff !important;
}

.homeorange.jade.alternative a.hoverColor:hover,
.homeorange.jade.alternative .color.persist,
.homeorange.jade .alternative a.hoverColor:hover,
.homeorange.jade .alternative .color.persist,
.homeorange.jade .articleContainer:hover .color.persist,
.homeorange.jade .alternative .articleContainer:hover a.hoverColor,
.homeorange.jade.alternative .articleContainer:hover a.hoverColor {
    color: #00482e !important;
}

.homeorange.jade .articleContainer.bigOneArticle .title .arrow {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -240px no-repeat !important;
}

.homeorange.jade .recentArticles .categoryArrow .arrow,
.homeorange.jade .articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -400px -216px no-repeat !important;
}

.homeorange.jade.alternative .articleContainer p.perex:before,
.homeorange.jade .alternative .articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0px -900px no-repeat !important;
}

.homeorange.jade .sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -400px -200px no-repeat !important;
}

.homeorange.jade.alternative .sectionHeader li a.dropdownMenu:after,
.homeorange.jade .alternative .sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

/* Hry - .saffron */

.saffron .articleContainer,
.saffron .sectionHeader {
    border-top-color: #f7b135 !important;
}
.saffron .liveArticleContainer,
.saffron.liveArticleContainer {
    border-top-color: #f7b135;
}
.saffron .liveArticleHomeBig:hover .hoverColor {
    color: #f7b135;
}

.saffron .alternative .articleContainer,
.saffron .alternative .sectionHeader,
.saffron.alternative .articleContainer,
.saffron.alternative .sectionHeader {
    border-top-color: #694506 !important;
}

.saffron .color,
.saffron a.color,
.saffron a.hoverColor:hover,
.saffron .articleContainer:hover a.hoverColor {
    color: #f7b135 !important;
}

.saffron .bg {
    background-color: #f7b135 !important;
}

.saffron.alternative .articleContainer {
    background-color: #f7b135 !important;
    color: #f7b135 !important;
}

.saffron.alternative .color,
.saffron.alternative a.color,
.saffron .alternative .color,
.saffron .alternative a.color,
.saffron .alternative .articleContainer a,
.saffron.alternative .articleContainer a {
    color: #fff !important;
}

.saffron.alternative a.hoverColor:hover,
.saffron.alternative .color.persist,
.saffron .alternative a.hoverColor:hover,
.saffron .alternative .color.persist,
.saffron .articleContainer:hover .color.persist,
.saffron .alternative .articleContainer:hover a.hoverColor,
.saffron.alternative .articleContainer:hover a.hoverColor {
    color: #694506 !important;
}

.saffron .articleContainer.bigOneArticle .title .arrow {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -180px no-repeat !important;
}

.saffron .recentArticles .categoryArrow .arrow,
.saffron .articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -300px -216px no-repeat !important;
}

.saffron.alternative .articleContainer p.perex:before,
.saffron .alternative .articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0px -900px no-repeat !important;
}

.saffron .sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -300px -200px no-repeat !important;
}

.saffron.alternative .sectionHeader li a.dropdownMenu:after,
.saffron .alternative .sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

/*.saffron .iconPlay {
	background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -300px -150px no-repeat !important;
}

.saffron .iconPhoto {
	background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -300px -100px no-repeat !important;
}*/

/* Rádce - .dodger */

.dodger .articleContainer,
.dodger .sectionHeader {
    border-top-color: #23aaff !important;
}
.dodger .liveArticleContainer,
.dodger.liveArticleContainer {
    border-top-color: #23aaff;
}
.dodger .liveArticleHomeBig:hover .hoverColor {
    color: #23aaff;
}

.dodger .alternative .articleContainer,
.dodger .alternative .sectionHeader,
.dodger.alternative .articleContainer,
.dodger.alternative .sectionHeader {
    border-top-color: #053d60 !important;
}

.dodger .color,
.dodger a.color,
.dodger a.hoverColor:hover,
.dodger .articleContainer:hover a.hoverColor {
    color: #23aaff !important;
}

.dodger .bg {
    background-color: #23aaff !important;
}

.dodger.alternative .articleContainer {
    background-color: #23aaff !important;
    color: #23aaff !important;
}

.dodger.alternative .color,
.dodger.alternative a.color,
.dodger .alternative .color,
.dodger .alternative a.color,
.dodger .alternative .articleContainer a,
.dodger.alternative .articleContainer a {
    color: #fff !important;
}

.dodger.alternative a.hoverColor:hover,
.dodger.alternative .color.persist,
.dodger .alternative a.hoverColor:hover,
.dodger .alternative .color.persist,
.dodger .articleContainer:hover .color.persist,
.dodger .alternative .articleContainer:hover a.hoverColor,
.dodger.alternative .articleContainer:hover a.hoverColor {
    color: #053d60 !important;
}

.dodger .articleContainer.bigOneArticle .title .arrow {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -0px no-repeat !important;
}

.dodger .recentArticles .categoryArrow .arrow,
.dodger .articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -550px -216px no-repeat !important;
}

.dodger.alternative .articleContainer p.perex:before,
.dodger .alternative .articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0px -900px no-repeat !important;
}

.dodger .sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -550px -200px no-repeat !important;
}

.dodger.alternative .sectionHeader li a.dropdownMenu:after,
.dodger .alternative .sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

/*.dodger .iconPlay {
	background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -550px -150px no-repeat !important;
}

.dodger .iconPhoto {
	background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -550px -100px no-repeat !important;
}*/

/* Sport - .atlantis */

.atlantis .articleContainer,
.atlantis .sectionHeader {
    border-top-color: #91c837 !important;
}
.atlantis .liveArticleContainer,
.atlantis.liveArticleContainer {
    border-top-color: #91c837;
}
.atlantis .liveArticleHomeBig:hover .hoverColor {
    color: #91c837;
}

.atlantis .alternative .articleContainer,
.atlantis .alternative .sectionHeader,
.atlantis.alternative .articleContainer,
.atlantis.alternative .sectionHeader {
    border-top-color: #355401 !important;
}

.atlantis .color,
.atlantis a.color,
.atlantis a.hoverColor:hover,
.atlantis .articleContainer:hover a.hoverColor {
    color: #91c837 !important;
}

.atlantis .bg {
    background-color: #91c837 !important;
}

.atlantis.alternative .articleContainer {
    background-color: #91c837 !important;
    color: #91c837 !important;
}

.atlantis.alternative .color,
.atlantis.alternative a.color,
.atlantis .alternative .color,
.atlantis .alternative a.color,
.atlantis .alternative .articleContainer a,
.atlantis.alternative .articleContainer a {
    color: #fff !important;
}

.atlantis.alternative a.hoverColor:hover,
.atlantis.alternative .color.persist,
.atlantis .alternative a.hoverColor:hover,
.atlantis .alternative .color.persist,
.atlantis .articleContainer:hover .color.persist,
.atlantis .alternative .articleContainer:hover a.hoverColor,
.atlantis.alternative .articleContainer:hover a.hoverColor {
    color: #355401 !important;
}

.atlantis .articleContainer.bigOneArticle .title .arrow {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -210px no-repeat !important;
}

.atlantis .recentArticles .categoryArrow .arrow,
.atlantis .articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -350px -216px no-repeat !important;
}

.atlantis.alternative .articleContainer p.perex:before,
.atlantis .alternative .articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0px -900px no-repeat !important;
}

.atlantis .sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -350px -200px no-repeat !important;
}

.atlantis.alternative .sectionHeader li a.dropdownMenu:after,
.atlantis .alternative .sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

/*.atlantis .iconPlay {
	background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -350px -150px no-repeat !important;
}

.atlantis .iconPhoto {
	background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -350px -100px no-repeat !important;
}*/

.clonedMenu {
    display: none;
}

/* Pro ženy - .hotpink */

.hotpink .articleContainer,
.hotpink .sectionHeader {
    border-top-color: #fe60b2 !important;
}
.hotpink .liveArticleContainer,
.hotpink.liveArticleContainer {
    border-top-color: #fe60b2;
}
.hotpink .liveArticleHomeBig:hover .hoverColor {
    color: #fe60b2;
}

.hotpink .alternative .articleContainer,
.hotpink .alternative .sectionHeader,
.hotpink.alternative .articleContainer,
.hotpink.alternative .sectionHeader {
    border-top-color: #6d0139 !important;
}

.hotpink .color,
.hotpink a.color,
.hotpink a.hoverColor:hover,
.hotpink .articleContainer:hover a.hoverColor {
    color: #fe60b2 !important;
}

.hotpink .bg {
    background-color: #fe60b2 !important;
}

.hotpink.alternative .articleContainer {
    background-color: #fe60b2 !important;
    color: #fe60b2 !important;
}

.hotpink.alternative .color,
.hotpink.alternative a.color,
.hotpink .alternative .color,
.hotpink .alternative a.color,
.hotpink .alternative .articleContainer a,
.hotpink.alternative .articleContainer a {
    color: #fff !important;
}

.hotpink.alternative a.hoverColor:hover,
.hotpink.alternative .color.persist,
.hotpink .alternative a.hoverColor:hover,
.hotpink .alternative .color.persist,
.hotpink .articleContainer:hover .color.persist,
.hotpink .alternative .articleContainer:hover a.hoverColor,
.hotpink.alternative .articleContainer:hover a.hoverColor {
    color: #6d0139 !important;
}

.hotpink .articleContainer.bigOneArticle .title .arrow {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -120px no-repeat !important;
}

.hotpink .recentArticles .categoryArrow .arrow,
.hotpink .articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -200px -216px no-repeat !important;
}

.hotpink.alternative .articleContainer p.perex:before,
.hotpink .alternative .articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0px -900px no-repeat !important;
}

.hotpink .sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -200px -200px no-repeat !important;
}

.hotpink.alternative .sectionHeader li a.dropdownMenu:after,
.hotpink .alternative .sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

/* Bydlení - začátek */

.homeorange .articleContainer,
.homeorange .sectionHeader {
    border-top-color: #ff7300 !important;
}
.homeorange .liveArticleContainer,
.homeorange.liveArticleContainer {
    border-top-color: #ff7300;
}
.homeorange .liveArticleHomeBig:hover .hoverColor {
    color: #ff7300;
}

.homeorange .alternative .articleContainer,
.homeorange .alternative .sectionHeader,
.homeorange.alternative .articleContainer,
.homeorange.alternative .sectionHeader {
    border-top-color: #ab4d00 !important;
}

.homeorange .color,
.homeorange a.color,
.homeorange a.hoverColor:hover,
.homeorange .articleContainer:hover a.hoverColor {
    color: #ff7300 !important;
}

.homeorange .bg {
    background-color: #ff7300 !important;
}

.homeorange.alternative .articleContainer {
    background-color: #ff7300 !important;
    color: #ff7300 !important;
}

.homeorange.alternative .color,
.homeorange.alternative a.color,
.homeorange .alternative .color,
.homeorange .alternative a.color,
.homeorange .alternative .articleContainer a,
.homeorange.alternative .articleContainer a {
    color: #fff !important;
}

.homeorange.alternative a.hoverColor:hover,
.homeorange.alternative .color.persist,
.homeorange .alternative a.hoverColor:hover,
.homeorange .alternative .color.persist,
.homeorange .articleContainer:hover .color.persist,
.homeorange .alternative .articleContainer:hover a.hoverColor,
.homeorange.alternative .articleContainer:hover a.hoverColor {
    color: #ab4d00 !important;
}

.homeorange .articleContainer.bigOneArticle .title .arrow {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -120px no-repeat !important;
}

.homeorange .recentArticles .categoryArrow .arrow,
.homeorange .articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -200px -216px no-repeat !important;
}

.homeorange.alternative .articleContainer p.perex:before,
.homeorange .alternative .articleContainer p.perex:before {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0px -900px no-repeat !important;
}

.homeorange .sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -200px -200px no-repeat !important;
}

.homeorange.alternative .sectionHeader li a.dropdownMenu:after,
.homeorange .alternative .sectionHeader li a.dropdownMenu:after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

/* Bydlení - obalka Lide a zeme */
.obalka-bydleni {
    display: block;
    width: 300px;
    min-height: 330px;
    margin: 10px auto 0;
    background: #ff7300 url("https://img2.cncenter.cz/images/blesk/bydleni/pozadi.jpg?v=92") 0 0 no-repeat;
}

.obalka-bydleni .edition-magazine {
    padding: 15px;
}

.obalka-bydleni .linkImage a {
    display: block;
    width: 194px;
    margin: 0 auto;
    box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.5);
    background: #ffffff;
    text-align: center;
    padding: 7px;
}

.obalka-bydleni a img {
    display: block;
    width: 100%;
    height: 100%;
}

.obalka-bydleni .linkText {
    display: block;
    margin: 15px 0 0;
    text-align: center;
}

.obalka-bydleni .linkText a {
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
}

.obalka-bydleni .linkText a span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 0 1px 10px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -0px -900px;
}

/* Bydlení - konec */

/*.hotpink .iconPlay {
	background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -200px -150px no-repeat !important;
}

.hotpink .iconPhoto {
	background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -200px -100px no-repeat !important;
}*/

/*  Soci top 3 articles */
.top3Soci-pic {
    float: left;
    width: 120px;
    height: 68px;
    overflow: hidden;
    font-size: 10px;
}
.sociPic {
    width: 120px;

    overflow: hidden;
}

.top3Soci-article {
    float: left;
    width: 52%;
    max-height: 68px !important;
    padding-left: 5px;
    overflow: hidden;
}

.top3Soci-article a {
    color: #000;
    font-size: 14px;
    line-height: 122%;
}
.top3Soci-article a:hover {
    color: #dc0032;
    text-decoration: underline;
}

.noBorderBox {
    box-sizing: border-box;
    margin: 0 10px 0 0;
    padding: 10px;
}
.noBorderBox:first-of-type {
    margin-left: 0px;
}

.top3Container {
    background: #fff;
    position: relative;
    padding: 0 0px;
}
.noBorderBox:hover a.hoverColor {
    color: #ff0000 !important;
}

/* Vertical videoportal */
.iconCelaKauza {
    display: block;
    top: 135px;
    left: 75px;
    position: absolute;
    width: 133px;
    height: 38px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/celakauza-play-red.png") no-repeat;
}
.iconCelaKauza-bz {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/celakauza-play-red.png") no-repeat;
}
.iconCelaKauza-lesk {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/celakauza-play-red.png") no-repeat;
}
.iconCelaKauza-mff {
    left: 121px;
    width: 40px;
    height: 37px;
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -250px -150px no-repeat !important;
}

.whiteBg {
    background: #fff;
    padding-bottom: 10px;
    margin: 10px 0 10px 10px;
}
.videoFirstPic {
    width: 300px;
    height: 158px;
    overflow: hidden;
    margin-bottom: 15px;
}

.videoFirstPic img {
    width: 280px;
}
.videoFirstTitle {
    overflow: hidden;
    height: 92px;
    margin-bottom: 12px;
    margin-top: 20px;
    padding-top: 5px;
}
.videoFirstTitle a {
    font-size: 24px;
    color: #16212d;
    font-family: "tablet_gothic_condensed";
}

.dateVideo {
    float: right;
    display: block;
    /*margin-right:10px;*/
    font-size: 15px;
    text-align: left;
    margin-bottom: 5px;
    color: #98a3af;
}

.linkCol {
    position: relative;
    padding-top: 0px;
    width: 280px;
    margin: 0 auto;
}
.linkCol:hover .videoFirstTitle a {
    color: #b469f5;
}
.linkCol:hover .leskTitle.videoFirstTitle a {
    color: #1c3c7f;
}
.linkCol:hover .bzTitle.videoFirstTitle a {
    color: #4b141b;
}
.playButtBig {
    position: absolute;
    top: 10px;
    left: 11px;
}

.vertVideo .top {
    height: 29px;
    background: #16212d;
    margin: 0;
    padding: 0;
    position: relative;
}
.vertVideo .top .logoTV {
    width: 99px;
    height: 29px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -850px -1400px no-repeat;
    position: absolute;
    left: 10px;
    top: 0;
}
.vertVideo .top .logoTV.sportTV {
    background: transparent url("https://img2.cncenter.cz/images/isport/TVsport-small.png?v=65") 0px 0px no-repeat;
}
.vertVideo .top .logoTV-art {
    left: 0px;
}
.vertVideo .top .logoLesk {
    width: 100%;
    height: 29px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/lesk-head.png?v=3") no-repeat;
    position: absolute;
    left: 0px;
    top: 0;
}
.vertVideo .top .logoBZ {
    width: 100%;
    height: 29px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/bz-head.png?v=3") no-repeat;
    position: absolute;
    left: 0px;
    top: 0;
}
.vertVideo .top .logoStudioBlesk {
    width: 100%;
    height: 29px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/header_studio_blesk.png") no-repeat;
    position: absolute;
    left: 0px;
    top: 0;
}
.vertVideo .top .logoKV {
    width: 100%;
    height: 29px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/mff-head.png?v=3") no-repeat;
    position: absolute;
    left: 0px;
    top: 0;
}
.vertVideo .top .header {
    float: right;
    font-family: "tablet_gothic_condensed";
    color: #e9eff4;
    font-size: 15px;
    line-height: 25px;
    width: 180px;
    text-align: right;
    padding-right: 10px;
}
.vertVideo .top .header a {
    font-family: "tablet_gothic_condensed";
    color: #b469f5;
    font-size: 15px;
    position: relative;
    top: 2px;
    padding: 0;
}
.header-bz a {
    font-family: "tablet_gothic_condensed";
    color: #ac7279;
    font-size: 15px;
    position: absolute;
    top: 8px;
    left: 190px;
}
.header-bzBanner {
    top: 17px;
    left: 175px;
    font-family: "tablet_gothic_condensed";
    color: #ac7279;
    font-size: 15px;
    position: absolute;
}
.header-tvBanner a {
    top: 5px;
    left: 145px;
    color: #000;
}
.tvBanner-arrow {
    display: inline-block;
    width: 10px;
    height: 11px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -93px -215px no-repeat;
    padding: 0 0 0 5px;
    margin-left: 4px;
    margin-top: 5px;
}
.header-lesk a {
    font-family: "tablet_gothic_condensed";
    color: #a9b6eb;
    font-size: 15px;
    position: absolute;
    top: 8px;
    left: 190px;
}
.header-vary a {
    font-family: "tablet_gothic_condensed";
    color: #a9b6eb;
    font-size: 15px;
    position: absolute;
    top: 9px;
    left: 230px;
}
.header-studioBlesk a {
    font-family: "tablet_gothic_condensed";
    color: white;
    font-size: 15px;
    position: absolute;
    top: 9px;
    left: 194px;
    white-space: nowrap;
}
.header-leskBanner {
    top: 17px;
    left: 190px;
    position: absolute;
    font-family: "tablet_gothic_condensed";
    color: #a9b6eb;
    font-size: 15px;
}

.vertVideo .top .header a span {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -847px -1450px no-repeat;
    padding: 0px;
    margin-left: 7px;
}
.borderTop {
    border-top: 1px solid #ccc;
    margin-top: 10px;
}
.leskBanner {
    display: block;
    width: 280px;
    height: 45px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/lesk-banner2.png?v=1") no-repeat;
    margin-left: 10px;
    position: relative;
}
.studiobleskBanner {
    display: block;
    width: 280px;
    height: 45px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/studio-blesk-banner.png?v=3") no-repeat;
    margin-left: 10px;
    position: relative;
}

.bzBanner {
    display: block;
    width: 280px;
    height: 45px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/bz-banner2.png?v=1") no-repeat;
    margin-left: 10px;
    position: relative;
}
.borderTvBanner {
    border-top: 1px solid #ccc;
    width: 280px;
    margin: 5px;
    margin-left: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
}
.logoTvWrapp {
    position: relative;
    width: 280px;
    height: 29px;
    margin-left: 10px;
}
.logoTvBanner {
    width: 99px;
    height: 29px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -850px -1400px no-repeat;
    position: absolute;
    left: 0px;
    top: 0;
}
.header-tvBanner a:hover {
    text-decoration: none;
}
.vertVideo .top a:hover {
    text-decoration: none;
}

/* videoportal pod clanky */

.linkCol-art {
    position: relative;
    padding-top: 0px;
    width: 620px;
    margin: 0 10px 0 0px !important;
    overflow: hidden;
}
.linkCol-art:hover .videoFirstTitle a {
    color: #b469f5;
}
.linkCol-art:hover .leskTitle.videoFirstTitle a {
    color: #1c3c7f;
}
.linkCol-art:hover .bzTitle.videoFirstTitle a {
    color: #4b141b;
}

.leskBanner-art {
    float: left;
    display: block;
    width: 157px;
    height: 47px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/lesk-banner-art.png?v=1") no-repeat;
    position: relative;
    margin-left: 10px;
}
.bzBanner-art {
    float: left;
    display: block;
    width: 157px;
    height: 47px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/bz-banner-art.png?v=1") no-repeat;
    position: relative;
    margin-left: 10px;
}
.tvBanner-art {
    float: left;
    display: block;
    width: 157px;
    height: 47px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/tv-banner-art.png?v=1") no-repeat;
    position: relative;
    margin-left: 10px;
}
.celebrityBanner-art {
    float: left;
    display: block;
    width: 157px;
    height: 47px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/celebrity-banner-art.png?v=1") no-repeat;
    position: relative;
    margin-left: 10px;
}
.header-art {
    top: 17px;
    left: 105px;
    position: absolute;
    font-family: "tablet_gothic_condensed";
    color: #a9b6eb;
    font-size: 15px;
}
.header-bz-art {
    color: #ac7279;
}
.header-tv-art {
    color: #ddd;
}

.header-lesk-art {
    color: #a9b6eb;
}
.header-celebrity-art {
    color: #ffffff;
}
.banners .header-art.header-celebrity-art,
.banners .header-art.header-bz-art {
    top: 18px;
}
.vertVideo .top .logoLesk-art {
    width: 100%;
    height: 29px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/lesk-head-art.png?v=1") no-repeat;
    position: absolute;
    left: 0px;
    top: 0;
}
.vertVideo .top .logoBZ-art {
    width: 100%;
    height: 29px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/bz-head-art.png?v=1") no-repeat;
    position: absolute;
    left: 0px;
    top: 0;
}
.artHead-lesk a {
    left: 515px;
    top: 8px;
}
.artHead-bz a {
    left: 515px;
    top: 8px;
}
.numOfWatch {
    float: right;
    font-size: 12px;
    text-align: right;
    margin-right: 10px;
    font-family: Arial;
    display: block;
    color: #979ba0;
}
.numOfWatchEye {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -880px -1370px no-repeat;
    width: 20px;
    height: 15px;
    display: block;
    float: right;
    margin-left: 15px;

    opacity: 0.3;
}

.whiteBg-art {
    margin-bottom: 20px;
    margin-left: 10px;
    width: 620px;
    height: 186px;
}
.banners {
    float: left;
    width: 334px;
    margin: 5px 0 0 6px;
}
.dateVideo-art {
    font-family: arial;
    font-size: 12px;
    color: #979ba0;
    margin-bottom: 0;
}
.videoPic-art {
    width: 280px;
    height: 170px;
}
.videoTitle-art {
    float: left;
    width: 315px;
    height: 75px;
    overflow: hidden;
    margin: 10px 10px 5px 15px;
    line-height: 30px;
}

.iconCelaKauza-art {
    display: block;
    top: 150px;
    left: 130px;
    position: absolute;
    width: 38px;
    height: 38px;
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat scroll -250px -150px transparent;
    /*background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat scroll -150px -150px transparent;*/
}

.iconCelaKauza-bzart {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat scroll -250px -150px transparent;
    /*background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat scroll -1050px -1399px transparent !important;*/
}

.iconCelaKauza-lesk-art {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat scroll -250px -150px transparent;
    /*background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat scroll -1000px -1399px transparent !important;*/
}
.vertVideo .top .artHead-bz a span {
    display: inline-block;
    width: 5px;
    height: 10px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -875px -1450px no-repeat;
    padding: 0 0 0 5px;
    margin-left: 7px;
}
.vertVideo .top .artHead-lesk a span {
    display: inline-block;
    width: 5px;
    height: 10px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -905px -1450px no-repeat;
    padding: 0 0 0 5px;
    margin-left: 7px;
}
.rubrika-videoport .numOfWatch {
    margin-right: 0;
}
.rubrika-videoport .videoFirstTitle {
    margin-bottom: 0;
    margin-top: 0;
    height: 48px;
    float: left;
    width: 220px;
    overflow: hidden;
    line-height: 24px;
}
.rubrika-videoport .watched {
    margin-top: 38px;
    float: right;
    width: 60px;
}
.rubrika-videoport .watched-big {
    margin-top: 5px;
    float: right;
    width: 60px;
}
.rubrika-videoport .numOfWatchEye {
    margin-left: 0px;
}
.rubrika-videoport .iconCelaKauza-art {
    top: 132px;
}
.rubrika-videoport .linkCol.small img {
    width: 120px;
    max-height: 72px;
    float: left;
}
.rubrika-videoport .videoFirstPic {
    margin-bottom: 10px;
}

.rubrika-videoport .linkCol {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.rubrika-videoport .linkCol-last {
    padding-bottom: 4px;
}
.rubrika-videoport .linkCol-big {
    padding-bottom: 0;
}
.rubrika-videoport .linkCol.bigOne .videoFirstTitle {
    padding-bottom: 4px;
}
.rubrika-videoport .linkCol.noBord {
    border-bottom: none;
    margin-bottom: 5px;
}
.rubrika-videoport .linkCol.bigOne {
    padding-bottom: 0;
}
.rubrika-videoport .whiteBg {
    padding-bottom: 0;
}
.rubrika-videoport .videoFirstpic {
    width: 280px;
}
.rubrika-videoport .videoOtherTitle {
    float: left;
    width: 150px;
    height: 68px;
    margin-left: 10px;
    line-height: 17px;
}
.rubrika-videoport .videoOtherTitle a {
    font-family: arial;
    font-size: 15px;
    font-weight: bold;
}
.rubrika-videoport .delsi {
    width: 280px;
}
.rubrika-videoport .iconCelaKauza-small {
    display: block;
    top: 53px;
    left: 53px;
    position: absolute;
    width: 28px;
    height: 28px;
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat scroll -945px -1365px transparent;
    /*background: url('/images/blesk-2013/blesk-sprites-webp.webp?v=1') no-repeat scroll -845px -1365px transparent;*/
}

/* article listed */

/* k listovacimu clanku se do #related pripojuji dalsi clanky z vlastniho boxu. ten schovame*/
.listedArticlesToBeHidden {
    display: none;
}
#article.listed,
#article.notlisted {
    overflow: visible;
    margin: 0 auto;
}
#article .pseudoInner_7 {
    display: block;
    float: right;
    width: 540px;
    padding-top: 0;
    padding-bottom: 10px;
    padding-left: 10px;
    margin-right: 10px;
}
#article .pseudoInner_8 {
    display: block;
    margin: 0 auto;
    width: 640px;
    padding: 0;
}
#article .headerWrapper {
    position: relative;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}
#article.listed .titleWrapper,
#article.notlisted .titleWrapper {
    display: block;
    position: relative;
    width: 100%;
    max-width: 640px !important;
}
#article.listed .titleWrapper {
    /*border-top: 4px solid #ff0000;
		background-color: white;*/
}

#article.listed h1,
#article.notlisted h1 {
    margin-left: 80px;
    padding-bottom: 20px;
    padding-top: 20px;
}
#article.listed .centerBanner,
#article.notlisted .centerBanner {
    display: block;
    margin: 0 auto;
}
#article.notlisted h2 {
    display: inline-block;
    padding-left: 20px;
    font-size: 24px;
    line-height: 24px;
    font-family: "tablet_gothic_condensed";
    border-left: 2px solid #cad5df !important;
}
#article li#related h2 {
    padding-left: 0;
}
#article.notlisted .number {
    display: inline-block;
    font-family: "tablet_gothic_condensed";
    margin-right: 20px;
    color: #ff0000;
    font-size: 24px;
    font-weight: bold;
}
#article.notlisted ul.main > li:first-child > img.topImg {
    margin-top: 50px;
    margin-left: -80px;
}
#article.notlisted ul.main li:first-child > photoDesc {
    margin-left: -80px;
    width: 620px;
}
#article.notlisted ul.main > li {
    padding-bottom: 10px !important;
}
#article.notlisted ul.main > li {
    padding-bottom: 20px !important;
}
#article.notlisted ul.main > li:not(:nth-child(1)) {
    border-bottom: 1px solid #cad5df;
}
#article.notlisted ul.main > li:last-of-type {
    border-bottom: 0;
}
#article.notlisted ul.main > li:nth-child(2) {
    border-top: 2px solid #ff0000;
}
#article.listed article {
    /*background-color: white;*/
}
#article ul.main {
    border-top: 1px solid #cad5df;
    margin: 0 10px;
}
#article.listed ul.main > li .imgInArt > img {
    max-width: 600px;
    width: 100%;
}
#article.notlisted ul.main > li .imgInArt > img {
    max-width: 600px;
    width: 100%;
}
#article.listed ul.listed.inner_8 {
    position: relative;
    width: auto;
    overflow: hidden;
    padding: 0;
}
#article.listed .number {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    float: left;
    font-family: "tablet_gothic_condensed";
    padding-right: 10px;
    padding-top: 5px;
    color: #ff0000;
    font-size: 35px;
    line-height: 40px;
    border-right: 2px solid #cad5df !important;
}
#article.listed h2 {
    display: inline-block;
    max-width: 410px;
    margin-left: 63px;
    margin-bottom: 16px;
    padding-top: 5px;
    float: left;
    font-size: 36px;
    line-height: 40px;
    font-family: "tablet_gothic_condensed";
}
#article.listed .linkArticle h2 {
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 16px;
}
#article.listed ul.listed > li {
    float: left;
    display: none;
    padding-bottom: 10px;
}
#article.listed ul.listed li.active {
    display: block;
}
#article .bottom {
    padding: 10px;
}

#article.listed #related {
    width: 620px;
    padding: 0px;
}
#article.notlisted #related {
    border-bottom: 0;
}
#article.listed #related .relatedArticles {
    padding: 0;
}

#article.notlisted #related h2 {
    margin-left: 69px;
    border-left: 0px !important;
    margin-top: inherit;
}
#article.listed .bottom .socialShares {
    margin-top: 6px;
    width: auto;
    min-width: 320px;
    float: left;
}
#article.listed .articleDetails .socialShares,
#article.notlisted .articleDetails .socialShares {
    width: auto;
    min-width: 320px;
    margin-left: 70px;
}
#article.notlisted .articleDetails .socialShares {
    margin-left: 70px;
}

#article.listed .bottom .counter {
    display: block;
    float: left;
}
#article.listed .bottom {
    display: block;
    position: relative;
    min-height: 32px;
    width: 100%;
    box-sizing: border-box;
}
#article.listed .listingControlButtons {
    display: block;
    position: absolute;
    right: 10px;
    bottom: -57px;
    font-family: "tablet_gothic_condensed";
    font-size: 21px;
    color: #ff0000;
    z-index: 1000;
}
#article .listingControlButtons {
    margin-bottom: 10px;
    display: none;
    position: relative;
}
#article .listingControlButtons button {
    border: 0;
    position: relative;
    background: transparent;
    outline: 0;
    padding: 0;
    margin: 0;
    display: block;
    float: left;
}
#article .bottom .listingControlButtons.smallControlButtons {
    display: block;
    position: relative;
    float: right;
    top: 10px;
    /*right: 20px;*/
}
#article .listingControlButtons.smallControlButtons button,
#article .listingControlButtons.smallControlButtons .counter {
    float: left;
}
#article .listingControlButtons.smallControlButtons .startListing.showNext {
    display: none;
    background-color: #ffffff;
    color: #ff0000;
}
#article .listingControlButtons.smallControlButtons button.moreArticles {
    background-color: #ffffff;
    color: #ff0000;
}
#article .listingControlButtons.smallControlButtons button.showStart {
    background-color: #ffffff !important;
    color: #ff0000;
}
#article .listingControlButtons.smallControlButtons {
}
#article .listingControlButtons.smallControlButtons .showPrev,
#article .listingControlButtons.smallControlButtons .showPrev.showStart {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/article-listed-sprite.png?v=3") 0px -37px no-repeat;
    padding: 0;
}
#article .listingControlButtons.smallControlButtons .shownext,
#article .listingControlButtons.smallControlButtons .showPrev.showStart {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/article-listed-sprite.png?v=3") -37px -37px no-repeat;
    padding: 0;
}
#article .smallControlButtons.listingControlButtons button.next:not(.moreArticles) {
    width: 32px;
    height: 32px;
    max-width: 32px;
    max-height: 32px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/article-listed-sprite.png?v=3") -37px -37px no-repeat;
}

#article .listingControlButtons.smallControlButtons .showStart.showPrev {
    display: none !important;
}
#article .listingControlButtons .next:not(.moreArticles):not(.showStart) {
    margin-left: 10px;
}
#article .listingControlButtons.smallControlButtons .next:not(.moreArticles):not(.showStart) {
    margin-left: 0;
}
#article .listingControlButtons .prev {
    margin-right: 10px;
}
#article .listingControlButtons.smallControlButtons .prev {
    margin-right: 0px;
}
#article .listingControlButtons button:hover {
    cursor: pointer;
}
#article .listingControlButtons button.next:not(.moreArticles) {
    width: 32px;
    height: 32px;
    max-width: 32px;
    max-height: 32px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/article-listed-sprite.png?v=3") -37px 0 no-repeat;
}
#article .listingControlButtons button.startListing {
    font-family: "tablet_gothic_condensed";
    color: white;
    background-color: #ff0000;
    padding: 0px 0px;
    width: 180px;
}
#article .listingControlButtons button.startListing .text {
    text-transform: none;
}

#article .listingControlButtons:not(.smallControlButtons) button.showStart {
    font-family: "tablet_gothic_condensed";
    color: white;

    padding: 0px 0px;
    width: 75px;
}
#article .listingControlButtons.smallControlButtons button.showStart {
    font-family: "tablet_gothic_condensed";
    color: #ff0000;
    padding: 0px 0px;
    width: 75px;
}
#article .listingControlButtons:not(.smallControlButtons) button.showStart {
    background-color: #ff0000 !important;
}
#article .listingControlButtons button.moreArticles {
    display: block;
    float: left;
    font-family: "tablet_gothic_condensed";
    color: white;
    padding: 0px 0px;
    min-width: 138px;
}
#article .listingControlButtons:not(.smallControlButtons) button.moreArticles {
    background-color: #ff0000 !important;
}

#article .listingControlButtons button.moreArticles > .whiteArrow {
    float: right;
}

/*
#article .listingControlButtons button.showStart:not(.prev)::after, #article .listingControlButtons button.startListing::after {
		position: absolute;
		content: "";
		bottom: 0px;
		right: 7px;
		width: 90%;
		top: 6px;
		max-width:  90%;
		background: transparent;
		-webkit-box-shadow: 0 15px 10px #888;
		-moz-box-shadow: 0 15px 10px #888;
		box-shadow: 0 15px 10px #888;
		-webkit-transform: rotate(4deg);
		-moz-transform: rotate(4deg);
		-o-transform: rotate(4deg);
		-ms-transform: rotate(4deg);
		transform: rotate(4deg);
		height: 10px;
		z-index: -1;
}
*/
#article .listingControlButtons button.prev,
#article .listingControlButtons button.prev.showStart {
    padding: 0;
    width: 32px;
    height: 32px;
    max-width: 32px;
    max-height: 32px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/article-listed-sprite.png?v=3") 0 0 no-repeat;
}

#article .listingControlButtons button .text {
    position: relative;
    display: block;
    float: left;
    font-size: 18px;
    line-height: 32px;
    font-family: "tablet_gothic_compressed";
    text-align: center;
    padding: 0;
    padding-left: 10px;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
}
#article .listingControlButtons:not(.smallControlButtons) .showStart .text {
    padding-left: 30px;
    padding-right: 5px;
    background-color: #ff0000;
}
#article.listed .whiteArrow {
    position: absolute;
    display: block;
    top: 0px;
    right: 0px;
    width: 32px;
    height: 32px;
    max-width: 32px;
    max-height: 32px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/article-listed-sprite.png?v=3") -37px 0 no-repeat;
}
#article.listed .whiteArrowBack {
    position: absolute;
    display: block;
    top: 0px;
    left: 0px;
    width: 25px;
    height: 32px;
    max-width: 32px;
    max-height: 32px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/article-listed-sprite.png?v=3") -3px 0 no-repeat;
}
#article.listed .smallControlButtons .whiteArrow {
    position: absolute;
    display: block;
    top: 0px;
    right: 0px;
    width: 25px;
    height: 32px;
    max-width: 32px;
    max-height: 32px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/article-listed-sprite.png?v=3") -43px -37px no-repeat;
}
#article.listed .smallControlButtons .whiteArrowBack {
    position: absolute;
    display: block;
    top: 0px;
    left: 0px;
    width: 25px;
    height: 32px;
    max-width: 32px;
    max-height: 32px;
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/article-listed-sprite.png?v=3") -7px -37px no-repeat;
}
#article.listed .smallControlButtons .showStart .text {
    padding-left: 25px;
}

#article.listed .articleDetails,
#article.notlisted .articleDetails {
    position: relative;
    margin: 10px 0;
    padding: 0 10px;
}
#article.listed .articleDetails {
    margin-bottom: 20px;
}
#article.listed .relatedArticles {
    width: 100%;
}

#article.notlisted .relatedArticles {
    width: 550px;
    float: right;
}
#article.listed .relatedArticles .article img,
#article.notlisted .relatedArticles .article img {
    width: 280px;
    height: 160px;
    cursor: pointer;
}
#article.listed .relatedArticles .article a {
    width: auto;
    max-width: 310px;
    font-size: 30px;
    line-height: 30px;
    font-weight: bold;
    font-family: "tablet_gothic_condensed";
    color: #16212d;
    cursor: pointer;
    float: left;
}

#article.notlisted .relatedArticles .article a {
    width: auto;
    max-width: 260px;
    font-size: 30px;
    line-height: 30px;
    font-weight: bold;
    font-family: "tablet_gothic_condensed";
    cursor: pointer;
}
#article.listed .relatedArticles .article,
#article.notlisted .relatedArticles .article {
    position: relative;
}
#article.listed .relatedArticles .article:last-child,
#article.notlisted .relatedArticles .article:last-child {
    border-bottom: 0;
}
#article.listed .relatedArticles .article:first-child,
#article.notlisted .relatedArticles .article:first-child {
    border-top: 1px solid #cad5df;
    margin-top: 0;
}
#article.listed .relatedArticles .article p.perex,
#article.listed .relatedArticles .article a.perex {
    position: absolute;
    bottom: 0px;
    right: 20px;
    font-size: 15px !important;
    line-height: 20px !important;
    color: #16212d;
    font-weight: normal;
    font-family: Arial, sans-serif;
    max-width: 300px;
    cursor: pointer;
}
#article.listed .relatedArticles .article a.perex {
    bottom: 10px;
    text-decoration: none;
}
#article.listed .relatedArticles .article p.perex:before {
    content: "";
    float: left;
    width: 10px;
    height: 10px;
    margin: 5px 3px 0 0;
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -250px -216px;
}
#article.notlisted .relatedArticles .article a.perex {
    position: absolute;
    bottom: 10px;
    right: 0px;
    font-size: 15px !important;
    line-height: 20px !important;
    color: #16212d;
    font-weight: normal;
    font-family: Arial, sans-serif;
    max-width: 260px;
    cursor: pointer;
}
#article.notlisted ul.main > li:nth-child(1) .internBanner,
#article.listed ul.main > li:nth-child(1) .internBanner {
    /*max-width: 500px !important;
	   /margin-left: 80px;*/
    max-width: 620px !important;
    margin-left: 0px;
}
#article.listed .relatedArticles .article a.perex:before,
#article.notlisted .relatedArticles .article a.perex:before {
    content: "";
    float: left;
    width: 10px;
    height: 10px;
    margin: 5px 3px 0 0;
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat -250px -216px;
}
#article.listed .authors:not(.autorLeft) {
    font-weight: bold;
    line-height: 20px;
    text-align: left;
}
#article.listed .dateTime,
#article.notlisted .dateTime {
    width: auto;
    margin-top: 33px;
    margin-bottom: 27px;
}
#article.listed ul > li:nth-child(1) img.imgTop {
    display: block;
    margin: 0 auto;
    /*margin-top: 66px;*/
    margin-top: 20px;
    width: 610px;
}
#article.listed .internBanner {
    margin-left: -70px;
}
#article.listed .listingControlButtons {
    display: none;
}
#article.listed .listingControlButtons .counter {
    line-height: 32px;
    float: left;
}
#article.listed .errorsFooter {
    background-color: #e9eff4;
}
#article.listed .relativeObject {
    padding: 0;
    margin-left: 0px;
    margin-right: 0;
}
#article.listed .relativeObject .headerWhite {
    padding: 5px 0;
    background: #ffffff;
}
#article.listed .relativeObject .socialShares {
    margin: 19px 0 40px;
}
#article.listed .relativeObject.videoInArticle {
    padding: 0 10px;
    margin-left: -10px;
    margin-right: 0;
}
#article.listed .socialShareArrow,
#article.notlisted .socialShareArrow {
    left: 0;
}

#article.listed ul.main li:first-of-type .photoDesc,
#article.notlisted ul.main li:first-of-type .photoDesc {
    font: bold 12px Arial;
    line-height: 15px;
    color: #e9eff4;
    padding: 10px;
    margin-bottom: 20px;
    background-color: #16212d;
}
#article.notlisted ul.main li:first-of-type .photoDesc {
    width: 600px;
}
/*
#article.notlisted ul.main li:first-of-type .photoDesc, #article.notlisted li:first-child > .imgTop{
	margin-left: -80px;
}
*/
#article.listed ul.main li:first-of-type .photoDesc .author,
#article.notlisted ul.main li:first-of-type .photoDesc .author {
    font-weight: normal;
    color: #98a3af;
}
#article.listed .relativeObject .videoHeader,
#article.notlisted .relativeObject .videoHeader {
    padding: 0 10px;
}

#article.listed .tagsFooter,
#article.notlisted .tagsFooter {
    padding-left: 10px;
    margin-left: 0;
}
#article.listed .imgInArtDescription {
    margin: 0 !important;
    font: bold 12px Arial;
    line-height: 15px;
    color: #e9eff4 !important;
    padding: 10px;
    margin-bottom: 20px;
    background-color: #16212d;
    padding: 10px !important;
    padding-bottom: 0 !important;
}
#article.listed .imgAuthor {
    margin: 0 !important;
    font: bold 12px Arial;
    line-height: 15px;
    color: #e9eff4 !important;
    padding: 10px;
    margin-bottom: 20px;
    background-color: #16212d;
    padding: 10px !important;
    padding-top: 0 !important;
}
#article.listed .imgInArtDescription {
    padding: 10px;
}
#article.notlisted .imgAuthor {
    padding: 10px;
}
#article.notlisted .imgInArtDescription {
    max-width: 100%;
    padding: 10px;
}
#article li#related h2 {
    margin-bottom: 5px;
}
#article.listed .leadsection p,
#article.listed .leadsection p span {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
}
#article.notlisted .leadsection {
    padding-left: 80px;
    padding-right: 10px;
}

#article.notlisted .autorLeft,
#article.listed .autorLeft {
    margin-left: 0 !important;
    margin-top: 50px !important;
}

#article.listed .autorLeft {
    margin-bottom: 12px !important;
}

#article.notlisted .authors.autorLeft,
#article.listed .authors.autorLeft {
    float: left;
    width: 600px;
    color: #5c6773;
    font-size: 15px;
    font-weight: bold;
    line-height: 18px;
    margin: 0 auto;
}
#article.notlisted .authors.autorLeft {
    margin-top: 0 !important;
}
#article.notlisted .videoInArticle.relativeObject {
    margin: 0;
}
#article.notlisted.mt10px .errorsFooter.relativeObject {
    margin-left: 0px;
}
/*paging*/
ul.listed,
ul.notlisted {
    display: block;
    float: none;
}
.paging {
    display: block;
    float: none;
    font-weight: bold;
    font-family: Arial;
    line-height: 40px;
    padding: 0px;
    width: 620px;
    background-color: white;
    margin-bottom: 20px;
}
.paging button {
    display: block;
    width: 100px;
    max-height: 30px;
    background-color: #ff0000;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    line-height: 30px;
    color: #fff;
    outline: 0;
    border: none;
    margin: 0px;
    padding: 5px;
}
.paging button.next {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    float: right;
}
.paging button.prev {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    float: left;
}
div.listed .paging button.next,
div.listed .paging button.prev {
    width: 100px;
}
.paging a {
    min-width: 29px;
    min-height: 30px;
    height: 100%;
    color: black;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    line-height: 30px;
    font-size: 14px;
    border: none;
    margin: 0 1px;
    padding: 5px 0px;
}
.paging a[data-id="intro"] {
    display: inline-block;
    /*background-color: #b9c7cd;*/
}
.paging a[data-id="intro"].active {
    padding-right: 4px;
    padding-left: 4px;
    text-transform: capitalize;
}
.paging a:hover {
    text-decoration: none;
    color: black;
}

.paging a.active:not([data-id="intro"]) {
    /*padding: 10px 5px;*/
    min-width: 29px;
    background-color: #b9c7cd;
}
.paging a.disabled {
    cursor: default;
}
#article .paging a.disabled:hover {
    text-decoration: underline;
    background-color: unset;
    color: unset;
}
/* end article listed */

.bot1520 {
    position: absolute;
    z-index: 0;
    bottom: 1520px;
}

.bot1880 {
    position: absolute;
    z-index: 0;
    bottom: 1880px;
}

.tnCzWrapper .recentArticles {
    margin-top: 0px;
    float: none;
    height: 290px;
}

.tnCzWrapper .recentArticles h4.header {
    background: none !important;
    color: #99a4aa !important;
    border-top: 0px !important;
    font-family: "tablet_gothic_compressed";
    font-size: 16px;
    line-height: 26px;
    height: 35px !important;
    text-transform: none;
    padding: 0;
}

.container .bottomStickyBlock.tnCzWrapper .recentArticles a.hoverColor:hover {
    color: #ff0000 !important;
    text-decoration: underline;
}

.container .bottomStickyBlock.tnCzWrapper .recentArticles .categoryArrow .arrow {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -250px -216px no-repeat !important;
}

/* Jak zhubnout
--------------------------------------------------------------------------------*/
.boxJakZhubnout {
    width: 940px;
}
.boxJakZhubnout .header {
    background: transparent url("https://img2.cncenter.cz/images/blesk-2013/bg-box-jak-zkubnout.png?v=68") 0px 0px no-repeat;
    width: 940px;
    height: 45px;
    overflow: hidden;
    margin: 0px 10px 0 10px;
}

.boxJakZhubnout .header a {
    display: block;
    width: 940px;
    height: 45px;
    text-indent: -9000px;
}

/*------------------ ERRORS FOOTER - hlášení chyb v hlavičce -------------------*/
/*--- Made by the one and only all mighty LH - Donate by sending dollaz babe ---*/

.errorsFooter {
    background: #fff;
    padding: 5px;
}

.hlaseniChyby #captcha {
    float: left;
    height: 30px;
    width: 125px;
    border: none;
    padding: 0 5px;
}

.hlaseniChyby #email-phone {
    float: left;
    height: 30px;
    margin: 0px 10px 0px 0;
    background: #dee6ee;
    border: none;
    padding: 0 5px;
}

#errorForm {
    background: #fff;
    padding: 10px 0 0 0;
    border-top: 1px solid #dee6ee;
}

.bottomBlock {
    margin-top: 10px;
    padding: 10px 0 0 0;
    border-top: 1px solid #dee6ee;
    font-size: 24px;
    font-family: "tablet_gothic_condensed";
}

.bottomBlock .errorTel {
    color: #f00;
    text-decoration: none;
    font-size: 28px;
    font-family: "tablet_gothic_condensed";
}

.bottomBlock > h4 {
    margin-left: 10px;
}

#showError::after {
    display: inline-block;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 5px 7px;
    border-color: transparent transparent transparent #ff0000;
    position: relative;
    left: 120px;
    top: -5px;
}

#showError.active::after {
    display: inline-block;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 5px 0 5px;
    border-color: #ff0000 transparent transparent transparent;
    position: relative;
    left: 120px;
    top: -5px;
}

/* new head*/

.wrapper.head {
    position: relative;
    margin-top: -67px;
    z-index: 0;
}

.logoBleskHP {
    z-index: 1;
}

/* reklama */
.ads {
    clear: both;
}

/* pravy sloupec - sticky reklama */

#stickyBottomRightAbsolute {
    position: relative;
    bottom: 0;
}

#stickyBottomRightAbsolute > div {
    position: relative !important;
    float: left !important;
    bottom: auto !important;
    margin-top: 10px;
}

#ads-prace {
    margin: 0 10px 0 10px;
}

/* levy sloupec - sticky reklama */

#stickyBottomLeftAbsolute {
    position: relative;
    bottom: 0;
}

#stickyBottomLeftAbsolute > div {
    position: relative !important;
    float: left !important;
    bottom: auto !important;
    margin-top: 10px;
}

/* uprava pro dlouha jmena v hlavicce */
.forecastHP .forecast {
    margin-left: 15px !important;
}
.calendarContHP .nameDay {
    font-size: 9px !important;
    padding: 1px 0px 0px 0px !important;
    max-width: 403px !important;
}
.headerMenu .calendarContHP {
    width: 403px !important;
}
.headerMenu .calendar {
    padding: 8px 0 0 0 !important;
}

.nws .article .headerMenu .nameDay {
    max-width: 400px;
}

/* OWL CAROUSEL IN ARTICLES */
#mainCarousel {
    position: relative;
}

#mainCarousel .mainCarouselPhoto {
    position: relative;
}

#mainCarousel .mainCarouselPhoto a {
    display: block;
}

#mainCarousel .owl-controls .owl-next {
    width: 60px;
    background: url("https://img2.cncenter.cz/images/isport/articlegallery-right.png") no-repeat center center;
    height: 60px;
    display: block;
    float: right;
    position: absolute;
    right: 0px;
    top: 0px;
    cursor: pointer;
    text-indent: -9999px;
    opacity: 1;
    padding: 155px 10px;
}

#mainCarousel .owl-controls .owl-next:hover {
    background: url("https://img2.cncenter.cz/images/isport/articlegallery-right-hover.png") no-repeat center center;
}

#mainCarousel .owl-controls .owl-prev {
    width: 60px;
    background: url("https://img2.cncenter.cz/images/isport/articlegallery-left.png") no-repeat center center;
    height: 60px;
    display: block;
    float: left;
    position: absolute;
    left: 0px;
    top: 0px;
    cursor: pointer;
    text-indent: -9999px;
    opacity: 1;
    padding: 155px 10px;
}

#mainCarousel .owl-controls .owl-prev:hover {
    background: url("https://img2.cncenter.cz/images/isport/articlegallery-left-hover.png") no-repeat center center;
}

#mainCarousel .carouselZoom {
    display: none;
    width: 33px;
    height: 33px;
    background: transparent url("https://img2.cncenter.cz/images/newblesk/galerie-responsive/zoom-blesk.png") no-repeat;
    text-indent: -99999px;
    overflow: hidden;
    position: absolute;
    top: 10px;
    right: 90px;
    z-index: 2;
}
#mainCarousel .mainCarouselPhoto:hover .carouselZoom {
    display: none;
}

#mainCarousel .carouselZoom:hover {
    background: transparent url("https://img2.cncenter.cz/images/newblesk/galerie-responsive/zoom-blesk.png") no-repeat 0px -33px;
}

/* Scroll redirecter in articles */
#redirecter {
    height: 20px;
    overflow: hidden;
    background: red;
    min-width: 188px;
    width: 188px;
}
#hpPart1 {
    height: 80px;
    opacity: 0.3;
    padding-left: 7px;
    margin-top: 20px;
}
#hpPart2 {
    clear: both;
    opacity: 0.15;
    position: relative;
    margin-left: 10px;
}
.scrollTitle {
    font-family: "tablet_gothic_compressed";
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 40px;
}
.scrollWrapper {
    border: 1px solid #ccc;
    background: #fff;
    margin-bottom: 40px;
    overflow: hidden;
}
.hpWrapper {
    height: 600px;
    overflow: hidden;
    clear: both;
}
.transGradient {
    width: 940px;
    height: 90px;
    position: absolute;
    bottom: 0px;
    left: 0;
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 90%);
}

/*  predplatne - pruchod pay gate   */
.predplatne {
    clear: both;
    padding-top: 50px;
}

.predplatne h1,
.predplatne a {
    margin-top: 30px;
    display: inline-block;
}

.predplatne a {
    margin-top: 30px;
    color: #fff;
    display: inline-block;
    background: #f00;
    padding: 10px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
}

#art-foodtest-banner {
    margin-left: 0 !important;
}

article .inner_7 #art-foodtest-banner {
    margin-left: -80px !important;
}

.tabsArea .behavioral-tabcontent-wrap .item-big.item-big-last {
    margin-left: 11px !important;
    width: 280px !important;
}

/* Recentarticles - small - external */
.recentarticles-small-external {
    position: relative;
}
.recentarticles-small-external.offset {
    top: -55px;
}

@font-face {
    font-family: "tablet_gothic_condensed";
    src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot?v=3");
    src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot?#iefix") format("embedded-opentype");
    src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.woff?v=3") format("woff");
    src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.ttf?v=3") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/* small icon play - in article context for videoCount in horizontal articles*/
.icon-circle {
    border: 2px solid #cad5df;
    position: relative;
    border-radius: 8px;
    background: #fff;
    width: 12px;
    height: 12px;
    display: inline-block;
    vertical-align: text-bottom;
}

.icon-arrow {
    border-left: 5px solid #cad5df;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    font-size: 0;
    position: absolute;
    left: 5px;
    top: 3px;
}

/* Component menu category styly */

.component-menu-category {
    background-color: transparent;
    border-top: 4px solid #083e89;
    height: 50px !important;
    margin: 20px 10px 0 10px;
    padding: 10px 0 10px 0;
    position: relative;
    float: left;
}
.component-menu-category .cmc-title {
    left: 10px !important;
    top: -8px !important;
}
.component-menu-category .cmc-title a {
    color: #16212d !important;
    font-family: "tablet_gothic_compressed";
}
.component-menu-category .cmc-container {
    height: 50px !important;
    margin: 0 !important;
}
.component-menu-category .cmc-container .cmc-box {
    height: 50px !important;
}
.component-menu-category .cmc-container .cmc-box .cmc-link {
    border-color: #cad5df !important;
    color: #083e89 !important;
    font-family: "tablet_gothic_compressed" !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin-right: 0 !important;
    font-size: 23px !important;
    height: 50px !important;
    letter-spacing: 1px !important;
    line-height: 50px !important;
}
.component-menu-category .cmc-container .cmc-box .cmc-link .cmc-arrow {
    display: none !important;
}
.component-menu-category .cmc-container .cmc-box:last-child > a::after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -600px -200px no-repeat !important;
    width: 10px;
    content: "";
    height: 10px;
    position: absolute;
    top: 20px;
    right: 0;
    background-origin: content-box;
    background-clip: content-box;
}
.component-menu-category.nolist .cmc-container .cmc-box:last-child > a::after {
    background: transparent !important;
}
.component-menu-category .cmc-container .cmc-box .cmc-list {
    right: 0 !important;
    background-color: #083e89 !important;
    padding: 0 !important;
}
.component-menu-category .cmc-container .cmc-box .cmc-list .cmc-link {
    box-sizing: border-box !important;
    border-bottom: 1px solid #fff !important;
    color: #fff !important;
}
.component-menu-category .cmc-container .cmc-box .cmc-list .cmc-link:last-child {
    border-bottom: none !important;
}
.component-menu-category .cmc-container .cmc-box .cmc-list .cmc-link:hover {
    color: #191e56 !important;
}

.component-menu-category-celebrity {
    border-color: #ff0000 !important;
    margin-top: 20px;
}
.component-menu-category-celebrity .cmc-title a:hover {
    color: #ff0000 !important;
}
.component-menu-category-celebrity .cmc-container .cmc-box .cmc-list {
    background-color: #ff0000 !important;
}
.component-menu-category-celebrity .cmc-container .cmc-box .cmc-link {
    color: #ff0000 !important;
}
.component-menu-category-celebrity .cmc-container .cmc-box .cmc-list .cmc-link {
    color: #fff !important;
}
.component-menu-category-celebrity .cmc-container .cmc-box .cmc-list .cmc-link:hover {
    color: #000 !important;
}
.component-menu-category-celebrity .cmc-container .cmc-box:last-child > a::after {
    background-position: -250px -200px !important;
    display: none;
}

.component-menu-category-testy {
    border-color: #ff0000 !important;
}
.component-menu-category-testy .cmc-container .cmc-box .cmc-list {
    background-color: #ff0000 !important;
}
.component-menu-category-testy .cmc-container .cmc-box .cmc-link {
    color: #ff0000 !important;
}
.component-menu-category-testy .cmc-container .cmc-box .cmc-list .cmc-link {
    color: #fff !important;
}
.component-menu-category-testy .cmc-container .cmc-box .cmc-list .cmc-link:hover {
    color: #000 !important;
}
.component-menu-category-testy .cmc-container .cmc-box:last-child > a::after {
    background-position: -250px -200px !important;
}

.component-menu-category.component-menu-category-cestovani {
    border-color: #3cc3dc !important;
    margin-top: 20px;
}
.component-menu-category-cestovani .cmc-container .cmc-box .cmc-list {
    background-color: #3cc3dc !important;
}
.component-menu-category-cestovani .cmc-container .cmc-box .cmc-link {
    color: #3cc3dc !important;
}
.component-menu-category-cestovani .cmc-container .cmc-box .cmc-list .cmc-link {
    color: #fff !important;
}
.component-menu-category-cestovani .cmc-container .cmc-box .cmc-list .cmc-link:hover {
    color: #000 !important;
}
.component-menu-category-cestovani .cmc-container .cmc-box:last-child > a::after {
    background-position: -250px -200px !important;
}
.component-menu-category.component-menu-category-cestovani .cmc-container .cmc-box:last-child > a::after {
    background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -450px -200px no-repeat !important;
}

/*
Perex dodatek
 */
.articleContainer p.perex:before {
    content: "";
    float: left;
    width: 0px;
    height: 0px;
    margin: 0;
    background: none;
}
.titleInArtFix,
.wikiFixTitleH {
    height: auto !important;
}

.wikiResult .articleContainer.middleArticle h3.title.wikiFixTitleH {
    margin-top: 0;
    padding: 5px 0px 0px 10px;
    min-height: 85px;
}

.wikiResult .articleContainer.middleArticle h3.title.wikiFixTitleH a {
    line-height: 25px;
}

.level1 .notifier {
    border-top: 1px solid #707b87;
    padding-top: 20px;
}
.level1 .foot {
    padding-bottom: 15px;
}

/*
tabulka výherců (Denní hry) --start--
 */
.denniVyhra {
    border: 0;
    width: 620px;
    margin-left: -80px;
}
.denniVyhra thead tr {
    background-color: #5c6773;
    color: #fff;
    font-weight: bold;
    font-size: 17px;
    vertical-align: middle;
}
.denniVyhra thead tr td {
    vertical-align: middle;
}
.denniVyhra thead tr td span {
    display: table-cell;
    vertical-align: middle;
}
.denniVyhra tbody tr {
    background-color: #e9eff4;
    color: #16212d;
    font-size: 10px;
    max-height: 40px;
}
.denniVyhra td {
    border: 0;
}
.denniVyhra td:nth-child(1) {
    width: 85px;
    padding-right: 1px;
    padding-left: 1px;
}
table.denniVyhra td,
table.denniVyhra th,
.denniVyhra td:nth-child(2) {
    width: auto;
}
.denniVyhra tbody tr td {
    padding: 8px 2px 7px 2px;
}
.denniVyhra td:not(:last-child) {
    border-right: 1px solid #98a3af;
}
.denniVyhra tbody tr:nth-child(odd) {
    background-color: #fff;
}
/*
tabulka výherců (Denní hry) --end--
 */

/*
tabulka výherců (Denní hry) --start--
 */
.jade .recentarticles-middle-zenycz .articleContainer.smallArticle h3.title a:hover {
    color: #fe60b2 !important;
}
.jade .recentarticles-middle-zenycz .articleContainer .categoryTitle a {
    color: #fe60b2 !important;
}
.jade .recentarticles-middle-zenycz .articleContainer:hover a.hoverColor {
    color: #fe60b2 !important;
}

/*for vertical right col Dalsi Zpravy a Video vertical box*/
.recentArticles.outer_4 .rec_vertical_paid .item.articleContainer .title a.resized,
.outer_4.greyBg .vertVideoSmall .linkCol .videoOtherTitle a {
    font-size: 13px !important;
    line-height: 17px;
}

/*videos in right col */
.vertVideoBig {
    position: relative;
}
/*end paid recentarticles*/

.video-sticky .video-wrapper {
    width: 300px;
    background: #fff;
}
.video-sticky {
    width: 300px;
    margin: 0 auto 20px;
    padding: 0 0 10px 0;
    background-color: #fff;
    text-align: center;
}
.video-sticky .video-sticky__title-wrapper {
    text-align: left;
    padding: 10px 10px 0;
}
.video-sticky .video-sticky__title a {
    line-height: 32px;
    color: #16212d;
    font-size: 30px;
    font-family: "tablet_gothic_condensed";
    letter-spacing: 0px;
    transition: all 0.2s ease-out;
    text-decoration: none;
}
.video-sticky .video-sticky__title a:hover {
    color: #ff0000;
    text-decoration: underline;
}
.chatArticle .online_report .social-media__wrapper .fb_iframe_widget {
    margin: 0;
}
